import { colorPrimary } from '../config/theme';

export const IMG_SPLASH_LOGO = '/images/splash_logo.svg';

export const IMG_SPLASH_LOGO_LEFT_TOP = '/images/splash_left_top.svg';

export const IMG_SPLASH_LOGO_RIGHT_BOTTOM = '/images/splash_right_bottom.svg';

export const IMG_ERROR_404 = '/images/error_404.gif';

export const IMG_ERROR_403 = '/images/error_403.gif';

export const IMG_LENDBOX = '/images/lendbox.png';

export const IMG_RBI = '/images/rbi_logo.png';

export const IMG_PERSON_AVTAR = '/images/person_preview.png';

export const IMG_PERSON_AVTAR_SMALL =
  '/images/person.jpg'; /** For Profile Picture */

export const IMG_PDF_PREVIEW = '/images/pdf_preview.png';

export const IMG_IMAGE_PREVIEW = '/images/image_preview.svg';

export const IMG_SUCCESS = '/images/success.png';

export const IMG_FAILED = '/images/failed.png';

export const DOWNLOAD_APP_ICON = '/images/download-app.png';

export const IMG_USER_ANALYTICS_ICON =
  '/images/analytics-images/user-analytics.svg';

export const IMG_USER_GROUP_ANALYTICS_ICON =
  '/images/analytics-images/user-group-analytics.svg';

export const IMG_ROLE_ANALYTICS_ICON =
  '/images/analytics-images/role-analytics.svg';

export const IMG_DISTRICT_ANALYTICS_ICON =
  '/images/analytics-images/district-analytics.svg';

export const IMG_AREA_ANALYTICS_ICON =
  '/images/analytics-images/area-analytics.svg';

export const IMG_SCHOOL_ANALYTICS_ICON =
  '/images/analytics-images/school-analytics.svg';

export const IMG_FORMS_ANALYTICS_ICON =
  '/images/analytics-images/forms-analytics.svg';

export const IMG_RESPONSE_ANALYTICS_ICON =
  '/images/analytics-images/response-analytics.svg';

export const IMG_RESPONSE_TIME_ANALYTICS_ICON =
  '/images/analytics-images/response-time-analytics.svg';

export const IMG_TOTAL_OBSERVATIONS_ANALYTICS_ICON =
  '/images/analytics-images/total-observations-analytics.svg';

export const IMG_TOTAL_INDICATORS_ANALYTICS_ICON =
  '/images/analytics-images/total-indicators-analytics.svg';

export const IMG_AVERAGE_SCORE_ANALYTICS_ICON =
  '/images/analytics-images/average-score-analytics.svg';

export const menu_location_icon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 15.1863C3.64864 16.003 2.5 17.1411 2.5 18.3999C2.5 20.8852 6.97715 22.8999 12.5 22.8999C18.0228 22.8999 22.5 20.8852 22.5 18.3999C22.5 17.1411 21.3514 16.003 19.5 15.1863M18.5 8.8999C18.5 12.9636 14 14.8999 12.5 17.8999C11 14.8999 6.5 12.9636 6.5 8.8999C6.5 5.58619 9.18629 2.8999 12.5 2.8999C15.8137 2.8999 18.5 5.58619 18.5 8.8999ZM13.5 8.8999C13.5 9.45219 13.0523 9.8999 12.5 9.8999C11.9477 9.8999 11.5 9.45219 11.5 8.8999C11.5 8.34762 11.9477 7.8999 12.5 7.8999C13.0523 7.8999 13.5 8.34762 13.5 8.8999Z"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const menu_dashboard_icon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4823 3.6639C13.131 3.39065 12.9553 3.25402 12.7613 3.2015C12.5902 3.15516 12.4098 3.15516 12.2387 3.2015C12.0447 3.25402 11.869 3.39065 11.5177 3.6639L4.73539 8.93903C4.28202 9.29165 4.05534 9.46796 3.89203 9.68876C3.74737 9.88435 3.6396 10.1047 3.57403 10.339C3.5 10.6034 3.5 10.8906 3.5 11.465V18.6999C3.5 19.82 3.5 20.38 3.71799 20.8079C3.90973 21.1842 4.21569 21.4902 4.59202 21.6819C5.01984 21.8999 5.5799 21.8999 6.7 21.8999H8.7C8.98003 21.8999 9.12004 21.8999 9.227 21.8454C9.32108 21.7975 9.39757 21.721 9.4455 21.6269C9.5 21.5199 9.5 21.3799 9.5 21.0999V14.4999C9.5 13.9398 9.5 13.6598 9.60899 13.4459C9.70487 13.2577 9.85785 13.1048 10.046 13.0089C10.2599 12.8999 10.5399 12.8999 11.1 12.8999H13.9C14.4601 12.8999 14.7401 12.8999 14.954 13.0089C15.1422 13.1048 15.2951 13.2577 15.391 13.4459C15.5 13.6598 15.5 13.9398 15.5 14.4999V21.0999C15.5 21.3799 15.5 21.5199 15.5545 21.6269C15.6024 21.721 15.6789 21.7975 15.773 21.8454C15.88 21.8999 16.02 21.8999 16.3 21.8999H18.3C19.4201 21.8999 19.9802 21.8999 20.408 21.6819C20.7843 21.4902 21.0903 21.1842 21.282 20.8079C21.5 20.38 21.5 19.82 21.5 18.6999V11.465C21.5 10.8906 21.5 10.6034 21.426 10.339C21.3604 10.1047 21.2526 9.88435 21.108 9.68876C20.9447 9.46796 20.718 9.29165 20.2646 8.93903L13.4823 3.6639Z"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const menu_user_icon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 20.8999C5.83579 18.4225 9.00702 16.8999 12.5 16.8999C15.993 16.8999 19.1642 18.4225 21.5 20.8999M17 8.3999C17 10.8852 14.9853 12.8999 12.5 12.8999C10.0147 12.8999 8 10.8852 8 8.3999C8 5.91462 10.0147 3.8999 12.5 3.8999C14.9853 3.8999 17 5.91462 17 8.3999Z"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// export const menu_location_icon = (
//   <svg
//     width="25"
//     height="25"
//     viewBox="0 0 25 25"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M3.5 20.8999C5.83579 18.4225 9.00702 16.8999 12.5 16.8999C15.993 16.8999 19.1642 18.4225 21.5 20.8999M17 8.3999C17 10.8852 14.9853 12.8999 12.5 12.8999C10.0147 12.8999 8 10.8852 8 8.3999C8 5.91462 10.0147 3.8999 12.5 3.8999C14.9853 3.8999 17 5.91462 17 8.3999Z"
//       stroke="#1F2933"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     />
//   </svg>
// );

export const menu_teacher_icon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 11.8999H8.5M10.5 15.8999H8.5M16.5 7.8999H8.5M20.5 11.3999V7.6999C20.5 6.01974 20.5 5.17967 20.173 4.53793C19.8854 3.97344 19.4265 3.5145 18.862 3.22688C18.2202 2.8999 17.3802 2.8999 15.7 2.8999H9.3C7.61984 2.8999 6.77976 2.8999 6.13803 3.22688C5.57354 3.5145 5.1146 3.97344 4.82698 4.53793C4.5 5.17967 4.5 6.01974 4.5 7.6999V18.0999C4.5 19.7801 4.5 20.6201 4.82698 21.2619C5.1146 21.8264 5.57354 22.2853 6.13803 22.5729C6.77976 22.8999 7.61984 22.8999 9.3 22.8999H12M22.5 22.8999L21 21.3999M22 18.8999C22 20.8329 20.433 22.3999 18.5 22.3999C16.567 22.3999 15 20.8329 15 18.8999C15 16.9669 16.567 15.3999 18.5 15.3999C20.433 15.3999 22 16.9669 22 18.8999Z"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const menu_schedule_icon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5 10.8999H3.5M16.5 2.8999V6.8999M8.5 2.8999V6.8999M9.5 16.8999L11.5 18.8999L16 14.3999M8.3 22.8999H16.7C18.3802 22.8999 19.2202 22.8999 19.862 22.5729C20.4265 22.2853 20.8854 21.8264 21.173 21.2619C21.5 20.6201 21.5 19.7801 21.5 18.0999V9.6999C21.5 8.01974 21.5 7.17967 21.173 6.53793C20.8854 5.97344 20.4265 5.5145 19.862 5.22688C19.2202 4.8999 18.3802 4.8999 16.7 4.8999H8.3C6.61984 4.8999 5.77976 4.8999 5.13803 5.22688C4.57354 5.5145 4.1146 5.97344 3.82698 6.53793C3.5 7.17967 3.5 8.01974 3.5 9.6999V18.0999C3.5 19.7801 3.5 20.6201 3.82698 21.2619C4.1146 21.8264 4.57354 22.2853 5.13803 22.5729C5.77976 22.8999 6.61984 22.8999 8.3 22.8999Z"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const menu_analytics_icon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5 21.8999H5.1C4.53995 21.8999 4.25992 21.8999 4.04601 21.7909C3.85785 21.695 3.70487 21.5421 3.60899 21.3539C3.5 21.14 3.5 20.86 3.5 20.2999V3.8999M21.5 7.8999L16.0657 13.3342C15.8677 13.5322 15.7687 13.6312 15.6545 13.6683C15.5541 13.701 15.4459 13.701 15.3455 13.6683C15.2313 13.6312 15.1323 13.5322 14.9343 13.3342L13.0657 11.4656C12.8677 11.2676 12.7687 11.1686 12.6545 11.1315C12.5541 11.0989 12.4459 11.0989 12.3455 11.1315C12.2313 11.1686 12.1323 11.2676 11.9343 11.4656L7.5 15.8999M21.5 7.8999H17.5M21.5 7.8999V11.8999"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const sceneroy_icon = color => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5604 16.2733L9.05752 10.7761C9.38753 10.4461 9.55254 10.2811 9.74281 10.2193C9.91018 10.1649 10.0905 10.1649 10.2578 10.2193C10.4481 10.2811 10.6131 10.4461 10.9431 10.7761L16.4036 16.2366M11.667 11.5L14.0575 9.10948C14.3875 8.77946 14.5525 8.61445 14.7428 8.55263C14.9102 8.49825 15.0905 8.49825 15.2578 8.55263C15.4481 8.61445 15.6131 8.77946 15.9431 9.10947L18.3337 11.5M8.33366 6.5C8.33366 7.42047 7.58747 8.16667 6.66699 8.16667C5.74652 8.16667 5.00033 7.42047 5.00033 6.5C5.00033 5.57953 5.74652 4.83333 6.66699 4.83333C7.58747 4.83333 8.33366 5.57953 8.33366 6.5ZM5.66699 16.5H14.3337C15.7338 16.5 16.4339 16.5 16.9686 16.2275C17.439 15.9878 17.8215 15.6054 18.0612 15.135C18.3337 14.6002 18.3337 13.9001 18.3337 12.5V5.5C18.3337 4.09987 18.3337 3.3998 18.0612 2.86502C17.8215 2.39462 17.439 2.01217 16.9686 1.77248C16.4339 1.5 15.7338 1.5 14.3337 1.5H5.66699C4.26686 1.5 3.5668 1.5 3.03202 1.77248C2.56161 2.01217 2.17916 2.39462 1.93948 2.86502C1.66699 3.3998 1.66699 4.09987 1.66699 5.5V12.5C1.66699 13.9001 1.66699 14.6002 1.93948 15.135C2.17916 15.6054 2.56161 15.9878 3.03202 16.2275C3.5668 16.5 4.26686 16.5 5.66699 16.5Z"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const player_icon = color => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.91667 6.47111C6.91667 6.07337 6.91667 5.8745 6.99978 5.76348C7.07222 5.66673 7.18309 5.6062 7.30365 5.59759C7.44199 5.58771 7.60927 5.69524 7.94384 5.91032L11.8777 8.43921C12.168 8.62585 12.3131 8.71917 12.3633 8.83783C12.4071 8.9415 12.4071 9.0585 12.3633 9.16217C12.3131 9.28083 12.168 9.37415 11.8777 9.56079L7.94384 12.0897C7.60927 12.3048 7.44199 12.4123 7.30365 12.4024C7.18309 12.3938 7.07222 12.3333 6.99978 12.2365C6.91667 12.1255 6.91667 11.9266 6.91667 11.5289V6.47111Z"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 5.5C1.5 4.09987 1.5 3.3998 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.3998 1.5 4.09987 1.5 5.5 1.5H12.5C13.9001 1.5 14.6002 1.5 15.135 1.77248C15.6054 2.01217 15.9878 2.39462 16.2275 2.86502C16.5 3.3998 16.5 4.09987 16.5 5.5V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V5.5Z"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const section_icon = color => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8333 7.33333C14.7668 7.33333 15.2335 7.33333 15.59 7.15168C15.9036 6.99189 16.1586 6.73692 16.3183 6.42332C16.5 6.0668 16.5 5.60009 16.5 4.66667V4.16667C16.5 3.23325 16.5 2.76654 16.3183 2.41002C16.1586 2.09641 15.9036 1.84145 15.59 1.68166C15.2335 1.5 14.7668 1.5 13.8333 1.5L4.16667 1.5C3.23325 1.5 2.76654 1.5 2.41002 1.68166C2.09641 1.84144 1.84144 2.09641 1.68166 2.41002C1.5 2.76654 1.5 3.23325 1.5 4.16667L1.5 4.66667C1.5 5.60009 1.5 6.0668 1.68166 6.42332C1.84144 6.73692 2.09641 6.99189 2.41002 7.15168C2.76654 7.33333 3.23325 7.33333 4.16667 7.33333L13.8333 7.33333Z"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8333 16.5C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1586 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7668 16.5 13.8333V13.3333C16.5 12.3999 16.5 11.9332 16.3183 11.5767C16.1586 11.2631 15.9036 11.0081 15.59 10.8483C15.2335 10.6667 14.7668 10.6667 13.8333 10.6667L4.16667 10.6667C3.23325 10.6667 2.76654 10.6667 2.41002 10.8483C2.09641 11.0081 1.84144 11.2631 1.68166 11.5767C1.5 11.9332 1.5 12.3999 1.5 13.3333L1.5 13.8333C1.5 14.7668 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1586 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333Z"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const text_icon = color => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33301 3.83301C1.33301 3.05644 1.33301 2.66815 1.45988 2.36187C1.62903 1.95349 1.95349 1.62903 2.36187 1.45988C2.66815 1.33301 3.05644 1.33301 3.83301 1.33301H12.1663C12.9429 1.33301 13.3312 1.33301 13.6375 1.45988C14.0459 1.62903 14.3703 1.95349 14.5395 2.36187C14.6663 2.66815 14.6663 3.05644 14.6663 3.83301M5.49967 14.6663H10.4997M7.99967 1.33301V14.6663"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const add_circle_icon = color => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0003 6.66699V13.3337M6.66699 10.0003H13.3337M18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z"
      stroke={color || '#1F2933'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AssignFormIcon = ({ color, className }) => {
  return (
    <svg
      className={className}
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3332 8.3335V4.5335C12.3332 3.41339 12.3332 2.85334 12.1152 2.42552C11.9234 2.04919 11.6175 1.74323 11.2412 1.55148C10.8133 1.3335 10.2533 1.3335 9.13317 1.3335H4.8665C3.7464 1.3335 3.18635 1.3335 2.75852 1.55148C2.3822 1.74323 2.07624 2.04919 1.88449 2.42552C1.6665 2.85334 1.6665 3.41339 1.6665 4.5335V11.4668C1.6665 12.5869 1.6665 13.147 1.88449 13.5748C2.07624 13.9511 2.3822 14.2571 2.75852 14.4488C3.18635 14.6668 3.7464 14.6668 4.8665 14.6668H6.99984M8.33317 7.3335H4.33317M5.6665 10.0002H4.33317M9.6665 4.66683H4.33317M8.6665 12.6668L9.99984 14.0002L12.9998 11.0002"
        stroke={color ? color : colorPrimary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IMG_TEACHER_DASHBOARD = '/images/teacher_dashboard.svg';

export const IMG_COURSES_ICON = '/images/courses_icon.svg';

export const IMG_CONTINUE_LEARNING_COURSE =
  '/images/continue_learning_course.svg';

export const IMG_RECOMMENDED_COURSE_1 = 'images/recommended_course_1.svg';

export const IMG_RECOMMENDED_COURSE_2 = 'images/recommended_course_2.svg';

export const IMG_CLOCK_ICON = 'images/clock_icon.svg';

export const IMG_OBSERVATION_ICON = 'images/observation_icon.svg';

export const IMG_FORWARD_ARROW_ICON = 'images/forward_arrow_icon.svg';

export const PreviewFormIcon = ({ color, className }) => {
  return (
    <svg
      className={className}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33366 7.33301H4.33366M5.66699 9.99967H4.33366M9.66699 4.66634H4.33366M12.3337 6.99967V4.53301C12.3337 3.4129 12.3337 2.85285 12.1157 2.42503C11.9239 2.0487 11.618 1.74274 11.2416 1.55099C10.8138 1.33301 10.2538 1.33301 9.13366 1.33301H4.86699C3.74689 1.33301 3.18683 1.33301 2.75901 1.55099C2.38269 1.74274 2.07673 2.0487 1.88498 2.42503C1.66699 2.85285 1.66699 3.4129 1.66699 4.53301V11.4663C1.66699 12.5864 1.66699 13.1465 1.88498 13.5743C2.07673 13.9506 2.38269 14.2566 2.75901 14.4484C3.18683 14.6663 3.74689 14.6663 4.86699 14.6663H6.66699M13.667 14.6663L12.667 13.6663M13.3337 11.9997C13.3337 13.2883 12.289 14.333 11.0003 14.333C9.71166 14.333 8.66699 13.2883 8.66699 11.9997C8.66699 10.711 9.71166 9.66634 11.0003 9.66634C12.289 9.66634 13.3337 10.711 13.3337 11.9997Z"
        stroke={color || '#1F2933'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FileIcon = ({ color, className }) => {
  return (
    <svg
      width="15"
      className={className}
      height="17"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1.26946V5.4C11 5.96005 11 6.24008 11.109 6.45399C11.2049 6.64215 11.3578 6.79513 11.546 6.89101C11.7599 7 12.0399 7 12.6 7H16.7305M17 8.98822V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H5.8C4.11984 21 3.27976 21 2.63803 20.673C2.07354 20.3854 1.6146 19.9265 1.32698 19.362C1 18.7202 1 17.8802 1 16.2V5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H9.01178C9.74555 1 10.1124 1 10.4577 1.08289C10.7638 1.15638 11.0564 1.27759 11.3249 1.44208C11.6276 1.6276 11.887 1.88703 12.4059 2.40589L15.5941 5.59411C16.113 6.11297 16.3724 6.3724 16.5579 6.67515C16.7224 6.94356 16.8436 7.2362 16.9171 7.5423C17 7.88757 17 8.25445 17 8.98822Z"
        stroke={color || '#1F2933'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const menu_observation_icon = (
  <svg
    // className={}
    width="18"
    height="23"
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 10.8999H5M7 14.8999H5M13 6.8999H5M17 6.6999V17.0999C17 18.7801 17 19.6201 16.673 20.2619C16.3854 20.8264 15.9265 21.2853 15.362 21.5729C14.7202 21.8999 13.8802 21.8999 12.2 21.8999H5.8C4.11984 21.8999 3.27976 21.8999 2.63803 21.5729C2.07354 21.2853 1.6146 20.8264 1.32698 20.2619C1 19.6201 1 18.7801 1 17.0999V6.6999C1 5.01974 1 4.17967 1.32698 3.53793C1.6146 2.97344 2.07354 2.5145 2.63803 2.22688C3.27976 1.8999 4.11984 1.8999 5.8 1.8999H12.2C13.8802 1.8999 14.7202 1.8999 15.362 2.22688C15.9265 2.5145 16.3854 2.97344 16.673 3.53793C17 4.17967 17 5.01974 17 6.6999Z"
      stroke={'#1F2933'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const menu_courses_icon = (
  <svg
    width="18"
    height="19"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.57181 19.8999C7.90661 19.2597 9.40997 18.8999 11 18.8999C12.59 18.8999 14.0934 19.2597 15.4282 19.8999M5.8 15.8999H16.2C17.8802 15.8999 18.7202 15.8999 19.362 15.5729C19.9265 15.2853 20.3854 14.8264 20.673 14.2619C21 13.6201 21 12.7801 21 11.0999V6.6999C21 5.01974 21 4.17967 20.673 3.53793C20.3854 2.97344 19.9265 2.5145 19.362 2.22688C18.7202 1.8999 17.8802 1.8999 16.2 1.8999H5.8C4.11984 1.8999 3.27976 1.8999 2.63803 2.22688C2.07354 2.5145 1.6146 2.97344 1.32698 3.53793C1 4.17967 1 5.01975 1 6.6999V11.0999C1 12.7801 1 13.6201 1.32698 14.2619C1.6146 14.8264 2.07354 15.2853 2.63803 15.5729C3.27976 15.8999 4.11984 15.8999 5.8 15.8999Z"
      stroke="#1F2933"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const fun_stories_icon = (
  <svg
    width="52"
    height="52"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.6267 13.1577L40.4318 38.4899C40.4318 38.9897 40.027 39.3937 39.5272 39.3937L37.8564 39.8571H24.2644C24.163 39.8571 24.0632 39.8764 23.9706 39.9134C23.8894 39.9448 23.8137 39.6555 23.7461 39.7126C23.0145 40.3275 22.0898 40.6656 21.1336 40.6656H20.8664C20.3883 40.6656 19.9183 40.5811 19.4757 40.4193C19.0338 40.2583 18.6193 40.0201 18.2539 39.7126C18.1863 39.6555 18.1107 39.9449 18.0294 39.9134C17.9368 39.8764 17.837 39.8571 17.7356 39.8571H1.90463C1.40483 39.8571 1 39.4531 1 38.9533V12.8816C1 12.3826 1.40483 11.9778 1.90463 11.9778H17.6994C17.9127 11.9778 18.1187 11.9022 18.2821 11.7653L19.1336 11.0474C19.297 10.9106 19.503 10.835 19.7163 10.835H22.2837C22.497 10.835 22.703 10.9106 22.8664 11.0474L23.7179 11.7653C23.8813 11.9022 24.0873 11.9778 24.3006 11.9778H38.1473L39.7221 12.2539C40.2219 12.2539 40.6267 12.6587 40.6267 13.1577Z"
      fill="#FCEBC5"
    />
    <path
      d="M21.3035 11.7653C21.4669 11.9022 21.6729 11.9778 21.8862 11.9778H24.301C24.0877 11.9778 23.8816 11.9022 23.7183 11.7653L22.8668 11.0474C22.7034 10.9106 22.4973 10.835 22.2841 10.835H19.8693C20.0826 10.835 20.2886 10.9106 20.452 11.0474L21.3035 11.7653Z"
      fill="#FCEBC5"
    />
    <path
      d="M40.0956 11.9775H37.6809C37.6809 11.9775 37.9005 12.3824 37.9005 12.8814V35.7517L38.5855 37.3081V38.9531C38.5855 39.4529 38.1807 39.8569 37.6809 39.8569H40.0956C40.5954 39.8569 41.0003 39.4529 41.0003 38.9531V12.8814C41.0003 12.3824 40.5954 11.9775 40.0956 11.9775Z"
      fill="#FCEBC5"
    />
    <path
      d="M23.9704 35.1675V39.9136C23.8891 39.9449 23.8134 39.99 23.7458 40.0472C23.0142 40.6621 22.0895 41.0001 21.1333 41.0001H20.8661C20.3881 41.0001 19.918 40.9156 19.4754 40.7538C19.0335 40.5928 18.6191 40.3546 18.2537 40.0472C18.1861 39.99 18.1104 39.9449 18.0291 39.9136V35.5176L23.9704 35.1675Z"
      fill="#FCEBC5"
    />
    <path
      d="M3.41132 37.3079V10.9259C3.41132 10.6174 3.72053 10.3996 4.02001 10.4971C7.26741 11.555 10.7259 11.8417 14.1093 11.3337L21.0003 10.2988L27.8912 11.3336C30.7771 11.767 33.7177 11.622 36.5353 10.9136C37.021 10.7915 37.3421 10.8136 37.8195 10.6581C38.119 10.5606 38.4282 10.7783 38.4282 11.0868V37.147C38.4282 37.3966 38.2212 37.5989 37.9658 37.5989L36.232 37.7599H3.87377C3.6184 37.7599 3.41132 37.5576 3.41132 37.3079Z"
      fill="#FCEBC5"
    />
    <path
      d="M37.9806 10.4977C37.5039 10.6529 37.0225 10.7912 36.5376 10.9131C36.5377 10.9175 36.5384 10.9219 36.5384 10.9264V37.3085C36.5384 37.558 36.3292 37.7604 36.0712 37.7604H38.1269C38.3822 37.7604 38.5893 37.558 38.5893 37.3085V10.9264C38.5893 10.6179 38.28 10.4001 37.9806 10.4977Z"
      fill="#FCEBC5"
    />
    <path
      d="M21.2784 13.8486C16.667 13.8486 12.881 16.7903 12.881 23.1305H14.3902C15.1452 23.1305 15.3967 22.3629 15.5692 21.6282C15.801 20.6407 16.7195 20.0135 17.4086 19.3154C18.3943 18.3166 19.764 17.6973 21.2784 17.6973C22.6679 17.6973 23.9353 18.2184 24.8963 19.0757C25.715 19.8061 26.8626 20.595 27.1217 21.6991C27.2942 22.4337 27.4115 23.1305 28.1665 23.1305H29.6758C29.6758 17.0738 25.8898 13.8486 21.2784 13.8486Z"
      fill="#FEF8EC"
    />
    <path
      d="M29.6759 23.1306C29.6759 20.5059 28.9645 18.4134 27.7834 16.8843C27.5802 16.992 27.4028 17.1009 27.2177 17.2367C26.8413 17.5127 26.4879 17.8577 26.2064 18.2356C26.0814 18.4035 25.9144 18.5375 25.7215 18.6191C25.4287 18.743 25.1527 18.897 24.8964 19.0765C25.7099 19.8022 26.3038 20.7681 26.5657 21.8634C26.7431 22.6055 27.4037 23.1306 28.1668 23.1306H29.6759Z"
      fill="#FEF8EC"
    />
    <path
      d="M14.5442 17.0747C13.5009 18.5575 12.881 20.5661 12.881 23.131H14.3677C15.1327 23.131 15.8127 22.6153 15.9895 21.8711C16.2227 20.8893 16.7228 20.0108 17.4086 19.3159C17.0503 19.015 16.6499 18.7735 16.221 18.5978C16.0649 18.5339 15.931 18.427 15.8321 18.2902C15.6192 17.9962 15.3718 17.7271 15.0991 17.4901C14.9242 17.338 14.7396 17.1997 14.5442 17.0747Z"
      fill="#FEF8EC"
    />
    <path
      d="M21.2785 11.1553C15.1704 11.1553 10.1234 15.7982 10.1234 23.1307L12.2732 22.4081C12.2936 20.2465 13.7387 18.9853 15.0992 17.4896C16.6339 15.8024 18.8341 14.7363 21.2785 14.7362C23.5842 14.7362 25.7002 15.713 27.2177 17.2366C28.7352 18.7603 30.5041 20.4094 30.5258 22.7092L32.4347 23.1306C32.4348 15.651 27.4136 11.1553 21.2785 11.1553Z"
      fill="#FCEBC5"
    />
    <path
      d="M29.6761 23.1305H32.4349C32.4349 20.492 31.8093 18.2254 30.7312 16.3958C30.5157 16.3616 30.3109 16.3422 30.0888 16.3378C30.0578 16.3371 30.0124 16.3359 29.9813 16.3359C28.9666 16.3359 28.0045 16.6651 27.218 17.2366C28.7191 18.7579 29.6545 20.8447 29.6761 23.1305Z"
      fill="#FCEBC5"
    />
    <path
      d="M13.0105 16.4412C12.8409 16.4044 12.6648 16.3771 12.4902 16.3596C12.3332 16.3438 12.178 16.3359 12.019 16.3359C11.9812 16.3359 11.9436 16.3375 11.906 16.3384C10.78 18.1916 10.1234 20.486 10.1234 23.1305H12.8811C12.9015 20.9689 13.7387 18.9852 15.0992 17.4895C14.5088 16.9763 13.7926 16.6086 13.0105 16.4412Z"
      fill="#FCEBC5"
    />
    <path
      d="M34.602 21.141C34.4254 19.8246 34.4169 18.7052 33.8959 17.5336C31.7483 12.7043 26.9073 9.33643 21.2789 9.33643C15.6907 9.33643 10.8783 12.6561 8.70792 17.4301C8.16192 18.631 8.53907 20.1669 8.35718 21.522C8.22543 22.5036 8.24241 23.1306 9.23323 23.1306L9.57987 22.3372C9.61231 20.0448 10.371 18.5961 11.671 16.9322C11.806 16.7594 12.3538 16.5372 12.4905 16.3596C14.5325 13.7078 17.7098 11.9787 21.2788 11.9787C24.8604 11.9787 28.0476 13.6927 30.0889 16.3377C30.3023 16.6143 31.089 16.7036 31.2881 16.9853C32.4464 18.6242 32.7347 20.5657 32.7501 22.7402L33.3244 23.1305C34.3153 23.1306 34.7338 22.1226 34.602 21.141Z"
      fill="#F7D178"
    />
    <path
      d="M9.23276 23.1305H10.1231C10.159 20.6 11.0372 18.2462 12.49 16.3596C12.3338 16.344 12.1769 16.3359 12.0188 16.3359C11.007 16.3359 10.0291 16.6666 9.23018 17.2532C9.09682 17.3511 8.93956 17.4117 8.77457 17.4245C8.75219 17.4262 8.72958 17.4279 8.70728 17.43C8.16322 18.6266 7.78527 19.9146 7.60257 21.2646C7.46913 22.2518 8.23654 23.1305 9.23276 23.1305Z"
      fill="#F7D178"
    />
    <path
      d="M34.9535 21.2601C34.7759 19.9504 34.414 18.6993 33.8955 17.5332C33.6591 17.4753 33.4148 17.4375 33.1653 17.4219C33.1449 17.4201 33.1247 17.4185 33.1046 17.4172C33.0192 17.4115 32.9387 17.3784 32.8713 17.3257C32.0829 16.7088 31.1159 16.3602 30.0886 16.3374C31.5439 18.2231 32.4166 20.5819 32.4347 23.1302H33.3241C34.3219 23.1302 35.0876 22.2489 34.9535 21.2601Z"
      fill="#F7D178"
    />
    <path
      d="M36.3765 23.2962C36.3443 24.3997 35.5709 25.1015 34.4674 25.1015L30.8088 25.2624H27.5411C26.16 25.2624 24.9833 24.1333 24.9922 22.7522C25.0002 21.4628 25.9869 20.4061 27.2465 20.2862C27.3383 20.2773 27.4171 20.217 27.4485 20.13C27.8212 19.0918 28.8135 18.3481 29.9805 18.3481C30.8521 18.3481 31.6264 18.7626 32.1181 19.4049C32.1777 19.4821 32.2775 19.516 32.3717 19.491C32.5415 19.4475 32.7194 19.4307 32.9037 19.4242C33.5533 19.4011 34.6381 20.3192 34.8369 21.2946C34.8538 21.3783 34.9149 21.4467 34.9962 21.4741C35.8171 21.7502 36.4039 22.3763 36.3765 23.2962Z"
      fill="#FEF8EC"
    />
    <path
      d="M16.2425 23.1308C16.2513 24.5118 12.6151 25.2624 11.234 25.2624H7.53179C6.42837 25.2624 5.49396 24.3997 5.46177 23.2962C5.4344 22.361 6.04044 21.5634 6.8831 21.3003C6.93943 21.2826 6.9829 21.2343 6.99336 21.1755C7.17525 20.1799 8.04688 19.4242 9.09557 19.4242C9.29839 19.4242 9.49396 19.4524 9.67907 19.5055C9.74185 19.5232 9.81026 19.4999 9.84889 19.4475C10.3382 18.7811 11.1278 18.3481 12.0187 18.3481C12.2127 18.3481 12.4018 18.3683 12.5837 18.4077C13.5125 18.6065 14.0268 19.4318 14.3286 20.3171C14.3503 20.3807 14.4058 20.4241 14.4726 20.429C15.7531 20.5288 16.2344 21.8262 16.2425 23.1308Z"
      fill="#FEF8EC"
    />
    <path
      d="M8.27924 1.1731L10.0978 2.06581C10.2392 2.13519 10.4057 2.1306 10.5431 2.05358L12.3098 1.06227C12.6557 0.868227 13.0741 1.15483 13.0178 1.54726L12.7303 3.55193C12.7129 3.67298 12.7429 3.79443 12.8113 3.8927C12.831 3.92095 12.8366 4.02091 12.8625 4.04473L13.7973 5.2564C14.0888 5.52521 13.9454 6.01149 13.5547 6.07926L11.9831 6.35958C11.828 6.38646 11.696 6.48803 11.6301 6.63105L10.9265 8.40417C10.7608 8.76433 10.2538 8.77833 10.0685 8.42775L9.16986 6.84248C9.14403 6.79362 9.06644 6.76602 9.02693 6.72964C8.95369 6.66228 8.86033 6.61817 8.75876 6.60626L6.74644 6.37028C6.35248 6.32409 6.18258 5.84642 6.45888 5.56199L7.87039 4.10903C7.98008 3.99612 8.02717 3.83644 7.99634 3.68207L7.59908 1.69616C7.52125 1.30742 7.92326 0.998288 8.27924 1.1731Z"
      fill="#FCEBC5"
    />
    <path
      d="M27.5257 29.4866L29.1317 29.5827C29.2565 29.5902 29.3771 29.5358 29.454 29.4372L30.4437 28.1693C30.6374 27.9211 31.0317 28.0027 31.111 28.3074L31.5158 29.8637C31.5473 29.9846 31.6363 30.0824 31.7539 30.1251L32.1279 30.2608L32.8969 30.7333C33.193 30.8407 33.2372 31.2408 32.9718 31.4103L31.5718 31.9597C31.4664 32.027 31.4009 32.1418 31.3966 32.2667L31.5186 33.7495C31.5078 34.0641 31.1408 34.2298 30.8975 34.0299L29.9997 32.8811C29.9031 32.8018 29.7736 32.775 29.6534 32.8095L28.4528 33.2887C28.1501 33.3757 27.8791 33.078 27.9941 32.7849L27.9266 32.2739L28.1275 31.7621C28.1732 31.6457 28.1587 31.5144 28.0886 31.4108L27.1881 30.0783C27.0119 29.8175 27.2114 29.4678 27.5257 29.4866Z"
      fill="#FCEBC5"
    />
    <path
      d="M33.2659 30.6739L32.1278 30.2607C31.2229 31.4848 29.7697 32.279 28.1307 32.279C28.0623 32.279 27.9945 32.2766 27.9266 32.2739L27.5399 33.259C27.4249 33.552 27.6959 33.8497 27.9986 33.7627L29.5448 33.3184C29.665 33.2838 29.7945 33.3106 29.8911 33.39L31.1339 34.4112C31.3772 34.6111 31.7442 34.4454 31.7549 34.1308L31.81 32.5237C31.8143 32.3988 31.8798 32.2839 31.9852 32.2166L33.3409 31.3509C33.6062 31.1815 33.5619 30.7814 33.2659 30.6739Z"
      fill="#FEF8EC"
    />
    <path
      d="M17.0074 22.7522C17.0163 24.1333 15.8396 25.2624 14.4585 25.2624H11.2344C15.5137 22.8222 12.9567 18.9325 12.5841 18.4077C13.5128 18.6065 14.2638 19.285 14.5656 20.1703C14.5873 20.2339 14.6428 20.2773 14.7096 20.2822C15.9901 20.382 16.9994 21.4476 17.0074 22.7522Z"
      fill="#FCEBC5"
    />
    <path
      d="M14.3685 5.34441L12.8794 3.97121C12.8534 3.94739 12.8308 3.92083 12.8112 3.89258C12.1218 5.38787 10.7127 6.48188 9.02679 6.72952C9.06639 6.7659 9.1002 6.80896 9.12603 6.85781L10.0723 8.64848C10.2576 8.99906 10.7646 8.98505 10.9303 8.62489L11.7766 6.78497C11.8424 6.64196 11.9744 6.54039 12.1296 6.5135L14.1259 6.16727C14.5166 6.0995 14.66 5.61322 14.3685 5.34441Z"
      fill="#FEF8EC"
    />
    <path
      d="M36.5385 23.2964C36.5063 24.3998 35.5719 25.2625 34.4685 25.2625H30.792C35.3111 23.4919 32.9047 19.4243 32.9047 19.4243C33.9381 19.4243 34.8 20.1583 34.9988 21.1338C35.0157 21.2175 35.0769 21.2859 35.1582 21.3133C35.9791 21.5893 36.5658 22.3764 36.5385 23.2964Z"
      fill="#FCEBC5"
    />
    <path
      d="M3.25328 11.811H1.90431C1.40491 11.811 1 12.2157 1 12.7149V38.7868C1 39.286 1.40483 39.6906 1.90431 39.6906H17.7357C17.9255 39.6906 18.1092 39.7577 18.2543 39.8799C18.9853 40.4956 19.9104 40.8333 20.8662 40.8333H21.1339C22.0898 40.8333 23.0149 40.4956 23.7458 39.8799C23.891 39.7577 24.0747 39.6906 24.2645 39.6906H40.0959C40.5953 39.6906 41.0002 39.286 41.0002 38.7868V12.7149C41.0002 12.2157 40.5954 11.811 40.0959 11.811H38.7602"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0291 39.8578V37.9307"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.9709 39.8578V37.8916"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33087 11.5268C10.861 11.6315 12.4031 11.5765 13.931 11.3599"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.94918 37.7604H3.87377C3.6184 37.7604 3.41132 37.558 3.41132 37.3085V10.9264C3.41132 10.6179 3.72053 10.4001 4.02001 10.4977C4.81759 10.7575 5.6279 10.9707 6.44673 11.1372"
      stroke="#EA7804"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.5892 33.4443V37.3082C38.5892 37.5577 38.3822 37.7601 38.1268 37.7601H8.76605"
      stroke="#EA7804"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.8167 11.4532C31.9022 11.7836 35.0281 11.4591 37.9798 10.4977C38.2793 10.4001 38.5885 10.6179 38.5885 10.9264V30.6277"
      stroke="#EA7804"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 17.856V37.5697"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.8 20.3605C33.517 14.0698 27.9503 9.33643 21.2775 9.33643C14.877 9.33643 9.49403 13.6916 7.93323 19.5988"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3275 20.8829C17.1828 19.0037 19.0776 17.6973 21.2778 17.6973C23.3289 17.6973 25.1147 18.8327 26.0403 20.5092"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9573 19.0121C15.3978 16.4595 18.1362 14.7358 21.2773 14.7358C24.2671 14.7358 26.892 16.2974 28.3802 18.649"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1955 18.3483C12.9868 14.5824 16.828 11.979 21.2775 11.979C25.8447 11.979 29.7709 14.7218 31.4972 18.6493"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.684 25.2618H34.5101C35.6297 25.2618 36.5372 24.3542 36.5372 23.2347C36.5372 22.2913 35.8928 21.4986 35.0202 21.2723C34.879 20.2284 33.9848 19.4235 32.9021 19.4235C32.661 19.4235 32.4295 19.464 32.2132 19.5376C31.73 18.8198 30.91 18.3477 29.9796 18.3477C28.7587 18.3477 27.7284 19.1611 27.3994 20.2753C26.0616 20.3207 24.9909 21.4187 24.9909 22.7675C24.9909 24.1452 26.1077 25.2619 27.4853 25.2619H30.8671"
      stroke="#EA7804"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.97668 21.2723C7.11793 20.2284 8.01209 19.4235 9.09475 19.4235C9.33588 19.4235 9.56742 19.464 9.78368 19.5376C10.2669 18.8198 11.0869 18.3477 12.0172 18.3477C13.2382 18.3477 14.2685 19.1611 14.5975 20.2753C15.9352 20.3207 17.006 21.4187 17.006 22.7675C17.006 24.1452 15.8892 25.2619 14.5116 25.2619H7.48678C6.36718 25.2619 5.45966 24.3543 5.45966 23.2348C5.45966 22.2913 6.104 21.4985 6.97668 21.2723Z"
      stroke="#EA7804"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.27932 1.1731L10.0979 2.06581C10.2393 2.13519 10.4058 2.1306 10.5431 2.05358L12.3099 1.06227C12.6557 0.868227 13.0742 1.15483 13.0178 1.54726L12.7303 3.55193C12.708 3.70775 12.7638 3.86461 12.8796 3.97133L14.3687 5.34453C14.6602 5.61334 14.5168 6.09962 14.1261 6.16739L12.1298 6.51362C11.9746 6.5405 11.8426 6.64207 11.7768 6.78509L10.9304 8.62509C10.7647 8.98525 10.2578 8.99926 10.0725 8.64868L9.12616 6.85801C9.0526 6.71877 8.91522 6.62469 8.75876 6.60634L6.74644 6.37036C6.35248 6.32417 6.18258 5.8465 6.45888 5.56207L7.87047 4.10903C7.98017 3.99612 8.02725 3.83644 7.99642 3.68207L7.59916 1.69616C7.52133 1.30742 7.92334 0.998288 8.27932 1.1731Z"
      stroke="#EA7804"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5257 29.4866L29.1317 29.5827C29.2565 29.5902 29.3771 29.5358 29.454 29.4372L30.4436 28.1693C30.6373 27.9211 31.0317 28.0027 31.111 28.3074L31.5158 29.8637C31.5473 29.9846 31.6363 30.0824 31.7539 30.1251L33.266 30.674C33.562 30.7815 33.6063 31.1816 33.3408 31.3511L31.9851 32.2168C31.8798 32.2841 31.8142 32.3989 31.8099 32.5239L31.7548 34.1309C31.7441 34.4455 31.3771 34.6113 31.1338 34.4113L29.891 33.3901C29.7944 33.3107 29.6649 33.2839 29.5447 33.3185L27.9985 33.7627C27.6959 33.8497 27.4248 33.552 27.5399 33.259L28.1276 31.7621C28.1733 31.6457 28.1587 31.5144 28.0887 31.4108L27.1882 30.0783C27.0118 29.8175 27.2113 29.4678 27.5257 29.4866Z"
      stroke="#EA7804"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.3282 5.34961H33.0418"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.7474 5.34961H28.4609"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.3948 2.41895V3.70402"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.3948 6.99561V8.28068"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4294 30.3901H13.143"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.84849 30.3901H8.56213"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.496 27.4595V28.7445"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.496 32.0361V33.3212"
      stroke="#EA7804"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const prepare_exam_icon = (
  <svg
    width="52"
    height="55"
    viewBox="0 0 52 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.2857 37.9736H21.7143V44.9736H30.2857V37.9736Z"
      fill="#FCEBC5"
    />
    <path
      d="M27.6029 38.7079C28.0771 38.3308 30.3571 37.8551 30.4143 37.5008H33.4243C34.8114 37.5008 35.9457 36.7922 35.9457 35.9251C35.9457 35.0579 34.8114 34.3493 33.4243 34.3493H30.3186C29.9943 33.5265 29.9943 33.0065 30.3186 32.1851H30.9014C32.1629 32.1851 33.1943 31.5408 33.1943 30.7522C33.1943 29.9636 32.1629 29.3193 30.9014 29.3193H12.1014C10.84 29.3193 9.80857 29.9636 9.80857 30.7522C9.80857 31.5408 10.84 32.1851 12.1014 32.1851H13.1057C13.41 33.0079 13.41 33.5279 13.1057 34.3493H2.70142C1.31428 34.3493 0.179993 35.0579 0.179993 35.9251C0.179993 36.7922 1.31428 37.5008 2.70142 37.5008H12.3571C12.4143 37.8779 10.1671 38.2922 10.54 38.6922"
      fill="#FCEBC5"
    />
    <path
      d="M30.2171 47.1407H37.0471C38.4343 47.1407 39.5686 46.4322 39.5686 45.565C39.5686 44.6979 38.4343 43.9893 37.0471 43.9893H33.5443C33.39 42.9693 33.5229 42.4007 33.9429 41.8393H34.6614C36.0486 41.8393 37.1829 41.1307 37.1829 40.2636C37.1829 39.3964 36.0486 38.6879 34.6614 38.6879L6.73286 38.3179C5.34571 38.3179 4.09286 39.035 4.21143 39.8936C4.55714 42.4079 19.9786 41.0636 21.2871 41.1193C21.7386 41.6779 21.7386 42.9664 21.5729 43.9893H6.32429C4.93714 43.9893 3.80286 44.6979 3.80286 45.565C3.80286 46.4322 4.93714 47.1407 6.32429 47.1407H30.2171Z"
      fill="#FCEBC5"
    />
    <path
      d="M45.1857 37.8804C44.67 37.2418 44.5057 35.7704 44.6943 34.6018H48.1328C49.7171 34.6018 51.0128 33.7918 51.0128 32.8018C51.0128 31.8118 49.7157 31.0018 48.1328 31.0018H44.5857C44.2157 30.0633 44.2157 28.3418 44.5857 27.4033H45.2514C46.6914 27.4033 47.87 26.6675 47.87 25.7675C47.87 24.8675 46.6914 24.1318 45.2514 24.1318H23.7771C22.3371 24.1318 21.1585 24.8675 21.1585 25.7675C21.1585 26.6675 22.3371 27.4033 23.7771 27.4033H24.9243C25.2728 28.3418 25.2728 30.0633 24.9243 31.0018H13.04C11.4557 31.0018 10.16 31.8118 10.16 32.8018C10.16 33.7918 11.4571 34.6018 13.04 34.6018H21.1243C21.3 35.7661 21.1485 37.2318 20.6685 37.8747L45.1843 37.8804H45.1857Z"
      fill="#FCEBC5"
    />
    <path d="M44.1429 11.688H8V35.2594H44.1429V11.688Z" fill="#FCEBC5" />
    <path
      d="M17 17.2591C17 17.2591 22.2857 14.6876 25.7143 17.2591C25.7143 17.2591 31.4285 15.1162 34.7143 17.4019V28.6876C34.7143 28.6876 31.4285 25.8305 25.7143 28.4019C25.7143 28.4019 20.4285 26.4019 16.8571 28.4019L17 17.2591Z"
      fill="#F8D88B"
    />
    <path d="M44.1429 32.8306H8V39.402H44.1429V32.8306Z" fill="#F8D88B" />
    <path
      d="M33.7143 44.8306H18.1429V47.1163H33.7143V44.8306Z"
      fill="#FCEBC5"
    />
    <path
      d="M7.90146 39.9664H21.2615V44.2393H19.63C18.3872 44.2393 17.3772 45.2493 17.3772 46.4921V47.425C17.3772 47.7364 17.61 48.0464 17.9986 48.0464H34C34.3115 48.0464 34.6215 47.8136 34.6215 47.425V46.4921C34.6215 45.2493 33.6115 44.2393 32.3686 44.2393H30.7372V39.9664H44.0986C44.41 39.9664 44.72 39.7336 44.72 39.345V11.3807C44.5643 10.9921 44.3315 10.7593 44.0215 10.7593H7.90146C7.51289 10.7593 7.28003 10.9921 7.28003 11.3807V39.4221C7.28003 39.7336 7.51289 39.9664 7.90146 39.9664ZM32.2143 45.4036C32.8357 45.4036 33.3015 45.8693 33.3015 46.4907V46.8021H18.5429V46.4907C18.5429 45.8693 19.0086 45.4036 19.63 45.4036H32.2143ZM22.3486 44.2393V39.9664H29.4172V44.2393H22.3486ZM8.44432 11.925H43.4V32.4321H8.44432V11.925ZM8.44432 33.5964H43.4V38.8007H8.44432V33.5964Z"
      fill="#E88236"
    />
    <path
      d="M25.9229 16.4294C23.0486 14.798 19.4757 14.798 16.6014 16.5851C16.4457 16.6622 16.2914 16.8951 16.2914 17.0508V28.548C16.2914 29.0137 16.7572 29.2465 17.1457 29.0137C21.5729 26.2951 25.6129 29.0137 25.5343 29.0137C25.69 29.0908 25.9229 29.0908 26.0786 29.0137C28.6414 27.4608 31.9043 27.4608 34.4672 29.0137C34.8557 29.2465 35.3214 28.9365 35.3214 28.548V17.1294C35.3214 16.8965 35.2443 16.7408 35.01 16.6637C32.3686 14.8765 28.7957 14.7994 25.9214 16.4308L25.9229 16.4294ZM25.3786 27.6151C22.8929 26.4494 20.0186 26.4494 17.5329 27.6151V17.4394C19.9414 16.0408 22.8929 16.0408 25.3786 17.4394V27.6151ZM34.3114 27.6151C31.8257 26.4494 28.9514 26.4494 26.4657 27.6151V17.4394C28.8743 16.0408 31.8257 16.0408 34.3114 17.4394V27.6151Z"
      fill="#E88236"
    />
    <path
      d="M26.6986 35.6162H25.1457C24.8357 35.6162 24.5243 35.8491 24.5243 36.2376C24.5243 36.6262 24.7572 36.8591 25.1457 36.8591H26.6986C27.0086 36.8591 27.32 36.6262 27.32 36.2376C27.32 35.8491 27.01 35.6162 26.6986 35.6162Z"
      fill="#E88236"
    />
  </svg>
);

export const PDF_ICON = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33333 1.33325H9.13333C10.2534 1.33325 10.8135 1.33325 11.2413 1.55124C11.6176 1.74299 11.9236 2.04895 12.1153 2.42527C12.3333 2.85309 12.3333 3.41315 12.3333 4.53325V11.4666C12.3333 12.5867 12.3333 13.1467 12.1153 13.5746C11.9236 13.9509 11.6176 14.2569 11.2413 14.4486C10.8135 14.6666 10.2534 14.6666 9.13333 14.6666H4.86667C3.74656 14.6666 3.18651 14.6666 2.75869 14.4486C2.38236 14.2569 2.0764 13.9509 1.88465 13.5746C1.66667 13.1467 1.66667 12.5867 1.66667 11.4666V10.9999M9.66667 8.66659H6.66667M9.66667 5.99992H7.33333M9.66667 11.3333H4.33333M3 6.66659V2.99992C3 2.44763 3.44772 1.99992 4 1.99992C4.55228 1.99992 5 2.44763 5 2.99992V6.66659C5 7.77115 4.10457 8.66659 3 8.66659C1.89543 8.66659 1 7.77116 1 6.66659V3.99992"
      stroke="#1F2933"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const VIDEO_ICON = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6673 3.95417C14.6673 3.55029 14.6673 3.34835 14.5875 3.25484C14.5182 3.1737 14.4142 3.13065 14.3078 3.13902C14.1852 3.14867 14.0424 3.29146 13.7569 3.57704L11.334 5.99992L13.7569 8.4228C14.0424 8.70838 14.1852 8.85117 14.3078 8.86082C14.4142 8.86919 14.5182 8.82613 14.5875 8.745C14.6673 8.65149 14.6673 8.44955 14.6673 8.04567V3.95417Z"
      stroke="#1F2933"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33398 4.53325C1.33398 3.41315 1.33398 2.85309 1.55197 2.42527C1.74372 2.04895 2.04968 1.74299 2.426 1.55124C2.85383 1.33325 3.41388 1.33325 4.53398 1.33325H8.13398C9.25409 1.33325 9.81414 1.33325 10.242 1.55124C10.6183 1.74299 10.9243 2.04895 11.116 2.42527C11.334 2.85309 11.334 3.41315 11.334 4.53325V7.46659C11.334 8.58669 11.334 9.14674 11.116 9.57457C10.9243 9.95089 10.6183 10.2569 10.242 10.4486C9.81414 10.6666 9.25409 10.6666 8.13398 10.6666H4.53398C3.41388 10.6666 2.85383 10.6666 2.426 10.4486C2.04968 10.2569 1.74372 9.95089 1.55197 9.57457C1.33398 9.14674 1.33398 8.58669 1.33398 7.46659V4.53325Z"
      stroke="#1F2933"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MIC_ICON = () => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6673 6.66659V7.99992C10.6673 10.5772 8.57798 12.6666 6.00065 12.6666M1.33398 6.66659V7.99992C1.33398 10.5772 3.42332 12.6666 6.00065 12.6666M6.00065 12.6666V14.6666M3.33398 14.6666H8.66732M6.00065 9.99992C4.89608 9.99992 4.00065 9.10449 4.00065 7.99992V3.33325C4.00065 2.22868 4.89608 1.33325 6.00065 1.33325C7.10522 1.33325 8.00065 2.22868 8.00065 3.33325V7.99992C8.00065 9.10449 7.10522 9.99992 6.00065 9.99992Z"
      stroke="#1F2933"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CUSTOM_PRINT_ICON = ({ color = '#1F2933' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 7V5.2C18 4.0799 18 3.51984 17.782 3.09202C17.5903 2.71569 17.2843 2.40973 16.908 2.21799C16.4802 2 15.9201 2 14.8 2H9.2C8.0799 2 7.51984 2 7.09202 2.21799C6.71569 2.40973 6.40973 2.71569 6.21799 3.09202C6 3.51984 6 4.0799 6 5.2V7M6 18C5.07003 18 4.60504 18 4.22354 17.8978C3.18827 17.6204 2.37962 16.8117 2.10222 15.7765C2 15.395 2 14.93 2 14V11.8C2 10.1198 2 9.27976 2.32698 8.63803C2.6146 8.07354 3.07354 7.6146 3.63803 7.32698C4.27976 7 5.11984 7 6.8 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V14C22 14.93 22 15.395 21.8978 15.7765C21.6204 16.8117 20.8117 17.6204 19.7765 17.8978C19.395 18 18.93 18 18 18M15 10.5H18M9.2 22H14.8C15.9201 22 16.4802 22 16.908 21.782C17.2843 21.5903 17.5903 21.2843 17.782 20.908C18 20.4802 18 19.9201 18 18.8V17.2C18 16.0799 18 15.5198 17.782 15.092C17.5903 14.7157 17.2843 14.4097 16.908 14.218C16.4802 14 15.9201 14 14.8 14H9.2C8.0799 14 7.51984 14 7.09202 14.218C6.71569 14.4097 6.40973 14.7157 6.21799 15.092C6 15.5198 6 16.0799 6 17.2V18.8C6 19.9201 6 20.4802 6.21799 20.908C6.40973 21.2843 6.71569 21.5903 7.09202 21.782C7.51984 22 8.07989 22 9.2 22Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AttachmentIcon = ({ color = 'black' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 5.25581V16.5C17.5 19.5376 15.0376 22 12 22C8.96243 22 6.5 19.5376 6.5 16.5V5.66667C6.5 3.64162 8.14162 2 10.1667 2C12.1917 2 13.8333 3.64162 13.8333 5.66667V16.4457C13.8333 17.4583 13.0125 18.2791 12 18.2791C10.9875 18.2791 10.1667 17.4583 10.1667 16.4457V6.65116"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
