import * as Yup from 'yup';
import {
  BORROWER_IMAGE_EXTENSION,
  CaptureImageAllowedExtention,
  NET_WORTH_MANDATORY_LIMIT,
  UNEXPECTED_ERROR,
  UploadDocumentAllowedExtention,
  fileAllowedExtention,
  imageAllowedExtention,
  uploadFileType,
  videoAllowedExtention
} from './constant';
import moment from 'moment';
import { api } from '../config/api';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { colorPrimary } from '../config/theme';
import { useEffect } from 'react';

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const isEmail = value => {
  if (value && value.match(emailRegExp)) return true;
  else return false;
};
export const isMobile = value => {
  if (value && value.match(phoneRegExp)) return true;
  else return false;
};

export function matchIsNumeric(text) {
  const isNumber = typeof text === 'number';
  const isString = typeof text === 'string';
  return (isNumber || (isString && text !== '')) && !isNaN(Number(text));
}

export const validateChar = value => {
  return matchIsNumeric(value);
};

export const createTitle = titleName => {
  const updatedTitle = `Pehla Akshar | ${titleName}`;
  return updatedTitle;
};

export const getName = firstName => {
  return `${firstName || '<FirstName>'}`;
};

export const maskMobile = mobile => {
  const numberStr = mobile.toString();
  if (isNaN(mobile) || numberStr.length < 5) {
    return 'Invalid Number';
  }
  const firstDigits = numberStr.substring(0, 3);
  const lastDigits = numberStr.substring(numberStr.length - 2);
  const maskedNumber = `${firstDigits}XXXXX${lastDigits}`;
  return maskedNumber;
};

export const maskEmail = email => {
  const [localPart, domainPart] = email.split('@');
  const maskedLocalPart =
    localPart.length > 2
      ? localPart[0] + '*'.repeat(localPart.length - 2) + localPart.slice(-1)
      : localPart;
  const maskedEmail = `${maskedLocalPart}@${domainPart}`;
  return maskedEmail;
};

export const isAlphabet = value => /^[A-Za-z ]*$/.test(value);

export const isNumber = value => /^[0-9]*$/.test(value);

export const testPattern = (value, pattern, errorMessage) => {
  return Yup.string().matches(pattern, errorMessage).isValidSync(value);
};

export const isValidMobileNumber = value => {
  return testPattern(
    value,
    /^\d{10}$/,
    'The mobile number should consist of exactly 10 digits.'
  );
};

export const isValidEmail = value => {
  return testPattern(
    value,
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
    'Please enter a valid email address.'
  );
};

export const isValidPassword = value => {
  return testPattern(
    value,
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*[@$!%*?&].*$/,
    'Password should be atleast 8 characters and should contain uppercase, lowercase, number and special character.'
  );
};

export const isPasswordContains = value => {
  return testPattern(
    value,
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
    'Password should be atleast 8 characters and should contain uppercase, lowercase, number and special character.'
  );
};

export const isValidPinNumber = value => {
  return testPattern(
    value,
    /^[0-9]{4}$/,
    'The PIN should have exactly 4 digits.'
  );
};
export const isValidPanNumber = value => {
  return testPattern(
    value,
    /^[A-Z]{5}\d{4}[A-Z]$/,
    'The PAN Number should consist of exactly 10 digits.'
  );
};
export const isValidAadhaarNumber = value => {
  return testPattern(
    value,
    /^\d{12}$/,
    'The Aadhaar Number should be composed of exactly 12 digits.'
  );
};
export const isValidAddress = value => {
  return testPattern(value, /^[.,:\w\s-]+$/, 'Please provide a valid address.');
};
export const isValidPinCode = value => {
  return testPattern(value, /^\d{6}$/, 'Please enter a valid pincode.');
};

export const handleError = error => {
  return error ? error : UNEXPECTED_ERROR;
};

export const formatNumberWithCommas = number => {
  try {
    const value = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value ? value : 0;
  } catch (error) {
    return 0;
  }
};

export const calculateMonthlyReturn = (principal, months) => {
  principal = Number(principal);
  if (
    isNaN(principal) ||
    isNaN(Number(process.env.REACT_APP_RATE_OF_INTEREST)) ||
    isNaN(months)
  ) {
    return 'Invalid input. Please provide valid numbers.';
  }
  const annualRate = Number(process.env.REACT_APP_RATE_OF_INTEREST) / 100;
  const monthlyRate = annualRate / 12;
  const monthlyInterest = principal * monthlyRate;
  const expectedReturn = principal + monthlyInterest * months;
  if (isNaN(expectedReturn)) {
    return 0;
  }
  return Number(expectedReturn).toFixed(2);
};

export const isArrayNotEmpty = array => {
  return Array.isArray(array) && array.length > 0;
};

export const formatDate = (date, format = 'YYYY-MM-DD') => {
  const formattedDate = moment(new Date(date)).format(format);
  return formattedDate;
};

export const enterOnlyAlphabates = value => {
  return value.replace(/[^A-Za-z\s]/g, '');
};
export const enterOnlyNumbers = value => {
  return value.replace(/[^0-9]/g, '');
};
export const enterOnlyEmail = value => {
  return value.replace(/[^a-zA-Z@.^0-9]/g, '');
};
export const enterEmailOrMobile = value => {
  return value.replace(/[^0-9a-zA-Z@.+\\-]/g, '');
};

export const enterWithNoSpaces = value => {
  return value.replace(/\s/g, '');
};
export const enterMaxInvestedAmount = value => {
  value = value.trim().replace(/^0+/, '');
  const sanitizedValue = enterOnlyNumbers(value);
  const numericValue = parseFloat(sanitizedValue);

  if (numericValue < 0) {
    return 0;
  }
  return sanitizedValue;
};

export const enterOnlyAlphabetsAndNumbers = value => {
  return value.replace(/[^a-zA-Z0-9]/g, '');
};

export const convertToDatePicker = inputDateString => {
  var dateFormats = [
    'YYYY-MM-DD',
    'MM/DD/YYYY',
    'DD/MM/YYYY',
    'DD-MM-YYYY',
    'YYYY/MM/DD'
  ];

  for (var i = 0; i < dateFormats.length; i++) {
    var parsedDate = moment(inputDateString, dateFormats[i], true);
    if (parsedDate.isValid()) {
      return parsedDate.format('YYYY-MM-DD');
    }
  }
  return inputDateString;
};

export const printCurrentTimeStamp = () => {
  const currentDate = moment();
  const formattedDate = currentDate.format('DD MMMM, YYYY | h:mm A');
  return formattedDate;
};

export const isGreaterThanZero = value => {
  if (typeof value === 'number') {
    return value > 0;
  }
  return false;
};

export const isImageExtensionAllowed = filename => {
  const allowedExtensions = imageAllowedExtention;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};
export const isVideoExtensionAllowed = filename => {
  const allowedExtensions = videoAllowedExtention;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};
export const isFileExtensionAllowed = filename => {
  const allowedExtensions = fileAllowedExtention;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};
export const isBorrowerImageExtensionAllowed = filename => {
  const allowedExtensions = BORROWER_IMAGE_EXTENSION;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};
export const isUploadImageExtensionAllowed = filename => {
  const allowedExtensions = UploadDocumentAllowedExtention;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};
export const isCaptureImageExtensionAllowed = filename => {
  const allowedExtensions = CaptureImageAllowedExtention;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};

export const getImageDetails = (docType, documents) => {
  if (isArrayNotEmpty(documents)) {
    const document = documents.find(
      document => document.fileName == docType?.fileName
    );
    return document ? document : null;
  }
};

export function categorizeDocuments(data) {
  const categorizedData = {};

  data.forEach(item => {
    const { documentType } = item;

    if (!categorizedData[documentType]) {
      categorizedData[documentType] = {};
    }

    categorizedData[documentType] = {
      ...categorizedData[documentType],
      ...item
    };
  });

  return categorizedData;
}

export const getFileName = content => {
  try {
    let filename = '';
    const filenameMatch = /filename=([^;\s]+)/.exec(content);
    if (filenameMatch) {
      filename = filenameMatch[1];
    }
    filename = filename.replace(/"/g, '');
    return filename;
  } catch (error) {
    console.error(error);
  }
};

export const isValidOtp = value => {
  return testPattern(value, /^\d{4}$/, 'Please enter a valid OTP.');
};

export const highlightText = (text, query) => {
  if (!query) return text;
  const regex = new RegExp(`(${query})`, 'gi');
  return text.split(regex).map((part, index) =>
    regex.test(part) ? (
      <span key={index} style={{ backgroundColor: 'yellow' }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

export const maskAccountNumber = inputString => {
  try {
    if (inputString.length < 4) {
      return 'XXXX' + inputString.slice(-4);
    }
    const maskedCharacters = 'X'.repeat(inputString.length - 4);
    const lastFourDigits = inputString.slice(-4);
    const maskedString = `${maskedCharacters}${lastFourDigits}`;
    return maskedString;
  } catch (error) {
    console.error(error);
  }
};

export const isDisplayNetworthCertificate = (
  currentInvestment,
  previousInvestment
) => {
  try {
    currentInvestment = Number(currentInvestment);
    previousInvestment = Number(previousInvestment);

    const totalInvestment = currentInvestment + previousInvestment;

    return totalInvestment >= Number(NET_WORTH_MANDATORY_LIMIT);
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getFullName = (firstName, middleName, lastName) => {
  const nameParts = [];
  if (firstName && firstName.trim() !== '') {
    nameParts.push(firstName);
  }
  if (middleName && middleName.trim() !== '') {
    nameParts.push(middleName);
  }
  if (lastName && lastName.trim() !== '') {
    nameParts.push(lastName);
  }
  const fullName = nameParts.join(' ');
  return fullName;
};

export const generateImageSrc = (base64String, mimeType = 'image') => {
  try {
    if (!base64String || typeof base64String !== 'string') {
      return null;
    }
    return `data:${mimeType};base64,${base64String}`;
  } catch (error) {
    console.error(error);
  }
};

export const formatAmountInINR = amount => {
  return '₹ ' + new Intl.NumberFormat('en-IN').format(amount);
};

export const isEmptyString = str => {
  return str.trim().length === 0;
};

export const formatDateTimeStamp = date => {
  const currentDate = moment(date);
  // const modifiedDate = currentDate.add(5, 'hours').add(30, 'minutes');
  const formattedDate = currentDate.format('DD MMMM, YYYY | h:mm A');
  return formattedDate;
};

export const parseParams = (params = '') => {
  const rawParams = params.replace('?', '').split('&');
  const extractedParams = {};
  rawParams.forEach(item => {
    item = item.split('=');
    extractedParams[item[0]] = item[1];
  });
  return extractedParams;
};

export const getIconUrl = (icon = '') => {
  return `/images/${icon}`;
};

export const getImagesUrl = (icon = '') => {
  return `/images/dashboard-images/${icon}`;
};

export function findModuleIdByName(moduleList, moduleName) {
  for (const module of moduleList) {
    if (module.menu_name === moduleName) {
      return module.menu_id;
    }

    if (module.subMenu && module.subMenu.length > 0) {
      const foundId = findModuleIdByName(module.subMenu, moduleName);
      if (foundId !== null) {
        return foundId;
      }
    }
  }

  return null; // Module not found
}

export const downloadFileCSVPostApi = async ({ apiPath, body, fileName }) => {
  const response = await api.post(apiPath, body);
  const blob = new Blob([response.data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName || `${moment().format('YYYY-MM-DD')}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFilePDFPostApi = async ({ apiPath, body, fileName }) => {
  try {
    const response = await api.post(apiPath, body, { responseType: 'blob' });
    // console.log('Res', response);

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/pdf' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Error downloading the file', error);
  }
};

export const ageValidation = ({ year = '', month = '' }) => {
  const today = new Date();
  return new Date(
    today.getFullYear() - year,
    today.getMonth() + month,
    today.getDate()
  );
};

export const mapArrayToTypography = arr => {
  return arr.map((item, index) => (
    <>
      {item && (
        <Typography
          color="#D62828"
          key={index}
          fontWeight={'600'}
          fontSize="14px"
          lineHeight={'18px'}
        >
          {arr && arr.length > 0 && `${index + 1}.`} {item}
        </Typography>
      )}
    </>
  ));
};

export function getOptionLabel(option) {
  if (!option) {
    return '';
  }

  if (option.label !== null) {
    return option.label;
  }

  if (option.id !== null) {
    return option.id;
  }

  return '';
}

export const generateVideoThumbnail = file => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      video.currentTime = Math.min(1, video.duration / 2); // Seek to 1 second or middle of the video
    };

    video.onseeked = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnail = canvas.toDataURL('image/png');
      resolve(thumbnail);
    };

    video.onerror = error => {
      reject(error);
    };

    video.src = URL.createObjectURL(file);
  });
};

export const capitalizeFirstChar = str => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// export function getFileTypeFromBase64(base64String) {
//   // Helper function to convert base64 to Uint8Array
//   function base64ToUint8Array(base64) {
//     const binaryString = atob(base64);
//     const len = binaryString.length;
//     const bytes = new Uint8Array(len);
//     for (let i = 0; i < len; i++) {
//       bytes[i] = binaryString.charCodeAt(i);
//     }
//     return bytes;
//   }

//   // File signatures (magic numbers) for common file types
//   const signatures = {
//     'image/jpeg': [/^\xff\xd8\xff/],
//     'image/png': [/^\x89\x50\x4e\x47/],
//     'image/gif': [/^\x47\x49\x46\x38/],
//     'application/pdf': [/^\x25\x50\x44\x46/],
//     'video/mp4': [/^\x66\x74\x79\x70\x4d\x53\x4e\x56/],
//     'audio/mpeg': [/^\x49\x44\x33/],
//     'text/csv': [/^"/],
//     'text/plain': null
//   };

//   const fileExtensions = {
//     'image/jpeg': 'image',
//     'image/png': 'image',
//     'image/jpg': 'image',
//     'application/pdf': 'file',
//     'video/mp4': 'video',
//     'audio/mpeg': 'audio',
//     'text/csv': 'file',
//     'text/plain': 'file'
//   };

//   // Strip the data URL header if present
//   const base64Header = /^data:(.+);base64,/;
//   const base64HeaderMatch = base64String.match(base64Header);
//   if (base64HeaderMatch) {
//     base64String = base64String.replace(base64Header, '');
//   }

//   // Convert base64 to Uint8Array
//   const byteArray = base64ToUint8Array(base64String);

//   // Check the byte array against known file signatures
//   for (const [mimeType, patterns] of Object.entries(signatures)) {
//     for (const pattern of patterns) {
//       if (pattern.test(String.fromCharCode(...byteArray.slice(0, 4)))) {
//         return fileExtensions[mimeType];
//       }
//     }
//   }

//   // Return null if no match is found
//   return null;
// }

export function getFileTypeFromBase64(base64String) {
  // Helper function to convert base64 to Uint8Array
  function base64ToUint8Array(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  // File signatures (magic numbers) for common file types
  const signatures = {
    'image/jpeg': [0xff, 0xd8, 0xff],
    'image/png': [0x89, 0x50, 0x4e, 0x47],
    'image/gif': [0x47, 0x49, 0x46, 0x38],
    'application/pdf': [0x25, 0x50, 0x44, 0x46],
    'video/mp4': [0x66, 0x74, 0x79, 0x70],
    'audio/mpeg': [0x49, 0x44, 0x33],
    'text/csv': [0x22], // CSV often starts with a double quote
    'text/plain': [0x0a, 0x0d] // Line feed or carriage return
  };

  const fileExtensions = {
    'image/jpeg': 'image',
    'image/png': 'image',
    'image/gif': 'image',
    'application/pdf': 'file',
    'video/mp4': 'video',
    'audio/mpeg': 'audio',
    'text/csv': 'file',
    'text/plain': 'file'
  };

  // Strip the data URL header if present
  const base64Header = /^data:(.+);base64,/;
  const base64HeaderMatch = base64String.match(base64Header);
  if (base64HeaderMatch) {
    base64String = base64String.replace(base64Header, '');
  }

  // Convert base64 to Uint8Array
  const byteArray = base64ToUint8Array(base64String);

  // Check the byte array against known file signatures
  for (const [mimeType, pattern] of Object.entries(signatures)) {
    console.log(mimeType, 'mimeType');
    const match = pattern.every((byte, index) => byte === byteArray[index]);
    if (match) {
      return fileExtensions[mimeType];
    }
  }

  // Return null if no match is found
  return null;
}

export function getMimeTypeFromBase64(base64String) {
  const base64HeaderPattern = /^data:(.+);base64,/;
  const base64HeaderMatch = base64String.match(base64HeaderPattern);

  if (base64HeaderMatch) {
    return base64HeaderMatch[1];
  }

  // Decode base64 string to binary string
  const binaryString = atob(base64String);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Check for common file signatures
  if (bytes[0] === 0xff && bytes[1] === 0xd8 && bytes[2] === 0xff) {
    return 'image/jpeg';
  } else if (
    bytes[0] === 0x89 &&
    bytes[1] === 0x50 &&
    bytes[2] === 0x4e &&
    bytes[3] === 0x47
  ) {
    return 'image/png';
  } else if (bytes[0] === 0x47 && bytes[1] === 0x49 && bytes[2] === 0x46) {
    return 'image/gif';
  } else if (
    bytes[0] === 0x25 &&
    bytes[1] === 0x50 &&
    bytes[2] === 0x44 &&
    bytes[3] === 0x46
  ) {
    return 'application/pdf';
  } else if (bytes[0] === 0x49 && bytes[1] === 0x44 && bytes[2] === 0x33) {
    return 'audio/mpeg';
  } else if (
    bytes[0] === 0x66 &&
    bytes[1] === 0x74 &&
    bytes[2] === 0x79 &&
    bytes[3] === 0x70
  ) {
    return 'video/mp4';
  } else if (
    bytes[0] === 0x00 &&
    bytes[1] === 0x00 &&
    bytes[2] === 0x00 &&
    (bytes[3] === 0x14 || bytes[3] === 0x20)
  ) {
    return 'video/mp4';
  } else if (
    binaryString.startsWith('ID,') ||
    binaryString.startsWith('id,') ||
    binaryString.startsWith('ID;') ||
    binaryString.startsWith('id;') ||
    (bytes[0] >= 0x30 &&
      bytes[0] <= 0x39 &&
      (bytes[1] === 0x2c || bytes[1] === 0x3b)) // checks if the file starts with numbers followed by comma or semicolon
  ) {
    return 'text/csv';
  }

  return 'application/octet-stream'; // Default MIME type if unknown
}

export function base64ToFile(base64String, fileName) {
  // Strip the base64 header if present
  const base64HeaderPattern = /^data:(.+);base64,/;
  const base64HeaderMatch = base64String.match(base64HeaderPattern);

  let base64Data = base64String;
  let mimeType = '';

  if (base64HeaderMatch) {
    base64Data = base64String.replace(base64HeaderPattern, '');
    mimeType = base64HeaderMatch[1];
  } else {
    mimeType = getMimeTypeFromBase64(base64String);
  }

  // Decode base64 string to a binary string
  const binaryString = atob(base64Data);

  // Create a Uint8Array from the binary string
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  const blob = new Blob([bytes], { type: mimeType });

  // Create a File from the Blob
  return new File([blob], `${fileName}.${uploadFileType[mimeType]}`, {
    type: mimeType
  });
}

export const determineMimeType = base64String => {
  // Decode the Base64 content
  const decodedContent = atob(base64String);

  // Check if it's likely a CSV by checking for comma separators
  if (decodedContent.includes(',')) {
    return 'text/csv';
  }

  // Default to text/plain if not identified as CSV
  return 'text/plain';
};

export function base64ToTxtCSV(base64String, fileName) {
  const mimeType = determineMimeType(base64String);
  const decodedContent = atob(base64String);

  const arrayBuffer = new ArrayBuffer(decodedContent.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < decodedContent.length; i++) {
    uint8Array[i] = decodedContent.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type: mimeType });

  // Create a File from the Blob
  return new File([blob], `${fileName}.${uploadFileType[mimeType]}`, {
    type: mimeType
  });
}

export function convertUTCtoTimezone(
  utcDateTime,
  targetTimezone = 'Asia/Kolkata'
) {
  // Parse UTC datetime string
  const utcDate = new Date(utcDateTime);

  // Get UTC timestamp in milliseconds
  const utcTimestamp = utcDate.getTime();

  // Get UTC offset in milliseconds
  const utcOffset = utcDate.getTimezoneOffset() * 60 * 1000;

  // Calculate target timezone offset in milliseconds
  const targetOffset = new Date().getTimezoneOffset() * 60000 + utcOffset;

  // Calculate target datetime in milliseconds
  const targetTimestamp = utcTimestamp + targetOffset;

  // Create a new Date object for the target timezone
  const targetDate = new Date(targetTimestamp);

  // Format the target datetime as per your requirement
  const formattedDate = targetDate.toLocaleString('en-US', {
    timeZone: targetTimezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });

  return formattedDate;
}

export function convertBarChartData(data) {
  if (data && data.length > 0) {
    const headers = data[0];
    const rows = data.slice(1);
    const output = [
      [
        'Selected Option',
        'Option Count',
        { role: 'style' },
        { role: 'annotation' }
      ]
    ];
    rows.forEach(row => {
      const [option, count, percentage] = row;
      output.push([
        option,
        count,
        `color: ${colorPrimary};`,
        `${count} (${percentage}%)`
      ]);
    });
    return output;
  }
}

// Custom hook to detect clicks outside the referenced element
export function useClickOutside(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export const openFilePDFPostApi = async ({ apiPath, body, fileName }) => {
  try {
    const response = await api.post(apiPath, body, { responseType: 'blob' });
    // console.log('Res', response);

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/pdf' })
    );
    window.open(url, '_blank');
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', `${fileName}.pdf`);
    // document.body.appendChild(link);
    // link.click();
    // link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Error downloading the file', error);
  }
};

export function isBase64OrUrl(input) {
  const base64Pattern = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+)?;base64,/;
  const urlPattern = /^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/;

  if (base64Pattern.test(input)) {
    return 'Base64';
  } else if (urlPattern.test(input)) {
    return 'URL';
  } else {
    return 'Invalid';
  }
}

export async function urlToFile(url, filename, mimeType) {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], filename, { type: mimeType });
  return file;
}

export function base64URLToFile(base64String, fileName, fileType) {
  console.log(base64String, 'base64String');
  // Decode the base64 string to binary data
  const byteString = atob(base64String);

  // Create an array for the decoded bytes
  const byteArray = new Uint8Array(byteString.length);

  // Assign each byte to the Uint8Array
  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i);
  }

  // Create a Blob from the byte array, with the correct MIME type
  const blob = new Blob([byteArray], { type: fileType });

  // Create a File object from the Blob (optional)
  const file = new File([blob], fileName, { type: fileType });

  return file;
}

export function copyToClipboard(text) {
  const tempTextArea = document.createElement('textarea');
  tempTextArea.value = text;
  document.body.appendChild(tempTextArea);
  tempTextArea.select();
  try {
    const success = document.execCommand('copy');
    console.log(success, 'success');
    if (success) {
      console.log('Text copied to clipboard!');
    } else {
      console.error('Failed to copy text.');
    }
  } catch (err) {
    console.error('Error during copying:', err);
  }
  document.body.removeChild(tempTextArea);
}
