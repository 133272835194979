import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';
import { downloadFileCSVPostApi } from '../../utils/utils';

const intial = {
  isStateLoading: false,
  isStateSuccess: false,
  isStateFailed: false,
  stateData: { totalCount: 0, state: [] },
  stateErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isStateNoAuthLoading: false,
  isStateNoAuthSuccess: false,
  isStateNoAuthFailed: false,
  stateNoAuthData: { totalCount: 0, state: [] },
  stateNoAuthErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDistrictLoading: false,
  isDistrictSuccess: false,
  isDistrictFailed: false,
  districtData: {
    totalCount: 0,
    allCount: 0,
    activeCount: 0,
    inActiveCount: 0,
    district: []
  },
  districtErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDistrictNoAuthLoading: false,
  isDistrictNoAuthSuccess: false,
  isDistrictNoAuthFailed: false,
  districtNoAuthData: {
    totalCount: 0,
    allCount: 0,
    activeCount: 0,
    inActiveCount: 0,
    district: []
  },
  districtNoAuthErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDistrictByStateLoading: false,
  isDistrictByStateSuccess: false,
  isDistrictByStateFailed: false,
  districtByStateData: { totalCount: 0, districtByState: [] },
  districtByStateErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDownloadDistrictLoading: false,
  isDownloadDistrictSuccess: false,
  isDownloadDistrictFailed: false,
  downloadDistrictErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUploadDistrictLoading: false,
  isUploadDistrictSuccess: false,
  isUploadDistrictFailed: false,
  uploadDistrictMessage: '',
  uploadDistrictErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAreaLoading: false,
  isAreaSuccess: false,
  isAreaFailed: false,
  areaData: {
    totalCount: 0,
    allCount: 0,
    activeCount: 0,
    inActiveCount: 0,
    area: []
  },
  areaErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAreaNoAuthLoading: false,
  isAreaNoAuthSuccess: false,
  isAreaNoAuthFailed: false,
  areaNoAuthData: {
    totalCount: 0,
    allCount: 0,
    activeCount: 0,
    inActiveCount: 0,
    area: []
  },
  areaNoAuthErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isModulesLoading: false,
  isModulesSuccess: false,
  isModulesFailed: false,
  modules: [],
  modulesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDistrictDetailsLoading: false,
  isDistrictDetailsSuccess: false,
  isDistrictDetailsFailed: false,
  districtDetails: {
    stateId: null,
    stateCode: null,
    stateName: null,
    districtId: null,
    districtCode: null,
    districtName: null,
    areas: null,
    schools: null,
    users: null,
    createdBy: null,
    creationDate: null
  },
  districtOtherDetails: {
    dataListOfEmployees: [],
    dataListOfSchools: [],
    dataListOfAreas: []
  },
  districtDetailsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isActivateDeactivateLoading: false,
  isActivateDeactivateSuccess: false,
  isActivateDeactivateFailed: false,
  activateDeactivateResponse: '',
  activateDeactivateErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDeleteDistrictLoading: false,
  isDeleteDistrictSuccess: false,
  isDeleteDistrictFailed: false,
  deleteDistrictErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCreateUpdateDistrictLoading: false,
  isCreateUpdateDistrictSuccess: false,
  isCreateUpdateDistrictFailed: false,
  createUpdateDistrictResponse: '',
  createUpdateDistrictErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAreaDetailsLoading: false,
  isAreaDetailsSuccess: false,
  isAreaDetailsFailed: false,
  areaDetails: {
    pinId: null,
    area: null,
    stateId: null,
    stateCode: null,
    stateName: null,
    districtId: null,
    districtCode: null,
    districtName: null,
    areas: null,
    schools: null,
    users: null,
    createdBy: null,
    creationDate: null
  },
  areaOtherDetails: {
    dataListOfEmployees: [],
    dataListOfSchools: []
  },
  areaDetailsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isActivateDeactivateAreaLoading: false,
  isActivateDeactivateAreaSuccess: false,
  isActivateDeactivateAreaFailed: false,
  activateDeactivateAreaResponse: '',
  activateDeactivateAreaErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDeleteAreaLoading: false,
  isDeleteAreaSuccess: false,
  isDeleteAreaFailed: false,
  deleteAreaErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCreateUpdateAreaLoading: false,
  isCreateUpdateAreaSuccess: false,
  isCreateUpdateAreaFailed: false,
  createUpdateAreaResponse: '',
  createUpdateAreaErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  ////------Schools
  isSchoolsLoading: false,
  isSchoolsSuccess: false,
  isSchoolsFailed: false,
  schoolsData: {
    totalCount: 0,
    allCount: 0,
    activeCount: 0,
    inActiveCount: 0,
    schools: []
  },
  schoolErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isSchoolsNoAuthLoading: false,
  isSchoolsNoAuthSuccess: false,
  isSchoolsNoAuthFailed: false,
  schoolsNoAuthData: {
    totalCount: 0,
    allCount: 0,
    activeCount: 0,
    inActiveCount: 0,
    schools: []
  },
  schoolsNoAuthErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isActivateDeactivateSchoolLoading: false,
  isActivateDeactivateSchoolSuccess: false,
  isActivateDeactivateSchoolFailed: false,
  activateDeactivateSchoolResponse: '',
  activateDeactivateSchoolErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDeleteSchoolLoading: false,
  isDeleteSchoolSuccess: false,
  isDeleteSchoolFailed: false,
  deleteSchoolErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCreateUpdateSchoolLoading: false,
  isCreateUpdateSchoolSuccess: false,
  isCreateUpdateSchoolFailed: false,
  isCreateUpdateSchoolResponse: '',
  createUpdateSchoolErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isSchoolDetailsLoading: false,
  isSchoolDetailsSuccess: false,
  isSchoolDetailsFailed: false,
  schoolDetails: {
    schoolId: null,
    schoolName: null,
    pinId: null,
    area: null,
    pinCode: null,
    districtId: null,
    districtCode: null,
    districtName: null,
    stateId: null,
    stateCode: null,
    stateName: null,
    status: null,
    users: null,
    employees: null,
    createdBy: null,
    creationDate: null
  },
  schoolOtherDetails: {
    dataListOfEmployees: [],
    dataListOfClassrooms: [],
    dataListOfUsers: []
  },
  schoolDetailsErrorContainer: {
    error: false,
    errorMessage: '',
    description: '',
    statusCode: 0
  },
  ////-----School ends

  ////------Classroom
  isClassroomsLoading: false,
  isClassroomsSuccess: false,
  isClassroomsFailed: false,
  classroomsData: { totalCount: 0, classrooms: [] },
  classroomErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isActivateDeactivateClassroomLoading: false,
  isActivateDeactivateClassroomSuccess: false,
  isActivateDeactivateClassroomFailed: false,
  activateDeactivateClassroomResponse: '',
  activateDeactivateClassroomErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDeleteClassroomLoading: false,
  isDeleteClassroomSuccess: false,
  isDeleteClassroomFailed: false,
  deleteClassroomErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCreateUpdateClassroomLoading: false,
  isCreateUpdateClassroomSuccess: false,
  isCreateUpdateClassroomFailed: false,
  isCreateUpdateClassroomResponse: '',
  createUpdateClassroomErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isClassroomDetailsLoading: false,
  isClassroomDetailsSuccess: false,
  isClassroomDetailsFailed: false,
  classroomDetails: {
    schoolId: null,
    schoolName: null,
    pinId: null,
    area: null,
    pinCode: null,
    districtId: null,
    districtCode: null,
    districtName: null,
    stateId: null,
    stateCode: null,
    stateName: null,
    status: null,
    users: null,
    createdBy: null,
    creationDate: null
  },
  classroomOtherDetails: {
    dataListOfEmployees: [],
    dataListOfClassrooms: []
  },
  classroomDetailsErrorContainer: {
    error: false,
    errorMessage: '',
    description: '',
    statusCode: 0
  },

  isGradeListLoading: false,
  isGradeListSuccess: false,
  isGradeListFailed: false,
  gradeList: [],
  gradeListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isNoAuthGradeListLoading: false,
  isNoAuthGradeListSuccess: false,
  isNoAuthGradeListFailed: false,
  noAuthGradeList: [],
  noAuthGradeListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isNoAuthRoleListLoading: false,
  isNoAuthRoleListSuccess: false,
  isNoAuthRoleListFailed: false,
  noAuthRoleList: [],
  noAuthRoleListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isTeacherListLoading: false,
  isTeacherListSuccess: false,
  isTeacherListFailed: false,
  teacherList: [],
  teacherListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
  ////-----Classroom ends
};

export const getState = createAsyncThunk(
  'masters/getState',
  async (statePayload, { rejectWithValue }) => {
    try {
      let response;

      if (
        statePayload.searchFilter == '' ||
        statePayload.searchFilter == null
      ) {
        response = await api.post('metadata/states', statePayload);
      } else {
        response = await api.post(
          `metadata/states/search?searchCriteria=${statePayload.searchFilter}`,
          statePayload
        );
      }
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getStateNoAuth = createAsyncThunk(
  'masters/getStateNoAuth',
  async (statePayload, { rejectWithValue }) => {
    try {
      let response;

      if (
        statePayload.searchFilter == '' ||
        statePayload.searchFilter == null
      ) {
        response = await api.post('register/states', statePayload);
      } else {
        response = await api.post(
          `register/states/search?searchCriteria=${statePayload.searchFilter}`,
          statePayload
        );
      }
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getModules = createAsyncThunk(
  'masters/getModules',
  async (modulesPayload, { rejectWithValue }) => {
    try {
      const response = await api.get(`metadata/modules`);

      return response.data?.payload || [];
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

//----------------- Districts starts
export const getDistrict = createAsyncThunk(
  'masters/getDistrict',
  async (districtPayload, { rejectWithValue }) => {
    try {
      let response;

      if (districtPayload?.search == '' || districtPayload?.search == null) {
        response = await api.post(`metadata/districts`, districtPayload);
      } else {
        response = await api.post(
          `districts/search?searchCriteria=${districtPayload?.search}`,
          districtPayload
        );
      }
      return response.data?.payload || intial.districtData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getDistrictNoAuth = createAsyncThunk(
  'masters/getDistrictNoAuth',
  async (districtPayload, { rejectWithValue }) => {
    try {
      let response;

      if (districtPayload?.search == '' || districtPayload?.search == null) {
        response = await api.post(`register/districts`, districtPayload);
      } else {
        response = await api.post(
          `register/search?searchCriteria=${districtPayload?.search}`,
          districtPayload
        );
      }
      return response.data?.payload || intial.districtData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getDistrictByState = createAsyncThunk(
  'masters/getDistrictByState',
  async (districtPayload, stateId, { rejectWithValue }) => {
    try {
      let response;
      console.log('getDistrictByState');
      if (districtPayload?.search == '' || districtPayload?.search == null) {
        response = await api.post(
          `metadata/districtsByState/${stateId}`,
          districtPayload
        );
      } else {
        response = await api.post(
          `districts/search?searchCriteria=${districtPayload?.search}`,
          districtPayload
        );
      }
      // console.log('Districts By State', response.data?.payload);
      return response.data?.payload || intial.districtByStateData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getDistrictDetails = createAsyncThunk(
  'masters/getDistrictDetails',
  async (
    { districtId, payload = { page: 0, size: 0, type: 'all' } },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(`districts/${districtId}`, payload);

      return response.data?.payload || [];
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const activateDeactivateDistrict = createAsyncThunk(
  'master/activateDistrict',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`districts/activate`, payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deleteDistrict = createAsyncThunk(
  'master/deleteDistrict',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.delete(`districts`, {
        data: payload
      });
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUpdateDistrict = createAsyncThunk(
  'master/createUpdateDistrict',
  async ({ payload, districtId }, { rejectWithValue }) => {
    try {
      let response;
      console.log(`payload:${payload} , districtId:${districtId}`);
      if (districtId) {
        response = await api.put(`districts/${districtId}`, payload);
      } else {
        response = await api.post(`districts`, payload);
      }
      // console.log(response.data, 'create Upladt');
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const uploadDistrictCSV = createAsyncThunk(
  'master/uploadDistrictCSV',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(`upload/districts`, payload);
      console.log(response, 'upload districts');
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const downloadDistrictCSV = createAsyncThunk(
  'master/downloadDistrict',
  async ({ fileName, body }, { rejectWithValue }) => {
    try {
      return await downloadFileCSVPostApi({
        fileName: fileName,
        apiPath: '/download/districts',
        body: body
      });
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const revertDistrictFormDetails = createAction(
  'REVERT_DISTRICT_FORM_DATA'
);

export const revertDistrictDetails = createAction('REVERT_DISTRICT_DETAILS');

export const getDistrictCount = createAsyncThunk(
  'masters/getDistrictCount',
  async (_, { rejectWithValue }) => {
    try {
      let response;

      response = await api.post(`metadata/districts`, {
        type: 'all',
        page: 0,
        size: 0
      });

      return response.data?.payload || intial.districtData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);
//---------- Districts Ends

//--------- Area starts
export const getArea = createAsyncThunk(
  'masters/getArea',
  async (areaPayload, { rejectWithValue }) => {
    let response;
    try {
      if (areaPayload?.search === '' || areaPayload?.search === null) {
        response = await api.post(`metadata/areas`, areaPayload);
      } else {
        response = await api.post(
          `areas/search?searchCriteria=${areaPayload?.search}`,
          areaPayload
        );
      }

      return response.data?.payload || intial.areaData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getAreaNoAuth = createAsyncThunk(
  'masters/getAreaNoAuth',
  async (areaPayload, { rejectWithValue }) => {
    let response;
    try {
      if (areaPayload?.search === '' || areaPayload?.search === null) {
        response = await api.post(`register/areas`, areaPayload);
      } else {
        response = await api.post(
          `areas/search?searchCriteria=${areaPayload?.search}`,
          areaPayload
        );
      }

      return response.data?.payload || intial.areaData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getAreaDetails = createAsyncThunk(
  'masters/getAreaDetails',
  async (
    { pinId, payload = { page: 0, size: 0, type: 'all' } },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(`areas/${pinId}`, payload);
      // console.log(response, 'reapsn');
      return response.data?.payload || [];
      // return [];
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const activateDeactivateArea = createAsyncThunk(
  'master/activateDeactivateArea',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`areas/activate`, payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deleteArea = createAsyncThunk(
  'master/deleteArea',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.delete(`areas`, {
        data: payload
      });
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUpdateArea = createAsyncThunk(
  'master/createUpdateArea',
  async ({ payload, pinId }, { rejectWithValue }) => {
    try {
      let response;
      console.log(payload, pinId, 'asdad');
      if (pinId) {
        response = await api.put(`areas/${pinId}`, payload);
      } else {
        response = await api.post(`areas`, payload);
      }

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const revertAreaFormDetails = createAction('REVERT_AREA_FORM_DATA');

export const revertAreaDetails = createAction('REVERT_AREA_DETAILS');
export const getAreaCount = createAsyncThunk(
  'masters/getAreaCount',
  async (_, { rejectWithValue }) => {
    try {
      let response = await api.post(`metadata/areas`, {
        type: 'all',
        page: 0,
        size: 0
      });

      return response.data?.payload || intial.areaData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);
//------------- Area Ends

//------------- School start
export const revertSchoolPageData = createAction('REVERT_SCHOOL_PAGE_DATA');
export const revertSchoolFormData = createAction('REVERT_SCHOOL_FORM_DATA');

export const getSchools = createAsyncThunk(
  'masters/getSchool',
  async (schoolsPayload, { rejectWithValue }) => {
    try {
      let response;
      // const response = await api.post(`metadata/schools`, schoolsPayload);

      if (
        schoolsPayload.searchFilter == '' ||
        schoolsPayload.searchFilter == null
      ) {
        response = await api.post('metadata/schools', schoolsPayload);
      } else {
        response = await api.post(
          `schools/search?searchCriteria=${schoolsPayload.searchFilter}`,
          schoolsPayload
        );
      }
      return response.data?.payload || intial.schoolsData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getSchoolsNoAuth = createAsyncThunk(
  'masters/getSchoolsNoAuth',
  async (schoolsPayload, { rejectWithValue }) => {
    try {
      let response;
      // const response = await api.post(`metadata/schools`, schoolsPayload);

      if (
        schoolsPayload.searchFilter == '' ||
        schoolsPayload.searchFilter == null
      ) {
        response = await api.post('register/schools', schoolsPayload);
      } else {
        response = await api.post(
          `schools/search?searchCriteria=${schoolsPayload.searchFilter}`,
          schoolsPayload
        );
      }
      return response.data?.payload || intial.schoolsData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const activateDeactivateSchool = createAsyncThunk(
  'master/activateDeactivateSchool',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`schools/activate`, payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deleteSchool = createAsyncThunk(
  'master/deleteSchool',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.delete(`schools`, {
        data: payload
      });
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUpdateSchool = createAsyncThunk(
  'master/createUpdateSchool',
  async ({ payload, schoolId }, { rejectWithValue }) => {
    try {
      let response;
      if (schoolId) {
        response = await api.put(`schools/${schoolId}`, payload);
      } else {
        response = await api.post(`schools`, payload);
      }
      // console.log(response.data, 'create Upladt');
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getSchoolDetails = createAsyncThunk(
  'masters/getSchoolDetails',
  async (
    { schoolId, payload = { size: 1, page: 0, type: 'all' } },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(`schools/${schoolId}`, payload);

      return response.data?.payload || [];
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getSchoolsCount = createAsyncThunk(
  'masters/getSchoolsCount',
  async (_, { rejectWithValue }) => {
    try {
      let response = await api.post('metadata/schools', {
        type: 'all',
        page: 0,
        size: 0
      });
      return response.data?.payload || intial.schoolsData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);
//------------- School Ends

//------------- Classroom start
export const revertClassroomPageData = createAction(
  'REVERT_CLASSROOM_PAGE_DATA'
);
export const revertClassroomFormData = createAction(
  'REVERT_CLASSROOM_FORM_DATA'
);

export const getClassrooms = createAsyncThunk(
  'masters/getClassrooms',
  async (classroomsPayload, { rejectWithValue }) => {
    try {
      const response = await api.post(`classrooms/all`, classroomsPayload);

      return response.data?.payload || intial.classroomsData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const activateDeactivateClassroom = createAsyncThunk(
  'master/activateDeactivateClassroom',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`classroom/activate`, payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deleteClassroom = createAsyncThunk(
  'master/deleteClassroom',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.delete(`classrooms`, {
        data: payload
      });
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUpdateClassroom = createAsyncThunk(
  'master/createUpdateClassroom',
  async ({ payload, classroomId }, { rejectWithValue }) => {
    try {
      let response;
      if (classroomId) {
        response = await api.put(`classrooms/${classroomId}`, payload);
      } else {
        response = await api.post(`classrooms`, payload);
      }
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getClassroomDetails = createAsyncThunk(
  'masters/getClassroomDetails',
  async (classroomId, { rejectWithValue }) => {
    try {
      const response = await api.get(`classrooms/${classroomId}`);

      return response.data.payload || [];
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getGradeList = createAsyncThunk(
  'roles/getGradeList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('metadata/grades');

      return response.data?.payload || intial.pageData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getNoAuthGradeList = createAsyncThunk(
  'roles/getNoAuthGradeList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('register/grades');

      return response.data?.payload || intial.pageData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getNoAuthRoleList = createAsyncThunk(
  'roles/getNoAuthRoleList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('register/roles');

      return response.data?.payload || intial.pageData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getTeacherList = createAsyncThunk(
  'roles/getTeacherList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('metadata/teachers');
      return response.data?.payload || intial.pageData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);
//------------- Classroom Ends

export const masterSlice = createSlice({
  name: 'masters',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(getState.pending, state => {
        state.isStateLoading = true;
        state.isStateSuccess = false;
        state.isStateFailed = false;
        state.stateErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getState.fulfilled, (state, action) => {
        state.isStateLoading = false;
        state.isStateSuccess = true;
        state.stateData = {
          state: action.payload.dataList,
          totalCount: action.payload.totalCount
        };
      })
      .addCase(getState.rejected, (state, action) => {
        state.isStateLoading = false;
        state.isStateFailed = true;
        state.stateData = intial.stateData;
        state.stateErrorContainer = {
          ...state.stateErrorContainer,
          ...action.payload
        };
      })

      .addCase(getStateNoAuth.pending, state => {
        state.isStateNoAuthLoading = true;
        state.isStateNoAuthSuccess = false;
        state.isStateNoAuthFailed = false;
        state.stateNoAuthErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getStateNoAuth.fulfilled, (state, action) => {
        state.isStateNoAuthLoading = false;
        state.isStateNoAuthSuccess = true;
        state.stateNoAuthData = {
          state: action.payload.dataList,
          totalCount: action.payload.totalCount
        };
      })
      .addCase(getStateNoAuth.rejected, (state, action) => {
        state.isStateNoAuthLoading = false;
        state.isStateNoAuthFailed = true;
        state.stateNoAuthData = intial.stateData;
        state.stateNoAuthErrorContainer = {
          ...state.stateNoAuthErrorContainer,
          ...action.payload
        };
      })

      .addCase(getModules.pending, state => {
        state.isModulesLoading = true;
        state.isModulesSuccess = false;
        state.isModulesFailed = false;
        state.modulesErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getModules.fulfilled, (state, action) => {
        state.isModulesLoading = false;
        state.isModulesSuccess = true;
        state.modules = action.payload;
      })
      .addCase(getModules.rejected, (state, action) => {
        state.isModulesLoading = false;
        state.isModulesFailed = true;
        state.modules = [];
        state.modulesErrorContainer = {
          ...state.modulesErrorContainer,
          ...action.payload
        };
      })

      ////----Districts start
      .addCase(revertDistrictDetails, state => {
        state.isActivateDeactivateLoading = false;
        state.isActivateDeactivateSuccess = false;
        state.isActivateDeactivateFailed = false;
        state.activateDeactivateResponse = '';
        state.activateDeactivateResponse = {
          ...intial.activateDeactivateErrorContainer
        };

        state.isDeleteDistrictLoading = false;
        state.isDeleteDistrictSuccess = false;
        state.isDeleteDistrictFailed = false;
        state.deleteDistrictErrorContainer = {
          ...intial.deleteDistrictErrorContainer
        };

        state.isDownloadDistrictLoading = false;
        state.isDownloadDistrictSuccess = false;
        state.isDownloadDistrictFailed = false;
        state.downloadDistrictErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isUploadDistrictLoading = false;
        state.isUploadDistrictSuccess = false;
        state.isUploadDistrictFailed = false;
        state.uploadDistrictMessage = '';
        state.uploadDistrictErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })

      .addCase(revertDistrictFormDetails, state => {
        state.isDistrictDetailsLoading = false;
        state.isDistrictDetailsSuccess = false;
        state.isDistrictDetailsFailed = false;
        state.districtDetails = intial.districtDetails;
        state.districtOtherDetails = intial.districtOtherDetails;
        state.districtDetailsErrorContainer = {
          ...intial.districtDetailsErrorContainer
        };

        state.isCreateUpdateDistrictLoading = false;
        state.isCreateUpdateDistrictSuccess = false;
        state.isCreateUpdateDistrictFailed = false;
        state.createUpdateDistrictResponse =
          intial.createUpdateDistrictResponse;
        state.createUpdateDistrictErrorContainer = {
          ...intial.createUpdateDistrictErrorContainer
        };
      })

      .addCase(getDistrict.pending, state => {
        state.isDistrictLoading = true;
        state.isDistrictSuccess = false;
        state.isDistrictFailed = false;
        state.districtErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getDistrict.fulfilled, (state, action) => {
        state.isDistrictLoading = false;
        state.isDistrictSuccess = true;
        state.districtData = {
          ...state.districtData,
          district: action.payload?.dataList,
          totalCount: action.payload?.totalCount
        };
      })
      .addCase(getDistrict.rejected, (state, action) => {
        state.isDistrictLoading = false;
        state.isDistrictFailed = true;
        state.district = intial.districtData;
        state.districtErrorContainer = {
          ...state.districtErrorContainer,
          ...action.payload
        };
      })

      .addCase(getDistrictNoAuth.pending, state => {
        state.isDistrictNoAuthLoading = true;
        state.isDistrictNoAuthSuccess = false;
        state.isDistrictNoAuthFailed = false;
        state.districtNoAuthErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getDistrictNoAuth.fulfilled, (state, action) => {
        state.isDistrictNoAuthLoading = false;
        state.isDistrictNoAuthSuccess = true;
        state.districtNoAuthData = {
          ...state.districtNoAuthData,
          district: action.payload?.dataList,
          totalCount: action.payload?.totalCount
        };
      })
      .addCase(getDistrictNoAuth.rejected, (state, action) => {
        state.isDistrictNoAuthLoading = false;
        state.isDistrictNoAuthFailed = true;
        state.districtNoAuthData = intial.districtNoAuthData;
        state.districtNoAuthErrorContainer = {
          ...state.districtNoAuthErrorContainer,
          ...action.payload
        };
      })

      .addCase(getDistrictByState.pending, state => {
        state.isDistrictByStateLoading = true;
        state.isDistrictByStateSuccess = false;
        state.isDistrictByStateFailed = false;
        state.districtByStateErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getDistrictByState.fulfilled, (state, action) => {
        state.isDistrictByStateLoading = false;
        state.isDistrictByStateSuccess = true;
        state.districtByStateData = {
          totalCount: action.payload.totalCount,
          districtByState: action.payload.dataList
        };
      })

      .addCase(getDistrictByState.rejected, (state, action) => {
        state.isDistrictByStateLoading = false;
        state.isDistrictByStateFailed = true;
        state.districtByState = intial.districtByStateData;
        state.districtByStateErrorContainer = {
          ...state.districtByStateErrorContainer,
          ...action.payload
        };
      })

      .addCase(getDistrictDetails.pending, state => {
        state.isDistrictDetailsLoading = true;
        state.isDistrictDetailsSuccess = false;
        state.isDistrictDetailsFailed = false;
        state.districtDetails = intial.districtDetails;
        state.districtDetailsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getDistrictDetails.fulfilled, (state, action) => {
        state.isDistrictDetailsLoading = false;
        state.isDistrictDetailsSuccess = true;
        state.districtDetails = {
          stateId: action.payload.typeResponse.dataList[0]['stateId'] || null,
          stateCode:
            action.payload.typeResponse.dataList[0]['stateCode'] || null,
          stateName:
            action.payload.typeResponse.dataList[0]['stateName'] || null,
          districtId:
            action.payload.typeResponse.dataList[0]['districtId'] || null,
          districtCode:
            action.payload.typeResponse.dataList[0]['districtCode'] || null,
          districtName:
            action.payload.typeResponse.dataList[0]['districtName'] || null,
          areas: action.payload.typeResponse.dataList[0]['areas'] || null,
          users: action.payload.typeResponse.dataList[0]['users'] || null,
          schools: action.payload.typeResponse.dataList[0]['schools'] || null,
          createdBy:
            action.payload.typeResponse.dataList[0]['createdBy'] || null,
          creationDate:
            action.payload.typeResponse.dataList[0]['creationDate'] || null,
          employee: action.payload?.dataListOfEmployees?.totalCount || null
        };
        state.districtOtherDetails = {
          dataListOfEmployees: action.payload.dataListOfEmployees
            ? action.payload.dataListOfEmployees.dataList
            : [],
          dataListOfSchools: action.payload.dataListOfSchools
            ? action.payload.dataListOfSchools.dataList
            : [],
          dataListOfAreas: action.payload.dataListOfAreas
            ? action.payload.dataListOfAreas.dataList
            : [],
          dataListOfUsers: action.payload.dataListOfUsers
            ? action.payload.dataListOfUsers.dataList
            : []
        };
      })
      .addCase(getDistrictDetails.rejected, (state, action) => {
        state.isDistrictDetailsLoading = false;
        state.isDistrictDetailsFailed = true;
        state.districtDetails = intial.districtDetails;
        state.districtDetailsErrorContainer = {
          ...state.districtDetailsErrorContainer,
          ...action.payload
        };
      })

      .addCase(activateDeactivateDistrict.pending, state => {
        state.isActivateDeactivateLoading = true;
        state.isActivateDeactivateSuccess = false;
        state.isActivateDeactivateFailed = false;
        state.activateDeactivateResponse = '';
        state.activateDeactivateErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(activateDeactivateDistrict.fulfilled, (state, action) => {
        state.isActivateDeactivateLoading = false;
        state.isActivateDeactivateSuccess = true;
        state.isActivateDeactivateFailed = false;
        state.activateDeactivateResponse = action.payload?.message;
      })
      .addCase(activateDeactivateDistrict.rejected, (state, action) => {
        state.isActivateDeactivateLoading = false;
        state.isActivateDeactivateSuccess = false;
        state.isActivateDeactivateFailed = true;
        state.activateDeactivateErrorContainer = {
          ...state.activateDeactivateErrorContainer,
          ...action.payload
        };
      })

      .addCase(deleteDistrict.pending, state => {
        state.isDeleteDistrictLoading = true;
        state.isDeleteDistrictSuccess = false;
        state.isDeleteDistrictFailed = false;
        state.deleteDistrictErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deleteDistrict.fulfilled, state => {
        state.isDeleteDistrictLoading = false;
        state.isDeleteDistrictSuccess = true;
        state.isDeleteDistrictFailed = false;
      })
      .addCase(deleteDistrict.rejected, (state, action) => {
        state.isDeleteDistrictLoading = false;
        state.isDeleteDistrictSuccess = false;
        state.isDeleteDistrictFailed = true;
        state.deleteDistrictErrorContainer = {
          ...state.deleteDistrictErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdateDistrict.pending, state => {
        state.isCreateUpdateDistrictLoading = true;
        state.isCreateUpdateDistrictSuccess = false;
        state.isCreateUpdateDistrictFailed = false;
        state.createUpdateDistrictErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUpdateDistrict.fulfilled, (state, action) => {
        // console.log('Called in action', action.payload);
        state.isCreateUpdateDistrictLoading = false;
        state.isCreateUpdateDistrictSuccess = true;
        state.isCreateUpdateDistrictFailed = false;
        state.createUpdateDistrictResponse = action.payload?.message;
      })
      .addCase(createUpdateDistrict.rejected, (state, action) => {
        state.isCreateUpdateDistrictLoading = false;
        state.isCreateUpdateDistrictSuccess = false;
        state.isCreateUpdateDistrictFailed = true;
        state.createUpdateDistrictErrorContainer = {
          ...state.createUpdateDistrictErrorContainer,
          ...action.payload
        };
      })

      .addCase(downloadDistrictCSV.pending, state => {
        state.isDownloadDistrictLoading = true;
        state.isDownloadDistrictSuccess = false;
        state.isDownloadDistrictFailed = false;
        state.downloadDistrictErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(downloadDistrictCSV.fulfilled, state => {
        state.isDownloadDistrictLoading = false;
        state.isDownloadDistrictFailed = false;
        state.isDownloadDistrictSuccess = true;
      })
      .addCase(downloadDistrictCSV.rejected, (state, action) => {
        state.isDownloadDistrictLoading = false;
        state.isDownloadDistrictSuccess = false;
        state.isDownloadDistrictFailed = true;
        state.downloadDistrictErrorContainer = {
          ...state.downloadDistrictErrorContainer,
          ...action.payload
        };
      })

      .addCase(uploadDistrictCSV.pending, state => {
        state.isUploadDistrictLoading = true;
        state.isUploadDistrictSuccess = false;
        state.isUploadDistrictFailed = false;
        state.uploadDistrictMessage = null;
        state.uploadDistrictErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(uploadDistrictCSV.fulfilled, (state, action) => {
        state.isUploadDistrictLoading = false;
        state.isUploadDistrictFailed = false;
        state.uploadDistrictMessage = action.payload?.message;
        state.isUploadDistrictSuccess = true;
      })
      .addCase(uploadDistrictCSV.rejected, (state, action) => {
        state.isUploadDistrictLoading = false;
        state.isUploadDistrictSuccess = false;
        state.isUploadDistrictFailed = true;
        state.uploadDistrictErrorContainer = {
          ...state.uploadDistrictErrorContainer,
          ...action.payload
        };
      })
      ////----District Ends

      ////----Area start
      .addCase(revertAreaDetails, state => {
        state.isActivateDeactivateAreaLoading = false;
        state.isActivateDeactivateAreaSuccess = false;
        state.isActivateDeactivateAreaFailed = false;
        state.activateDeactivateAreaResponse = '';
        state.activateDeactivateAreaResponse = {
          ...intial.activateDeactivateAreaErrorContainer
        };

        state.isDeleteAreaLoading = false;
        state.isDeleteAreaSuccess = false;
        state.isDeleteAreaFailed = false;
        state.deleteAreaErrorContainer = {
          ...intial.deleteAreaErrorContainer
        };
      })

      .addCase(revertAreaFormDetails, state => {
        state.isAreaDetailsLoading = false;
        state.isAreaDetailsSuccess = false;
        state.isAreaDetailsFailed = false;
        state.areaDetails = intial.areaDetails;
        state.areaOtherDetails = intial.areaOtherDetails;
        state.areaDetailsErrorContainer = {
          ...intial.areaDetailsErrorContainer
        };

        state.isCreateUpdateAreaLoading = false;
        state.isCreateUpdateAreaSuccess = false;
        state.isCreateUpdateAreaFailed = false;
        state.createUpdateAreaResponse = intial.createUpdateAreaResponse;
        state.createUpdateAreaErrorContainer = {
          ...intial.createUpdateAreaErrorContainer
        };
      })

      .addCase(getArea.pending, state => {
        state.isAreaLoading = true;
        state.isAreaSuccess = false;
        state.isAreaFailed = false;
        state.areaErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getArea.fulfilled, (state, action) => {
        state.isAreaLoading = false;
        state.isAreaSuccess = true;
        state.areaData = {
          ...state.areaData,
          area: action.payload.dataList,
          totalCount: action.payload.totalCount
        };
      })
      .addCase(getArea.rejected, (state, action) => {
        state.isAreaLoading = false;
        state.isAreaFailed = true;
        state.areaData = intial.areaData;
        state.areaErrorContainer = {
          ...state.areaErrorContainer,
          ...action.payload
        };
      })

      .addCase(getAreaNoAuth.pending, state => {
        state.isAreaNoAuthLoading = true;
        state.isAreaNoAuthSuccess = false;
        state.isAreaNoAuthFailed = false;
        state.areaNoAuthErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getAreaNoAuth.fulfilled, (state, action) => {
        state.isAreaNoAuthLoading = false;
        state.isAreaNoAuthSuccess = true;
        state.areaNoAuthData = {
          ...state.areaNoAuthData,
          area: action.payload.dataList,
          totalCount: action.payload.totalCount
        };
      })
      .addCase(getAreaNoAuth.rejected, (state, action) => {
        state.isAreaNoAuthLoading = false;
        state.isAreaNoAuthFailed = true;
        state.areaNoAuthData = intial.areaNoAuthData;
        state.areaNoAuthErrorContainer = {
          ...state.areaNoAuthErrorContainer,
          ...action.payload
        };
      })

      .addCase(getAreaDetails.pending, state => {
        state.isAreaDetailsLoading = true;
        state.isAreaDetailsSuccess = false;
        state.isAreaDetailsFailed = false;
        state.areaDetails = intial.areaDetails;
        state.areaDetailsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getAreaDetails.fulfilled, (state, action) => {
        state.isAreaDetailsLoading = false;
        state.isAreaDetailsSuccess = true;
        state.areaDetails = {
          pinId: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['pinId'] || null
            : null,
          area: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['area'] || null
            : null,
          pinCode: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['pinCode'] || null
            : null,
          districtId: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['districtId'] || null
            : null,
          districtCode: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['districtCode'] || null
            : null,
          districtName: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['districtName'] || null
            : null,
          stateId: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['stateId'] || null
            : null,
          stateCode: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['stateCode'] || null
            : null,
          stateName: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['stateName'] || null
            : null,
          schools: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['schools'] || null
            : null,
          users: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['users'] || null
            : null,
          createdBy: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['createdBy'] || null
            : null,
          creationDate: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['creationDate'] || null
            : null
        };
        state.areaOtherDetails = {
          dataListOfEmployees: action.payload.dataListOfEmployees
            ? action.payload.dataListOfEmployees.dataList
            : [],
          dataListOfSchools: action.payload.dataListOfSchools
            ? action.payload.dataListOfSchools.dataList
            : []
        };
      })
      .addCase(getAreaDetails.rejected, (state, action) => {
        state.isAreaDetailsLoading = false;
        state.isAreaDetailsFailed = true;
        state.areaDetails = intial.areaDetails;
        state.areaDetailsErrorContainer = {
          ...state.areaDetailsErrorContainer,
          ...action.payload
        };
      })

      .addCase(activateDeactivateArea.pending, state => {
        state.isActivateDeactivateAreaLoading = true;
        state.isActivateDeactivateAreaSuccess = false;
        state.isActivateDeactivateAreaFailed = false;
        state.activateDeactivateAreaResponse = '';
        state.activateDeactivateAreaErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(activateDeactivateArea.fulfilled, (state, action) => {
        state.isActivateDeactivateAreaLoading = false;
        state.isActivateDeactivateAreaSuccess = true;
        state.isActivateDeactivateAreaFailed = false;
        state.activateDeactivateAreaResponse = action.payload?.message;
      })
      .addCase(activateDeactivateArea.rejected, (state, action) => {
        state.isActivateDeactivateAreaLoading = false;
        state.isActivateDeactivateAreaSuccess = false;
        state.isActivateDeactivateAreaFailed = true;
        state.activateDeactivateAreaErrorContainer = {
          ...state.activateDeactivateAreaErrorContainer,
          ...action.payload
        };
      })

      .addCase(deleteArea.pending, state => {
        state.isDeleteAreaLoading = true;
        state.isDeleteAreaSuccess = false;
        state.isDeleteAreaFailed = false;
        state.deleteAreaErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deleteArea.fulfilled, state => {
        state.isDeleteAreaLoading = false;
        state.isDeleteAreaSuccess = true;
        state.isDeleteAreaFailed = false;
      })
      .addCase(deleteArea.rejected, (state, action) => {
        state.isDeleteAreaLoading = false;
        state.isDeleteAreaSuccess = false;
        state.isDeleteAreaFailed = true;
        state.deleteAreaErrorContainer = {
          ...state.deleteAreaErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdateArea.pending, state => {
        state.isCreateUpdateAreaLoading = true;
        state.isCreateUpdateAreaSuccess = false;
        state.isCreateUpdateAreaFailed = false;
        state.createUpdateAreaErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUpdateArea.fulfilled, (state, action) => {
        // console.log('Called in action', action.payload);
        state.isCreateUpdateAreaLoading = false;
        state.isCreateUpdateAreaSuccess = true;
        state.isCreateUpdateAreaFailed = false;
        state.createUpdateAreaResponse = action.payload?.message;
      })
      .addCase(createUpdateArea.rejected, (state, action) => {
        state.isCreateUpdateAreaLoading = false;
        state.isCreateUpdateAreaSuccess = false;
        state.isCreateUpdateAreaFailed = true;
        state.createUpdateAreaErrorContainer = {
          ...state.createUpdateAreaErrorContainer,
          ...action.payload
          // errorMessage: action.payload?.errorMessage
        };
      })
      ////---- Area Ends

      ////------ Schools start
      .addCase(revertSchoolPageData, state => {
        state.isActivateDeactivateSchoolLoading = false;
        state.isActivateDeactivateSchoolSuccess = false;
        state.isActivateDeactivateSchoolFailed = false;
        state.activateDeactivateSchoolResponse = '';
        state.activateDeactivateSchoolErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isDeleteSchoolLoading = false;
        state.isDeleteSchoolSuccess = false;
        state.isDeleteSchoolFailed = false;
        state.deleteSchoolErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(revertSchoolFormData, state => {
        state.isSchoolDetailsLoading = false;
        state.isSchoolDetailsSuccess = false;
        state.isSchoolDetailsFailed = false;
        state.schoolDetails = intial.schoolDetails;
        state.schoolOtherDetails = intial.schoolOtherDetails;
        state.schoolDetailsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };

        state.isCreateUpdateSchoolLoading = false;
        state.isCreateUpdateSchoolSuccess = false;
        state.isCreateUpdateSchoolFailed = false;
        state.isCreateUpdateSchoolResponse = '';
        state.createUpdateSchoolErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })

      .addCase(getSchools.pending, state => {
        state.isSchoolsLoading = true;
        state.isSchoolsSuccess = false;
        state.isSchoolsFailed = false;
        state.schoolErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getSchools.fulfilled, (state, action) => {
        state.isSchoolsLoading = false;
        state.isSchoolsSuccess = true;
        state.schoolsData = {
          ...state.schoolsData,
          schools: action.payload.dataList,
          totalCount: action.payload.totalCount
        };
      })
      .addCase(getSchools.rejected, (state, action) => {
        state.isSchoolsLoading = false;
        state.isSchoolsFailed = true;
        state.schoolsData = intial.schoolsData;
        state.schoolErrorContainer = {
          ...state.schoolErrorContainer,
          ...action.payload
        };
      })

      .addCase(getSchoolsNoAuth.pending, state => {
        state.isSchoolsNoAuthLoading = true;
        state.isSchoolsNoAuthSuccess = false;
        state.isSchoolsNoAuthFailed = false;
        state.schoolsNoAuthErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getSchoolsNoAuth.fulfilled, (state, action) => {
        state.isSchoolsNoAuthLoading = false;
        state.isSchoolsNoAuthSuccess = true;
        state.schoolsNoAuthData = {
          ...state.schoolsNoAuthData,
          schools: action.payload.dataList,
          totalCount: action.payload.totalCount
        };
      })
      .addCase(getSchoolsNoAuth.rejected, (state, action) => {
        state.isSchoolsNoAuthLoading = false;
        state.isSchoolsNoAuthFailed = true;
        state.schoolsNoAuthData = intial.schoolsNoAuthData;
        state.schoolsNoAuthErrorContainer = {
          ...state.schoolsNoAuthErrorContainer,
          ...action.payload
        };
      })

      .addCase(activateDeactivateSchool.pending, state => {
        state.isActivateDeactivateSchoolLoading = true;
        state.isActivateDeactivateSchoolSuccess = false;
        state.isActivateDeactivateSchoolFailed = false;
        state.activateDeactivateSchoolErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(activateDeactivateSchool.fulfilled, (state, action) => {
        state.isActivateDeactivateSchoolLoading = false;
        state.isActivateDeactivateSchoolSuccess = true;
        state.activateDeactivateSchoolResponse = action.payload?.message;
        state.isActivateDeactivateSchoolFailed = false;
      })
      .addCase(activateDeactivateSchool.rejected, (state, action) => {
        state.isActivateDeactivateSchoolLoading = false;
        state.isActivateDeactivateSchoolSuccess = false;
        state.isActivateDeactivateSchoolFailed = true;
        state.activateDeactivateSchoolErrorContainer = {
          ...state.activateDeactivateSchoolErrorContainer,
          ...action.payload
        };
      })

      .addCase(deleteSchool.pending, state => {
        state.isDeleteSchoolLoading = true;
        state.isDeleteSchoolSuccess = false;
        state.isDeleteSchoolFailed = false;
        state.deleteSchoolErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deleteSchool.fulfilled, state => {
        state.isDeleteSchoolLoading = false;
        state.isDeleteSchoolSuccess = true;
        state.isDeleteSchoolFailed = false;
      })
      .addCase(deleteSchool.rejected, (state, action) => {
        state.isDeleteSchoolLoading = false;
        state.isDeleteSchoolSuccess = false;
        state.isDeleteSchoolFailed = true;
        state.deleteSchoolErrorContainer = {
          ...state.deleteSchoolErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdateSchool.pending, state => {
        state.isCreateUpdateSchoolLoading = true;
        state.isCreateUpdateSchoolSuccess = false;
        state.isCreateUpdateSchoolFailed = false;
        state.createUpdateSchoolErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUpdateSchool.fulfilled, (state, action) => {
        state.isCreateUpdateSchoolLoading = false;
        state.isCreateUpdateSchoolSuccess = true;
        state.isCreateUpdateSchoolFailed = false;
        state.isCreateUpdateSchoolResponse = action.payload?.message;
      })
      .addCase(createUpdateSchool.rejected, (state, action) => {
        state.isCreateUpdateSchoolLoading = false;
        state.isCreateUpdateSchoolSuccess = false;
        state.isCreateUpdateSchoolFailed = true;
        state.createUpdateSchoolErrorContainer = {
          ...state.createUpdateSchoolErrorContainer,
          ...action.payload
        };
      })

      .addCase(getSchoolDetails.pending, state => {
        state.isSchoolDetailsLoading = true;
        state.isSchoolDetailsSuccess = false;
        state.isSchoolDetailsFailed = false;
        state.schoolDetails = intial.schoolDetails;
        state.schoolDetailsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getSchoolDetails.fulfilled, (state, action) => {
        state.isSchoolDetailsLoading = false;
        state.isSchoolDetailsSuccess = true;
        state.schoolDetails = {
          schoolId: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['schoolId'] || null
            : null,
          schoolName: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['schoolName'] || null
            : null,
          pinId: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['pinId'] || null
            : null,
          area: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['area'] || null
            : null,
          pinCode: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['pinCode'] || null
            : null,
          districtId: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['districtId'] || null
            : null,
          districtCode: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['districtCode'] || null
            : null,
          districtName: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['districtName'] || null
            : null,
          stateId: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['stateId'] || null
            : null,
          stateCode: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['stateCode'] || null
            : null,
          stateName: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['stateName'] || null
            : null,
          status: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['status'] || null
            : null,
          users: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['users'] || null
            : null,
          classrooms: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['classrooms'] || null
            : null,
          createdBy: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['createdBy'] || null
            : null,
          creationDate: action.payload.typeResponse
            ? action.payload.typeResponse.dataList[0]['creationDate'] || null
            : null,
          employees: action.payload.dataListOfEmployees
            ? action.payload.dataListOfEmployees?.totalCount || null
            : null
        };
        state.schoolOtherDetails = {
          dataListOfEmployees: action.payload.dataListOfEmployees
            ? action.payload.dataListOfEmployees.dataList
            : [],
          dataListOfClassrooms: action.payload.dataListOfClassrooms
            ? action.payload.dataListOfClassrooms.dataList
            : [],
          dataListOfUsers: action.payload.dataListOfUsers
            ? action.payload.dataListOfUsers.dataList
            : []
        };
      })
      .addCase(getSchoolDetails.rejected, (state, action) => {
        state.isSchoolDetailsLoading = false;
        state.isSchoolDetailsFailed = true;
        state.schoolDetails = intial.schoolDetails;
        state.schoolDetailsErrorContainer = {
          ...state.schoolDetailsErrorContainer,
          ...action.payload
        };
      })
      ////----School Ends

      ////------ Classroom start
      .addCase(revertClassroomPageData, state => {
        state.isActivateDeactivateClassroomLoading = false;
        state.isActivateDeactivateClassroomSuccess = false;
        state.isActivateDeactivateClassroomFailed = false;
        state.activateDeactivateClassroomResponse = '';
        state.activateDeactivateClassroomErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isDeleteClassroomLoading = false;
        state.isDeleteClassroomSuccess = false;
        state.isDeleteClassroomFailed = false;
        state.deleteClassroomErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(revertClassroomFormData, state => {
        state.isClassroomDetailsLoading = false;
        state.isClassroomDetailsSuccess = false;
        state.isClassroomDetailsFailed = false;
        state.classroomDetails = intial.classroomDetails;
        state.classroomOtherDetails = intial.classroomOtherDetails;
        state.classroomDetailsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };

        state.isCreateUpdateClassroomLoading = false;
        state.isCreateUpdateClassroomSuccess = false;
        state.isCreateUpdateClassroomFailed = false;
        state.isCreateUpdateClassroomResponse = '';
        state.createUpdateClassroomErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })

      .addCase(getClassrooms.pending, state => {
        state.isClassroomsLoading = true;
        state.isClassroomsSuccess = false;
        state.isClassroomsFailed = false;
        state.classroomErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getClassrooms.fulfilled, (state, action) => {
        state.isClassroomsLoading = false;
        state.isClassroomsSuccess = true;
        state.classroomsData = {
          totalCount: action.payload.totalCount,
          classrooms: action.payload.dataList
        };
      })
      .addCase(getClassrooms.rejected, (state, action) => {
        state.isClassroomsLoading = false;
        state.isClassroomsFailed = true;
        state.classroomsData = intial.classroomsData;
        state.classroomErrorContainer = {
          ...state.classroomErrorContainer,
          ...action.payload
        };
      })

      .addCase(activateDeactivateClassroom.pending, state => {
        state.isActivateDeactivateClassroomLoading = true;
        state.isActivateDeactivateClassroomSuccess = false;
        state.isActivateDeactivateClassroomFailed = false;
        state.activateDeactivateClassroomErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(activateDeactivateClassroom.fulfilled, (state, action) => {
        state.isActivateDeactivateClassroomLoading = false;
        state.isActivateDeactivateClassroomSuccess = true;
        state.activateDeactivateClassroomResponse = action.payload?.message;
        state.isActivateDeactivateClassroomFailed = false;
      })
      .addCase(activateDeactivateClassroom.rejected, (state, action) => {
        state.isActivateDeactivateClassroomLoading = false;
        state.isActivateDeactivateClassroomSuccess = false;
        state.isActivateDeactivateClassroomFailed = true;
        state.activateDeactivateClassroomErrorContainer = {
          ...state.activateDeactivateClassroomErrorContainer,
          ...action.payload
        };
      })

      .addCase(deleteClassroom.pending, state => {
        state.isDeleteClassroomLoading = true;
        state.isDeleteClassroomSuccess = false;
        state.isDeleteClassroomFailed = false;
        state.deleteClassroomErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deleteClassroom.fulfilled, state => {
        state.isDeleteClassroomLoading = false;
        state.isDeleteClassroomSuccess = true;
        state.isDeleteClassroomFailed = false;
      })
      .addCase(deleteClassroom.rejected, (state, action) => {
        state.isDeleteClassroomLoading = false;
        state.isDeleteClassroomSuccess = false;
        state.isDeleteClassroomFailed = true;
        state.deleteClassroomErrorContainer = {
          ...state.deleteClassroomErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdateClassroom.pending, state => {
        state.isCreateUpdateClassroomLoading = true;
        state.isCreateUpdateClassroomSuccess = false;
        state.isCreateUpdateClassroomFailed = false;
        state.createUpdateClassroomErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUpdateClassroom.fulfilled, (state, action) => {
        state.isCreateUpdateClassroomLoading = false;
        state.isCreateUpdateClassroomSuccess = true;
        state.isCreateUpdateClassroomFailed = false;
        state.isCreateUpdateClassroomResponse = action.payload?.message;
      })
      .addCase(createUpdateClassroom.rejected, (state, action) => {
        state.isCreateUpdateClassroomLoading = false;
        state.isCreateUpdateClassroomSuccess = false;
        state.isCreateUpdateClassroomFailed = true;
        state.createUpdateClassroomErrorContainer = {
          ...state.createUpdateClassroomErrorContainer,
          ...action.payload
        };
      })

      .addCase(getClassroomDetails.pending, state => {
        state.isClassroomDetailsLoading = true;
        state.isClassroomDetailsSuccess = false;
        state.isClassroomDetailsFailed = false;
        state.classroomDetails = intial.classroomDetails;
        state.classroomDetailsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getClassroomDetails.fulfilled, (state, action) => {
        console.log('action.payload', action.payload);
        state.isClassroomDetailsLoading = false;
        state.isClassroomDetailsSuccess = true;
        state.classroomDetails = {
          classroomId: action.payload
            ? action.payload?.dataList[0]['classroomId'] || null
            : null,
          division: action.payload
            ? action.payload?.dataList[0]['division'] || null
            : null,
          gradeId: action.payload
            ? action.payload?.dataList[0]['gradeId'] || null
            : null,
          grade: action.payload
            ? action.payload?.dataList[0]['grade'] || null
            : null,
          teacherId: action.payload
            ? action.payload?.dataList[0]['teacherId'] || null
            : null,
          teacherName: action.payload
            ? action.payload?.dataList[0]['teacherName'] || null
            : null,
          status: action.payload
            ? action.payload?.dataList[0]['status'] || null
            : null,
          createdBy: action.payload
            ? action.payload?.dataList[0]['createdBy'] || null
            : null,
          createdDate: action.payload
            ? action.payload?.dataList[0]['createdDate'] || null
            : null
        };
      })
      .addCase(getClassroomDetails.rejected, (state, action) => {
        state.isClassroomDetailsLoading = false;
        state.isClassroomDetailsFailed = true;
        state.classroomDetails = intial.classroomDetails;
        state.classroomDetailsErrorContainer = {
          ...state.classroomDetailsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getGradeList.pending, state => {
        state.isGradeListLoading = true;
        state.isGradeListSuccess = false;
        state.isGradeListFailed = false;
        state.gradeList = intial.gradeList;
      })
      .addCase(getGradeList.fulfilled, (state, action) => {
        state.isGradeListLoading = false;
        state.isGradeListSuccess = true;
        state.isGradeListFailed = false;
        state.gradeList = action.payload;
      })
      .addCase(getGradeList.rejected, (state, action) => {
        state.isGradeListLoading = false;
        state.isGradeListFailed = true;
        state.gradeListErrorContainer = {
          ...state.gradeListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getNoAuthGradeList.pending, state => {
        state.isNoAuthGradeListLoading = true;
        state.isNoAuthGradeListSuccess = false;
        state.isNoAuthGradeListFailed = false;
        state.noAuthGradeList = intial.noAuthGradeList;
      })
      .addCase(getNoAuthGradeList.fulfilled, (state, action) => {
        state.isNoAuthGradeListLoading = false;
        state.isNoAuthGradeListSuccess = true;
        state.isNoAuthGradeListFailed = false;
        state.noAuthGradeList = action.payload;
      })
      .addCase(getNoAuthGradeList.rejected, (state, action) => {
        state.isNoAuthGradeListLoading = false;
        state.isNoAuthGradeListFailed = true;
        state.noAuthGradeListErrorContainer = {
          ...state.noAuthGradeListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getNoAuthRoleList.pending, state => {
        state.isNoAuthRoleListLoading = true;
        state.isNoAuthRoleListSuccess = false;
        state.isNoAuthRoleListFailed = false;
        state.noAuthRoleList = intial.noAuthGradeList;
      })
      .addCase(getNoAuthRoleList.fulfilled, (state, action) => {
        state.isNoAuthRoleListLoading = false;
        state.isNoAuthRoleListSuccess = true;
        state.isGradeListFailed = false;
        state.noAuthRoleList = action.payload;
      })
      .addCase(getNoAuthRoleList.rejected, (state, action) => {
        state.isNoAuthRoleListLoading = false;
        state.isNoAuthRoleListFailed = true;
        state.noAuthRoleListErrorContainer = {
          ...state.noAuthRoleListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getTeacherList.pending, state => {
        state.isTeacherListLoading = true;
        state.isTeacherListSuccess = false;
        state.isTeacherListFailed = false;
        state.teacherList = intial.teacherList;
      })
      .addCase(getTeacherList.fulfilled, (state, action) => {
        state.isTeacherListLoading = false;
        state.isTeacherListSuccess = true;
        state.isTeacherListFailed = false;
        state.teacherList = action.payload;
      })
      .addCase(getTeacherList.rejected, (state, action) => {
        state.isTeacherListLoading = false;
        state.isTeacherListFailed = true;
        state.teacherListErrorContainer = {
          ...state.teacherListErrorContainer,
          ...action.payload
        };
      })
      ////----Classroom Ends
      // <! --district start -->
      .addCase(getDistrictCount.pending, state => {
        state.districtData.allCount = 0;
        state.districtData.activeCount = 0;
        state.districtData.inActiveCount = 0;
      })
      .addCase(getDistrictCount.fulfilled, (state, action) => {
        // console.log(action.payload, 'payload');
        state.districtData = {
          ...state.districtData,
          allCount: action.payload?.allCount,
          activeCount: action.payload?.activeCount,
          inActiveCount: action.payload?.inActiveCount
        };
      })
      .addCase(getDistrictCount.rejected, (state, action) => {
        state.districtData.allCount = 0;
        state.districtData.activeCount = 0;
        state.districtData.inActiveCount = 0;
      })
      // <! --district end -->
      // <! --area start -->
      .addCase(getAreaCount.pending, state => {
        state.areaData.allCount = 0;
        state.areaData.activeCount = 0;
        state.areaData.inActiveCount = 0;
      })
      .addCase(getAreaCount.fulfilled, (state, action) => {
        // console.log(action.payload, 'payload');
        state.areaData = {
          ...state.areaData,
          allCount: action.payload?.allCount,
          activeCount: action.payload?.activeCount,
          inActiveCount: action.payload?.inActiveCount
        };
      })
      .addCase(getAreaCount.rejected, (state, action) => {
        state.areaData.allCount = 0;
        state.areaData.activeCount = 0;
        state.areaData.inActiveCount = 0;
      })
      // <! --area end -->
      // <! --state start -->
      .addCase(getSchoolsCount.pending, state => {
        state.schoolsData.allCount = 0;
        state.schoolsData.activeCount = 0;
        state.schoolsData.inActiveCount = 0;
      })
      .addCase(getSchoolsCount.fulfilled, (state, action) => {
        // console.log(action.payload, 'payload');
        state.schoolsData = {
          ...state.schoolsData,
          allCount: action.payload?.allCount,
          activeCount: action.payload?.activeCount,
          inActiveCount: action.payload?.inActiveCount
        };
      })
      .addCase(getSchoolsCount.rejected, (state, action) => {
        state.schoolsData.allCount = 0;
        state.schoolsData.activeCount = 0;
        state.schoolsData.inActiveCount = 0;
      });
    // <! --state end -->
  }
});

export default masterSlice.reducer;
