import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
  Typography,
  SvgIcon,
  styled,
  Divider,
  SwipeableDrawer,
  IconButton
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveModule,
  toggleExpandMenu
} from '../../redux/reducers/layoutSlice';
import {
  DOWNLOAD_APP_ICON,
  IMG_SPLASH_LOGO,
  menu_location_icon,
  menu_dashboard_icon,
  menu_user_icon,
  menu_schedule_icon,
  menu_analytics_icon,
  menu_teacher_icon,
  menu_observation_icon,
  menu_courses_icon
} from '../../utils/imageUrls';
import {
  colorLightYellow,
  colorPrimary,
  colorSecondary
} from '../../config/theme';
import { drawerWidth } from '../../utils/constant';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { privateRoute } from '../../App';
import { useEffect, useState } from 'react';
import {
  BookOpenIcon,
  CalendarIcon,
  FileSearchIcon,
  GiveFeedBackIcon,
  ProductIcon,
  ResourceFavouritesIcon,
  ResourceHistoryIcon,
  ResourceHomeIcon,
  ResourceLibraryIcon,
  UserEditIcon
} from '../../utils/iconSvg';
import { ChevronLeft, Menu } from '@mui/icons-material';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between'
}));

function SideBar({ menulist, sidebarOpen, onToggle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeMenuId, expandedMenus } = useSelector(state => state.layout);

  const isActiveMenu = currentMenu => {
    return activeMenuId === currentMenu;
  };

  const [open, setOpen] = useState({});

  const handleClick = (item, index, hasChild) => {
    if (hasChild) {
      onToggle(true);
      dispatch(toggleExpandMenu(open === index ? null : index));
    } else {
      dispatch(setActiveModule(item));
      let routeTo = privateRoute.find(cc => cc.menu_name === item);
      if (routeTo) {
        return navigate(routeTo?.path);
      } else {
        navigate('/forbidden');
      }
    }
  };

  const handleDownloadAppClick = () => {
    //  need to add
  };

  useEffect(() => {
    setOpen(expandedMenus);
  }, [expandedMenus]);

  const renderSubmenus = submenus => {
    return (
      <>
        {Object.keys(submenus).map((group, index) => (
          <div key={index}>
            <ListItemButton
              sx={{
                justifyContent: sidebarOpen ? '' : 'center',
                width: sidebarOpen ? '95%' : '90px',
                borderRadius: '0 8px 8px 0',
                backgroundColor: isActiveMenu(group) ? colorPrimary : 'inherit',
                '&:hover': {
                  backgroundColor: 'transparent'
                },
                fontWeight: isActiveMenu(group) ? 'bold' : 'normal'
              }}
              onClick={() => {
                handleClick(
                  group,
                  index,
                  submenus[group] && submenus[group].length > 0
                );
              }}
            >
              <ListItemIcon sx={{ minWidth: 35 }}>
                <SvgIcon>
                  {group === 'User Management' ? (
                    menu_user_icon
                  ) : group === 'Location Management' ? (
                    menu_location_icon
                  ) : group === 'Schedules' ? (
                    menu_schedule_icon
                  ) : group === 'Analytics' ? (
                    menu_analytics_icon
                  ) : group === 'Teacher Evaluation' ? (
                    <UserEditIcon />
                  ) : group === 'Classroom Observations' ? (
                    <FileSearchIcon />
                  ) : group === 'Courses' ? (
                    menu_courses_icon
                  ) : group === 'Teaching Aids' ? (
                    <BookOpenIcon />
                  ) : group === 'Session Schedules' ? (
                    <CalendarIcon />
                  ) : group === 'Give Feedback' ? (
                    <GiveFeedBackIcon />
                  ) : group === 'Product Analytics' ? (
                    <ProductIcon />
                  ) : group === 'Resources' ? (
                    // <GiveFeedBackIcon />
                    ''
                  ) : (
                    ''
                  )}
                </SvgIcon>
              </ListItemIcon>
              {/* <ListItemText>
                <Typography fontWeight={'600'} fontSize={13}>
                  {group}
                </Typography>
              </ListItemText> */}
              {sidebarOpen && (
                <ListItemText>
                  <Typography fontWeight={'600'} fontSize={13}>
                    {group}
                  </Typography>
                </ListItemText>
              )}
              {sidebarOpen && (
                <>
                  {submenus[group] && submenus[group].length > 0 ? (
                    open === index ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : (
                    ''
                  )}
                </>
              )}
            </ListItemButton>
            <Collapse in={open == index} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {menulist[group]
                  .filter(cc => cc.status)
                  .map(item => (
                    <ListItemButton
                      sx={{
                        justifyContent: sidebarOpen ? '' : 'center',
                        width: sidebarOpen ? '95%' : '90px',
                        borderRadius: '0 8px 8px 0',
                        backgroundColor: isActiveMenu(item.pageName)
                          ? colorPrimary
                          : 'inherit',
                        '&:hover': {
                          backgroundColor: 'transparent'
                        },
                        fontWeight: isActiveMenu(item.pageName)
                          ? 'bold'
                          : 'normal'
                      }}
                      key={item.pageId}
                      onClick={() => handleClick(item.pageName, index, false)}
                    >
                      <ListItemIcon sx={{ minWidth: 35 }}>
                        <SvgIcon>
                          {item?.pageName === 'Home' ? (
                            <ResourceHomeIcon />
                          ) : item?.pageName === 'Library' ? (
                            <ResourceLibraryIcon />
                          ) : item?.pageName === 'History' ? (
                            <ResourceHistoryIcon />
                          ) : item?.pageName === 'Favourites' ? (
                            <ResourceFavouritesIcon />
                          ) : (
                            ''
                          )}
                        </SvgIcon>
                      </ListItemIcon>
                      {/* <ListItemText>
                        <Typography fontWeight={'600'} fontSize={13}>
                          {item.pageName}
                        </Typography>
                      </ListItemText> */}
                      {sidebarOpen && (
                        <ListItemText>
                          <Typography fontWeight={'600'} fontSize={13}>
                            {item.pageName}
                          </Typography>
                        </ListItemText>
                      )}
                    </ListItemButton>
                  ))}
              </List>
            </Collapse>
          </div>
        ))}
      </>
    );
  };

  const list = anchor => (
    <Box role="presentation">
      <List
        component="div"
        sx={{
          width: '100%',
          bgcolor: colorSecondary,
          maxWidth: '360px',
          overflowY: 'auto',
          height: { lg: 'calc(100vh - 300px)' },
          '&::-webkit-scrollbar': {
            width: '0.0em'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.3)'
          }
        }}
      >
        <ListItemButton
          sx={{
            justifyContent: sidebarOpen ? '' : 'center',
            width: sidebarOpen ? '95%' : '90px',
            borderRadius: '0 8px 8px 0',
            backgroundColor: isActiveMenu('dashboard')
              ? colorPrimary
              : 'inherit',
            '&:hover': {
              backgroundColor: 'transparent'
            },
            fontWeight: isActiveMenu('dashboard') ? 'bold' : 'normal'
          }}
          onClick={() => handleClick('dashboard', 999, false)}
        >
          <ListItemIcon sx={{ minWidth: 35 }}>
            <SvgIcon>{menu_dashboard_icon}</SvgIcon>
          </ListItemIcon>
          {sidebarOpen && (
            <Typography fontWeight={'600'} fontSize={13}>
              Dashboard
            </Typography>
          )}
        </ListItemButton>
        {renderSubmenus(menulist)}
      </List>
    </Box>
  );

  return (
    <>
      <SwipeableDrawer
        open={true}
        onClose={() => {
          onToggle(false);
        }}
        onOpen={() => {
          onToggle(true);
        }}
        sx={{
          width: sidebarOpen ? drawerWidth : 106,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: sidebarOpen ? drawerWidth : 106,
            boxSizing: 'border-box',
            bgcolor: colorSecondary,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            border: 'none'
          }
        }}
        variant="persistent"
        anchor="left"
      >
        <DrawerHeader
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignSelf: 'flex-start',
            marginTop: 2
          }}
        >
          <Box
            role="presentation"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/dashboard')}
          >
            <img
              style={{
                // display: 'block',
                margin: '15px 15px'
              }}
              height={sidebarOpen ? 65 : 45}
              width={sidebarOpen ? 65 : 45}
              src={IMG_SPLASH_LOGO}
              alt="IMG_SPLASH_LOGO"
            />
          </Box>

          <IconButton
            sx={{
              display: 'flex',
              padding: '0px',
              alignSelf: 'flex-start',
              mx: 3
            }}
            onClick={() => {
              dispatch(toggleExpandMenu(null));
              onToggle(!sidebarOpen);
            }}
          >
            {sidebarOpen ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 15L7.5 10L12.5 5"
                  stroke="#1F2933"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <Menu />
            )}
          </IconButton>
        </DrawerHeader>

        {list('left')}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'self-end',
            justifyContent: 'center',
            textAlign: 'center',
            alignSelf: 'center',
            marginTop: 'auto',
            marginBottom: '10px',
            height: { lg: '80%', xl: '80%', md: '75%' },
            width: { lg: '80%', xl: '75%', md: '75%' }
          }}
        >
          {!sidebarOpen ? (
            <Box sx={{ position: 'fixed' }}>
              {/* <IconButton onClick={() => onToggle(true)} sx={{ padding: 0 }}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.166 18.0003H23.8327M23.8327 18.0003L17.9993 12.167M23.8327 18.0003L17.9993 23.8337"
                    stroke="#1F2933"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton> */}
            </Box>
          ) : (
            <>
              <img
                style={{ height: '100%', width: '100%' }}
                onClick={handleDownloadAppClick}
                src={DOWNLOAD_APP_ICON}
                alt="Download app icon"
              />
            </>
          )}
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default SideBar;
