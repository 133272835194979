import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import {
  colorFieldError,
  colorPureWhite,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../config/theme';
import { IMG_SPLASH_LOGO } from '../utils/imageUrls';
import { Calendar01Icon, EyeOffIcon, EyeOnIcon } from '../utils/iconSvg';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  ageValidation,
  createTitle,
  enterOnlyNumbers,
  enterWithNoSpaces,
  handleError,
  isArrayNotEmpty,
  isValidEmail,
  isValidMobileNumber,
  isValidPassword,
  mapArrayToTypography
} from '../utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getArea,
  getAreaNoAuth,
  getDistrict,
  getDistrictNoAuth,
  getSchoolsNoAuth,
  getState,
  getStateNoAuth
} from '../redux/reducers/mastersSlice';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  registerUser,
  revertRegisterUser,
  validateUserDetails
} from '../redux/reducers/authSlice';
import SnackBarBox from '../components/SnackBarBox';
import { resetGoogleLogin } from '../redux/reducers/authSlice';

function Registration() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const firstName = location?.state?.firstName || '';
  const lastName = location?.state?.lastName || '';
  const email = location?.state?.email || '';
  const isGoogleSignIn = location?.state?.isGoogleSignIn || false;

  const {
    isRegisterUserLoading,
    isRegisterUserFailed,
    isRegisterUserSuccess,
    registerUserData,
    registerUserErrorContainer,
    isValidateUserLoading,
    isValidateUserFailed,
    isValidateUserSuccess,
    validateUserErrorContainer
  } = useSelector(state => state.auth);

  const {
    areaNoAuthData,
    stateNoAuthData,
    districtNoAuthData,
    schoolsNoAuthData
  } = useSelector(state => state.masters);

  const formControl = {
    margin: '0.5rem auto'
  };

  useEffect(() => {
    if (firstName || lastName || email) {
      formik.setValues({
        ...formik.values,
        firstName: firstName || '',
        lastName: lastName || '',
        email: email || ''
      });
    }
  }, [firstName, lastName, email]);

  useEffect(() => {
    document.title = createTitle('Sign-up');
    let mastersPayload = {
      type: 'all',
      page: 0,
      size: 0,
      search: ''
    };
    dispatch(revertRegisterUser());
    dispatch(getAreaNoAuth(mastersPayload));
    dispatch(getStateNoAuth(mastersPayload));
    dispatch(getDistrictNoAuth(mastersPayload));
    dispatch(getSchoolsNoAuth(mastersPayload));
    dispatch(resetGoogleLogin());
  }, []);

  // useEffect(() => {
  //   if (!isRegisterUserLoading && isRegisterUserSuccess) {
  //     navigate('/welcome', {
  //       state: {
  //         name: formik?.values?.firstName,
  //         username: registerUserData?.payload?.payload?.id,
  //         userPassword: formik?.values?.password
  //       }
  //     });
  //   }
  // }, [isRegisterUserSuccess]);

  const validationSchema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup
      .string()
      .required('Email is required')
      .test('is-valid-email', 'Please provide a valid email address.', value =>
        isValidEmail(value)
      ),
    dob: yup
      .date()
      .max(
        ageValidation({ year: 4, month: 0 }),
        'Minimum age required is 4.0 years'
      )
      .required('Date of birth is required'),
    contactNo: yup
      .string()
      .required('Contact Number is required')
      .test(
        'is-valid-mobile-number',
        'Contact number should consist of exactly 10 digits.',
        value => isValidMobileNumber(value)
      )
      .min(10, 'Contact number should be minimum of 10 digit.')
      .matches(/^[6789]\d{9}$/, 'Invalid contact number'),
    state: yup.string().required('State is required'),
    district: yup.string().required('District is required'),
    area: yup.string().required('Area is required'),
    schoolName: yup.string().required('School is required'),
    password: yup
      .string()
      .required('Password cannot be blank')
      .test(
        'is-valid-password',
        'Password should be atleast 8 characters and should contain uppercase, lowercase, number and special character.',
        value => isValidPassword(value)
      ),
    confirmPassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password'), null], 'Re-entered password is incorrect')
  });

  const googleSignInValidation = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup
      .string()
      .required('Email is required')
      .test('is-valid-email', 'Please provide a valid email address.', value =>
        isValidEmail(value)
      ),
    dob: yup
      .date()
      .max(
        ageValidation({ year: 4, month: 0 }),
        'Minimum age required is 4.0 years'
      )
      .required('Date of birth is required'),
    contactNo: yup
      .string()
      .required('Contact Number is required')
      .test(
        'is-valid-mobile-number',
        'Mobile number should consist of exactly 10 digits.',
        value => isValidMobileNumber(value)
      )
      .min(10, 'Mobile Number should be minimum of 10 digit.')
      .matches(/^[6789]\d{9}$/, 'Invalid Mobile Number'),
    state: yup.string().required('State is required'),
    district: yup.string().required('District is required'),
    area: yup.string().required('Area is required'),
    schoolName: yup.string().required('School is required')
  });
  const formik = useFormik({
    initialValues: {
      firstName: location?.state?.firstName || '',
      lastName: location?.state?.lastName || '',
      email: location?.state?.email || '',
      dob: location?.state?.dateOfBirth || '',
      contactNo: location?.state?.contactNumber || '',
      state: location?.state?.stateId || '',
      district: location?.state?.districtId || '',
      area: location?.state?.area || '',
      schoolName: location?.state?.schoolId || '',
      password: location?.state?.password || '',
      confirmPassword: location?.state?.password || '',
      roleId: location?.state?.roleId || '',
      grade: location?.state?.grade || ''
    },

    validationSchema: isGoogleSignIn
      ? googleSignInValidation
      : validationSchema,
    onSubmit: value => {
      let payload = {
        name: `${value.firstName || ''} ${value.lastName || ''}`,
        contactNumber: value.contactNo,
        email: value.email,
        dateOfBirth: value.dob,
        userType: 'Self_Registered',
        citizenship: 'Indian',
        status: true,
        loggedInUserName: '',
        password: isGoogleSignIn ? null : value.password,
        stateId: value.state,
        districtId: value.district,
        area: value.area,
        schoolId: value.schoolName
        // isSignInGoogle: isGoogleSignIn ? true : false
      };
      const formData = new FormData();
      formData.append('user', JSON.stringify(payload));
      dispatch(validateUserDetails(formData));

      // dispatch(registerUser(formData));
    }
  });

  useEffect(() => {
    if (!isValidateUserLoading && isValidateUserSuccess) {
      navigate('/welcome', {
        state: {
          firstName: formik?.values?.firstName,
          lastName: formik?.values?.lastName,
          username: registerUserData?.payload?.payload?.id,
          userPassword: formik?.values?.password,
          name: `${formik?.values?.firstName || ''} ${formik?.values?.lastName || ''}`,
          contactNumber: formik?.values?.contactNo,
          email: formik?.values?.email,
          dateOfBirth: dayjs(formik?.values?.dob).format('YYYY-MM-DD'),
          userType: 'Self_Registered',
          citizenship: 'Indian',
          status: true,
          loggedInUserName: '',
          password: isGoogleSignIn ? null : formik?.values?.password,
          stateId: formik?.values?.state,
          districtId: formik?.values?.district,
          area: formik?.values?.area,
          schoolId: formik?.values?.schoolName,
          isSignInGoogle: isGoogleSignIn ? true : false,
          roleId: formik?.values?.roleId,
          grade: formik?.values?.grade
        }
      });
    }
  }, [isValidateUserSuccess]);

  const handleFormValue = (e, field) => {
    formik.setFieldValue(field, e.target?.value);
  };

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (!isGoogleSignIn) {
      if (
        !formik?.values?.firstName ||
        !formik?.values?.lastName ||
        !formik?.values?.email ||
        !formik?.values?.password ||
        !formik?.values?.confirmPassword ||
        !formik?.values?.contactNo ||
        !formik?.values?.state ||
        !formik?.values?.district ||
        !formik?.values?.area ||
        !formik?.values?.schoolName
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      if (
        !formik?.values?.firstName ||
        !formik?.values?.lastName ||
        !formik?.values?.email ||
        !formik?.values?.contactNo ||
        !formik?.values?.state ||
        !formik?.values?.district ||
        !formik?.values?.area ||
        !formik?.values?.schoolName
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [formik]);

  return (
    <Grid
      sx={{
        // p: 2,
        height: '100vh',
        position: 'fixed',
        width: '100%',
        display: 'flex',
        // justifyContent: 'space-between',
        // gap: 8,
        backgroundColor: colorPureWhite
      }}
    >
      <Grid
        style={{
          overflowY: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          WebkitOverflowScrolling: 'touch',
          scrollbarColor: 'transparent transparent',
          // backgroundColor: 'yellow',
          width: '80%'
        }}
      >
        <Grid sx={{ px: '24px', py: '20px' }}>
          <img
            width={'80px'}
            height={'75.88px'}
            src={IMG_SPLASH_LOGO}
            alt={IMG_SPLASH_LOGO}
          />
        </Grid>
        <Grid
          sx={{
            p: 3,
            py: 10,
            marginLeft: '64px',
            marginRight: '64px'
          }}
        >
          <Box>
            <Typography
              fontSize={'32px'}
              fontWeight={'700'}
              fontFamily={'Lato'}
              color={'#1F2933'}
            >
              New user registration
            </Typography>
          </Box>

          <Box>
            <Box mt={5} mb={2}>
              <form autoComplete="off" onSubmit={formik?.handleSubmit}>
                {/* First Name & Last name & Email*/}
                {/* <Box display={'flex'} gap={'16px'}> */}
                <Grid container spacing={2}>
                  <Grid lg={4} md={6} sm={12} xs={12} item sx={formControl}>
                    <InputLabel
                      required
                      sx={{
                        mb: 0.2,
                        fontWeight: '700',
                        color: colorTextBlack,
                        fontSize: '16px'
                      }}
                    >
                      First name
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        name="firstName"
                        size="small"
                        // sx={{ width: '300px', height: '42px' }}
                        placeholder="Enter your first name"
                        value={formik.values.firstName}
                        onChange={e => handleFormValue(e, 'firstName')}
                        error={
                          formik.touched?.firstName && formik.errors?.firstName
                        }
                        helperText={
                          formik.touched?.firstName && formik.errors?.firstName
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid lg={4} md={6} sm={12} xs={12} item sx={formControl}>
                    <InputLabel
                      required
                      sx={{
                        mb: 0.2,
                        fontWeight: '700',
                        color: colorTextBlack,
                        fontSize: '16px'
                      }}
                    >
                      Last name
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        name="lastName"
                        size="small"
                        placeholder="Enter your last name"
                        // sx={{ width: '300px', height: '42px' }}
                        value={formik.values.lastName}
                        onChange={e => handleFormValue(e, 'lastName')}
                        error={
                          formik.touched?.lastName && formik.errors?.lastName
                        }
                        helperText={
                          formik.touched?.lastName && formik.errors?.lastName
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid lg={4} md={6} sm={12} xs={12} item sx={formControl}>
                    <InputLabel
                      required
                      sx={{
                        mb: 0.2,
                        fontWeight: '700',
                        color: colorTextBlack,
                        fontSize: '16px'
                      }}
                    >
                      Email
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        disabled={isGoogleSignIn}
                        name="email"
                        size="small"
                        placeholder="Enter your email address"
                        inputProps={{
                          autocomplete: 'new-email',
                          form: {
                            autocomplete: 'off'
                          }
                        }}
                        // sx={{ width: '300px', height: '42px' }}
                        value={formik.values.email}
                        onChange={e => handleFormValue(e, 'email')}
                        error={formik.touched?.email && formik.errors?.email}
                        helperText={
                          formik.touched?.email && formik.errors?.email
                        }
                      />
                    </FormControl>
                  </Grid>
                  {/* </Box> */}
                </Grid>

                {/* DOB & Password & Re-enter password */}
                {/* <Box display={'flex'} gap={'16px'}> */}
                <Grid container spacing={2}>
                  <Grid item lg={4} md={6} sm={12} xs={12} sx={formControl}>
                    <InputLabel
                      required
                      sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                    >
                      Date of birth
                    </InputLabel>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disableFuture={dayjs(new Date())}
                          name="dob"
                          value={
                            formik.values.dob ? dayjs(formik.values.dob) : null
                          }
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              size: 'small',
                              InputProps: {
                                sx: { color: colorTextBlack }
                              },
                              sx: {
                                borderRadius: '8px',
                                border: `1px solid ${formik.touched?.dob && formik.errors?.dob ? 'red' : 'inherit'}`
                              }
                            }
                          }}
                          onChange={date => {
                            formik.setFieldValue('dob', date);
                            formik.setFieldTouched('dob', true);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={
                                formik.touched?.dob &&
                                Boolean(formik.errors?.dob)
                              }
                            />
                          )}
                          components={{
                            OpenPickerIcon: props => (
                              <IconButton size="small" {...props}>
                                <Calendar01Icon />
                              </IconButton>
                            )
                          }}
                        />
                      </LocalizationProvider>
                      {formik.touched?.dob && formik.errors?.dob && (
                        <FormHelperText>{formik.errors?.dob}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {!isGoogleSignIn ? (
                    <>
                      <Grid item lg={4} md={6} sm={12} xs={12} sx={formControl}>
                        <InputLabel
                          required
                          sx={{
                            mb: 0.2,
                            fontWeight: '700',
                            fontSize: '16px',
                            color: colorTextBlack
                          }}
                        >
                          Password
                        </InputLabel>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            name="password"
                            placeholder="Enter your password"
                            type={!showPassword ? 'text' : 'password'}
                            inputProps={{
                              maxLength: 16,
                              minLength: 8,
                              autocomplete: 'new-password',
                              form: {
                                autocomplete: 'off'
                              }
                            }}
                            // sx={{ width: '300px', height: '42px' }}
                            value={formik.values.password}
                            onChange={e =>
                              formik.setFieldValue(
                                'password',
                                enterWithNoSpaces(e.target.value)
                              )
                            }
                            size="small"
                            error={
                              formik.touched?.password &&
                              formik.errors?.password
                            }
                            // helperText={
                            //   formik.touched?.password && formik.errors?.password
                            // }
                            InputProps={{
                              style: { backgroundColor: colorPureWhite },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      display: 'inline-flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {!showPassword ? (
                                      <EyeOnIcon
                                        color={
                                          formik.values.password !== ''
                                            ? colorTextBlack
                                            : colorTextGrey
                                        }
                                      />
                                    ) : (
                                      <EyeOffIcon
                                        color={
                                          formik.values.password !== ''
                                            ? colorTextBlack
                                            : colorTextGrey
                                        }
                                      />
                                    )}
                                  </span>
                                </InputAdornment>
                              ),
                              tabIndex: -1
                            }}
                          />
                          {formik.touched?.password &&
                            formik.errors?.password && (
                              <FormHelperText sx={{ mt: 0.2 }}>
                                <SvgIcon
                                  sx={{
                                    marginRight: 0.5,
                                    height: '16px',
                                    width: '16px'
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="15"
                                    viewBox="0 0 16 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.64093 5.87662V8.54329M7.64093 11.21H7.6476M6.71781 2.4711L1.23455 11.9422C0.930414 12.4675 0.778346 12.7302 0.800822 12.9457C0.820425 13.1338 0.918939 13.3046 1.07184 13.4158C1.24715 13.5433 1.55066 13.5433 2.15767 13.5433H13.1242C13.7312 13.5433 14.0347 13.5433 14.21 13.4158C14.3629 13.3046 14.4614 13.1338 14.481 12.9457C14.5035 12.7302 14.3514 12.4675 14.0473 11.9422L8.56405 2.4711C8.261 1.94766 8.10948 1.68594 7.9118 1.59803C7.73936 1.52136 7.5425 1.52136 7.37006 1.59803C7.17238 1.68594 7.02085 1.94766 6.71781 2.4711Z"
                                      stroke={colorFieldError}
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </SvgIcon>
                                {formik.errors?.password}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item lg={4} md={6} sm={12} xs={12} sx={formControl}>
                        <InputLabel
                          required
                          sx={{
                            mb: 0.2,
                            fontWeight: '700',
                            fontSize: '16px',
                            color: colorTextBlack
                          }}
                        >
                          Re-enter password
                        </InputLabel>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            name="confirmPassword"
                            placeholder="Re-enter your password"
                            type={!showConfirmPassword ? 'text' : 'password'}
                            inputProps={{
                              maxLength: 16,
                              minLength: 8,
                              autocomplete: 'confirmPassword',
                              form: {
                                autocomplete: 'off'
                              }
                            }}
                            // sx={{ width: '300px', height: '42px' }}
                            value={formik.values.confirmPassword}
                            onChange={e =>
                              formik.setFieldValue(
                                'confirmPassword',
                                enterWithNoSpaces(e.target.value)
                              )
                            }
                            size="small"
                            error={
                              formik.touched?.confirmPassword &&
                              formik.errors?.confirmPassword
                            }
                            // helperText={
                            //   formik.touched?.confirmPassword &&
                            //   formik.errors?.confirmPassword
                            // }
                            InputProps={{
                              style: { backgroundColor: colorPureWhite },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span
                                    onClick={() =>
                                      setShowConfirmPassword(
                                        !showConfirmPassword
                                      )
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      display: 'inline-flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {!showConfirmPassword ? (
                                      <EyeOnIcon
                                        color={
                                          formik.values.confirmPassword !== ''
                                            ? colorTextBlack
                                            : colorTextGrey
                                        }
                                      />
                                    ) : (
                                      <EyeOffIcon
                                        color={
                                          formik.values.confirmPassword !== ''
                                            ? colorTextBlack
                                            : colorTextGrey
                                        }
                                      />
                                    )}
                                  </span>
                                </InputAdornment>
                              ),
                              tabIndex: -1
                            }}
                          />
                          {formik.touched?.confirmPassword &&
                            formik.errors?.confirmPassword && (
                              <FormHelperText sx={{ mt: 0.2 }}>
                                <SvgIcon
                                  sx={{
                                    marginRight: 0.5,
                                    height: '16px',
                                    width: '16px'
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="15"
                                    viewBox="0 0 16 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.64093 5.87662V8.54329M7.64093 11.21H7.6476M6.71781 2.4711L1.23455 11.9422C0.930414 12.4675 0.778346 12.7302 0.800822 12.9457C0.820425 13.1338 0.918939 13.3046 1.07184 13.4158C1.24715 13.5433 1.55066 13.5433 2.15767 13.5433H13.1242C13.7312 13.5433 14.0347 13.5433 14.21 13.4158C14.3629 13.3046 14.4614 13.1338 14.481 12.9457C14.5035 12.7302 14.3514 12.4675 14.0473 11.9422L8.56405 2.4711C8.261 1.94766 8.10948 1.68594 7.9118 1.59803C7.73936 1.52136 7.5425 1.52136 7.37006 1.59803C7.17238 1.68594 7.02085 1.94766 6.71781 2.4711Z"
                                      stroke={colorFieldError}
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </SvgIcon>
                                {formik.errors?.confirmPassword}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        lg={4}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={formControl}
                      ></Grid>
                      <Grid
                        item
                        lg={4}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={formControl}
                      ></Grid>
                    </>
                  )}

                  {/* </Box> */}
                </Grid>

                <Divider sx={{ marginY: '16px' }} />

                {/* Contact no & State & District */}
                {/* <Box display={'flex'} gap={'16px'}> */}
                <Grid container spacing={2}>
                  <Grid lg={4} md={6} sm={12} xs={12} item sx={formControl}>
                    <InputLabel
                      required
                      sx={{
                        mb: 0.2,
                        fontWeight: '700',
                        color: colorTextBlack,
                        fontSize: '16px'
                      }}
                    >
                      Contact no.
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        name="contactNo"
                        size="small"
                        placeholder="Enter your contact no."
                        // sx={{ width: '300px', height: '42px' }}
                        type="tel"
                        inputProps={{ maxLength: 10 }}
                        value={formik.values.contactNo}
                        onChange={e => {
                          formik.setFieldValue(
                            'contactNo',
                            enterOnlyNumbers(e.target.value)
                          );
                        }}
                        error={
                          formik.touched?.contactNo && formik.errors?.contactNo
                        }
                        helperText={
                          formik.touched?.contactNo && formik.errors?.contactNo
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid lg={4} md={6} sm={12} xs={12} item sx={formControl}>
                    <InputLabel
                      required
                      sx={{
                        mb: 0.2,
                        fontWeight: '700',
                        color: colorTextBlack,
                        fontSize: '16px'
                      }}
                    >
                      State
                    </InputLabel>
                    <FormControl fullWidth size="small">
                      <Select
                        value={formik.values.state}
                        onChange={e => {
                          formik.handleChange(e);
                          formik.setFieldValue('district', null);
                          formik.setFieldValue('area', null);
                          formik.setFieldValue('schoolName', null);
                        }}
                        name="state"
                        displayEmpty
                        sx={{
                          textAlign: 'left'
                        }}
                        renderValue={selected => {
                          if (selected === '') {
                            return (
                              <span
                                style={{
                                  color: '#ABB4BD',
                                  fontFamily: 'Lato',
                                  fontWeight: 500
                                }}
                              >
                                Select state name
                              </span>
                            );
                          }
                          return stateNoAuthData.state.find(
                            row => row.stateId === selected
                          )?.stateName;
                        }}
                      >
                        <MenuItem value="" disabled>
                          <div color="#ABB4BD">Select state name</div>
                        </MenuItem>
                        {isArrayNotEmpty(stateNoAuthData.state) &&
                          stateNoAuthData.state.map((row, key) => {
                            return (
                              <MenuItem
                                key={key}
                                value={row.stateId}
                                sx={{
                                  ':hover': {
                                    backgroundColor: colorSecondary
                                  }
                                }}
                              >
                                {row?.stateName}
                              </MenuItem>
                            );
                          })}

                        {!isArrayNotEmpty(stateNoAuthData.state) && (
                          <MenuItem
                            key={-99}
                            value={null}
                            sx={{
                              ':hover': {
                                backgroundColor: colorSecondary
                              }
                            }}
                          >
                            None
                          </MenuItem>
                        )}
                      </Select>
                      {formik.touched?.state && formik.errors?.state && (
                        <FormHelperText sx={{ textAlign: 'start' }}>
                          {formik.errors?.state}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid lg={4} md={6} sm={12} xs={12} item sx={formControl}>
                    <InputLabel
                      required
                      sx={{
                        mb: 0.2,
                        fontWeight: '700',
                        color: colorTextBlack,
                        fontSize: '16px'
                      }}
                    >
                      District
                    </InputLabel>
                    <FormControl fullWidth size="small">
                      <Select
                        value={formik.values.district}
                        onChange={e => {
                          formik.handleChange(e);
                          formik.setFieldValue('area', null);
                          formik.setFieldValue('schoolName', null);
                        }}
                        name="district"
                        displayEmpty
                        sx={{
                          textAlign: 'left'
                          // width: '300px',
                          // height: '42px'
                        }}
                        renderValue={selected => {
                          if (selected === '') {
                            return (
                              <span
                                style={{
                                  color: '#ABB4BD',
                                  fontFamily: 'Lato',
                                  fontWeight: 500
                                }}
                              >
                                Select district name
                              </span>
                            );
                          }
                          return districtNoAuthData.district.find(
                            row => row.districtId === selected
                          )?.districtName;
                        }}
                      >
                        <MenuItem value="" disabled>
                          <div color="#ABB4BD">Select district name</div>
                        </MenuItem>
                        {isArrayNotEmpty(districtNoAuthData.district) &&
                          districtNoAuthData.district
                            .filter(cc => cc.stateId === formik.values.state)
                            .map((row, key) => {
                              return (
                                <MenuItem
                                  key={key}
                                  value={row?.districtId}
                                  sx={{
                                    ':hover': {
                                      backgroundColor: colorSecondary
                                    }
                                  }}
                                >
                                  {row?.districtName}
                                </MenuItem>
                              );
                            })}
                        {!isArrayNotEmpty(
                          districtNoAuthData.district.filter(
                            cc => cc.stateId === formik.values.state
                          )
                        ) && (
                          <MenuItem
                            key={-99}
                            value={null}
                            sx={{
                              ':hover': {
                                backgroundColor: colorSecondary
                              }
                            }}
                          >
                            None
                          </MenuItem>
                        )}
                      </Select>
                      {formik.touched?.district && formik.errors?.district && (
                        <FormHelperText sx={{ textAlign: 'start' }}>
                          {formik.errors?.district}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* </Box> */}
                </Grid>

                {/* Area & School */}
                {/* <Box display={'flex'} gap={'16px'}> */}
                <Grid container spacing={2}>
                  <Grid lg={4} md={6} sm={12} xs={12} item sx={formControl}>
                    <InputLabel
                      required
                      sx={{
                        mb: 0.2,
                        fontWeight: '700',
                        color: colorTextBlack,
                        fontSize: '16px'
                      }}
                    >
                      Area
                    </InputLabel>
                    <FormControl fullWidth size="small">
                      <Select
                        value={formik.values.area}
                        onChange={e => {
                          formik.handleChange(e);
                          formik.setFieldValue('schoolName', null);
                        }}
                        name="area"
                        displayEmpty
                        sx={{
                          textAlign: 'left'
                        }}
                        renderValue={selected => {
                          if (selected === '') {
                            return (
                              <span
                                style={{
                                  color: '#ABB4BD',
                                  fontFamily: 'Lato',
                                  fontWeight: 500
                                }}
                              >
                                Select area name
                              </span>
                            );
                          }
                          return areaNoAuthData.area.find(
                            row => row.pinId === selected
                          )?.area;
                        }}
                      >
                        <MenuItem value="" disabled>
                          <div color="#ABB4BD">Select area name</div>
                        </MenuItem>
                        {isArrayNotEmpty(areaNoAuthData.area) &&
                          areaNoAuthData.area
                            .filter(
                              cc =>
                                cc.stateId === formik.values.state &&
                                cc.districtId === formik.values.district
                            )
                            .map((row, key) => {
                              return (
                                <MenuItem
                                  key={key}
                                  value={row?.pinId}
                                  sx={{
                                    ':hover': {
                                      backgroundColor: colorSecondary
                                    }
                                  }}
                                >
                                  {row?.area}
                                </MenuItem>
                              );
                            })}

                        {!isArrayNotEmpty(
                          areaNoAuthData.area.filter(
                            cc =>
                              cc.stateId === formik.values.state &&
                              cc.districtId === formik.values.district
                          )
                        ) && (
                          <MenuItem key={-99} value={null}>
                            None
                          </MenuItem>
                        )}
                      </Select>
                      {formik.touched?.area && formik.errors?.area && (
                        <FormHelperText sx={{ textAlign: 'start' }}>
                          {formik.errors?.area}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid lg={4} md={6} sm={12} xs={12} item sx={formControl}>
                    <InputLabel
                      required
                      sx={{
                        mb: 0.2,
                        fontWeight: '700',
                        color: colorTextBlack,
                        fontSize: '16px'
                      }}
                    >
                      School name
                    </InputLabel>
                    <FormControl fullWidth size="small">
                      <Select
                        value={formik.values?.schoolName}
                        onChange={formik.handleChange}
                        name="schoolName"
                        displayEmpty
                        sx={{
                          textAlign: 'left'
                        }}
                        renderValue={selected => {
                          if (selected === '') {
                            return (
                              <span
                                style={{
                                  color: '#ABB4BD',
                                  fontFamily: 'Lato',
                                  fontWeight: 500
                                }}
                              >
                                Select school name
                              </span>
                            );
                          }
                          return schoolsNoAuthData?.schools.find(
                            row => row.schoolId === selected
                          )?.schoolName;
                        }}
                      >
                        <MenuItem value="" disabled>
                          <div color="#ABB4BD">Select school name</div>
                        </MenuItem>
                        {isArrayNotEmpty(schoolsNoAuthData?.schools) &&
                          schoolsNoAuthData?.schools
                            .filter(
                              cc =>
                                cc.stateId === formik.values?.state &&
                                cc.districtId === formik.values?.district &&
                                cc.pinId === formik.values?.area
                            )
                            .map((row, key) => {
                              return (
                                <MenuItem
                                  key={key}
                                  value={row?.schoolId}
                                  sx={{
                                    ':hover': {
                                      backgroundColor: colorSecondary
                                    }
                                  }}
                                >
                                  {row?.schoolName}
                                </MenuItem>
                              );
                            })}

                        {!isArrayNotEmpty(
                          schoolsNoAuthData.schools.filter(
                            cc =>
                              cc.stateId === formik.values.state &&
                              cc.districtId === formik.values.district &&
                              cc.pinId === formik.values?.area
                          )
                        ) && (
                          <MenuItem key={-99} value={null}>
                            None
                          </MenuItem>
                        )}
                      </Select>
                      {formik.touched?.schoolName &&
                        formik.errors?.schoolName && (
                          <FormHelperText sx={{ textAlign: 'start' }}>
                            {formik.errors?.schoolName}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>

                  <Grid
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    sx={formControl}
                  ></Grid>
                  {/* </Box> */}
                </Grid>
              </form>
            </Box>
            <Divider />
          </Box>
          <Box mt={3}>
            <Grid
              sx={formControl}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display={'flex'}
              gap={2}
            >
              <Button
                variant="outlined"
                sx={{ color: colorTextBlack, height: '42px', width: '106px' }}
                size="medium"
                fullWidth
                onClick={() => {
                  navigate('/login');
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="medium"
                disabled={disabled}
                fullWidth
                sx={{ height: '42px', width: '95px' }}
                onClick={formik.handleSubmit}
              >
                Next
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid
        sx={{
          backgroundColor: colorSecondary,
          width: '360px',
          // height: '928px',
          borderRadius: '5px',
          margin: '16px'
        }}
      ></Grid>

      {!isValidateUserLoading && isValidateUserFailed && (
        <SnackBarBox
          type="error"
          // message={mapArrayToTypography(
          //   createUserErrorContainer?.errorMessage?.split(';')
          // )}
          message={
            validateUserErrorContainer?.errorMessage?.split(';').length > 1
              ? mapArrayToTypography(
                  validateUserErrorContainer?.errorMessage?.split(';')
                )
              : handleError(validateUserErrorContainer?.errorMessage)
          }
        />
      )}
    </Grid>
  );
}

export default Registration;
