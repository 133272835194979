import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Chip,
  Container,
  createTheme,
  IconButton,
  ThemeProvider
} from '@mui/material';
import { colorSecondary } from '../../config/theme';
import {
  ArrowBack,
  ArrowBackIos,
  ArrowForward,
  ArrowForwardIos
} from '@mui/icons-material';
import ContentSection from '../../containers/Resources/ContentSection';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#FEF8EC',
      contrastText: '#000000' // Ensure contrastText is defined
    }
  }
});

export default function Resources() {
  const tags = [
    'All',
    'Education',
    'Sports',
    'Music',
    'Technology',
    'Arthur’s House',
    'History of India',
    'Global Warming',
    'Environmental Sciences',
    'Environmental Sciences',
    'Social Emotional Learning'
  ];

  const sections = [
    {
      title: 'Fun with Stories',
      subtitle: '45 videos',
      videos: [
        {
          title:
            'A story day | Student discipline and creating safe spaces forchildren headline',
          author: 'Yashavi Kapoor',
          views: '1.2K',
          timeAgo: '2 days ago'
        },
        {
          title:
            'A story day | Student discipline and creating safe spaces forchildren headline',
          author: 'Yashavi Kapoor',
          views: '3.4K',
          timeAgo: '1 week ago'
        },
        {
          title:
            'A story day | Student discipline and creating safe spaces forchildren headline',
          author: 'Yashavi Kapoor',
          views: '1.2K',
          timeAgo: '2 days ago'
        },
        {
          title: 'Video 4',
          author: 'Yashavi Kapoor',
          views: '3.4K',
          timeAgo: '1 week ago'
        }
      ]
    },
    {
      title: 'Prepare for Exam',
      subtitle: '45 videos | 25 documents',
      videos: [
        {
          title: 'Video 5',
          author: 'Author C',
          views: '5.6K',
          timeAgo: '5 days ago'
        },
        {
          title: 'Video 6',
          author: 'Author D',
          views: '2.1K',
          timeAgo: '3 weeks ago'
        },
        {
          title: 'Video 7',
          author: 'Author A',
          views: '1.2K',
          timeAgo: '2 days ago'
        },
        {
          title: 'Video 8',
          author: 'Author B',
          views: '3.4K',
          timeAgo: '1 week ago'
        }
      ]
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <TagsBar tags={tags} />
        <Container maxWidth="lg" sx={{ marginTop: 2 }}>
          {sections.map((section, index) => (
            <ContentSection
              key={index}
              title={section.title}
              subtitle={section.subtitle}
              videos={section.videos}
            />
          ))}
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export const TagsBar = ({ tags }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);

  const handleScroll = direction => {
    if (containerRef.current) {
      const newScrollPosition =
        direction === 'left'
          ? scrollPosition - containerRef.current.clientWidth
          : scrollPosition + containerRef.current.clientWidth;
      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth'
      });
      setScrollPosition(newScrollPosition);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollPosition;
    }
  }, [scrollPosition]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        onClick={() => handleScroll('left')}
        // disabled={scrollPosition <= 0}
      >
        <ArrowBackIos />
      </IconButton>
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          overflowX: 'hidden',
          padding: 0.3,
          flexGrow: 1,
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {tags.map((tag, index) => (
          <Chip
            label={tag}
            key={index}
            sx={{
              marginRight: 1,
              border: '1px solid #F7D178',
              borderRadius: '8px',
              fontSize: '14px',
              fontWeight: 600,
              fontFamily: 'Lato',
              '&:hover': {
                backgroundColor: '#FCEBC5',
                borderColor: '#F4C24A'
              }
            }}
            clickable
            color="secondary"
          />
        ))}
      </Box>
      <IconButton onClick={() => handleScroll('right')}>
        <ArrowForwardIos />
      </IconButton>
    </Box>
  );
};
