import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Typography, Grid, SvgIcon, Link, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle } from '../utils/utils';
import {
  colorLinkGrey,
  colorMidGray,
  colorPrimary,
  colorPureWhite,
  colorTextBlack,
  colorTextGrey
} from '../config/theme';
import { privateRoute } from '../App';
import { DASHBOARD_MENUS, TEACHER_ROLE_TYPE } from '../utils/constant';
import _ from 'lodash';
import { setActiveModule } from '../redux/reducers/layoutSlice';
import RightSection from '../containers/teacherDashboard/rightSection';
import TeacherDetails from '../containers/teacherDashboard/leftSection/teacherDetails';
import Courses from '../containers/teacherDashboard/leftSection/courses';
import {
  resetGoogleLogin,
  teacherDashboard
} from '../redux/reducers/authSlice';
import {
  AddIcon,
  AddUserIcon,
  AnalyticsIcon,
  BarChartSquare,
  BarChartSquareIcon,
  CalendarPlus,
  Edit05Icon,
  EditIcon,
  LocationIcon,
  PieIcon,
  ProductIcon,
  SchedulesCalendarIcon,
  ShareThreeDotsIcon,
  UserAddIcon,
  UsersEditIcon
} from '../utils/iconSvg';
// import { Add } from '@mui/icons-material';

const CustomCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  // '&:hover': {
  //   boxShadow: theme.shadows[8]
  // },
  borderRadius: theme.spacing(1),
  backgroundColor: colorPureWhite,
  // minHeight: 330,

  border: '1px solid #E4E7EB'
}));

const ICONS = {
  'User Management': UserAddIcon,
  'Location Management': LocationIcon,
  Analytics: AnalyticsIcon,
  Schedules: SchedulesCalendarIcon,
  'Add user': AddUserIcon,
  'Create user groups': UsersEditIcon,
  'Create role': Edit05Icon,
  'Create new school': EditIcon,
  'Add new class': AddIcon,
  'Usage Analytics': PieIcon,
  'Product Analytics': ProductIcon,
  'Create new event': CalendarPlus,
  'Share calendar': ShareThreeDotsIcon
};

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authData } = useSelector(state => state.auth);
  const { modules } = useSelector(state => state.layout);

  //usestate
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = (cardIndex, subMenuIndex) => {
    if (!(cardIndex === 3 && subMenuIndex === 0)) {
      setHoveredItem([cardIndex, subMenuIndex]);
    }
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  useEffect(() => {
    document.title = createTitle('Dashboard');
    dispatch(setActiveModule('dashboard'));
    dispatch(teacherDashboard());
    dispatch(resetGoogleLogin());
  }, []);

  function handleNavigation(moduleId, path) {
    let route = privateRoute.find(cc => cc.menu_name === moduleId);

    if (route?.path && path) {
      dispatch(setActiveModule(moduleId));
      navigate(path);
    } else {
      return navigate('/forbidden');
    }
  }

  const getIconColor = isHovered => (isHovered ? '#F4C24A' : 'black');

  const svgIcon = (menuName, isHovered) => {
    const IconComponent = ICONS[menuName];
    const color = getIconColor(isHovered);
    return IconComponent ? <IconComponent color={color} /> : null;
  };

  return (
    <>
      <div>
        {_.includes(TEACHER_ROLE_TYPE, authData?.roleType) && (
          <>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6} lg={6}>
                <TeacherDetails />
                <Courses />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <RightSection />
              </Grid>
            </Grid>
          </>
        )}
        {!_.includes(TEACHER_ROLE_TYPE, authData?.roleType) && (
          <>
            <Typography
              variant="h5"
              component="div"
              className=""
              fontSize={32}
              fontWeight={'700'}
              // style={{ paddingLeft: '13px' }}
            >
              Welcome {authData?.name?.split(' ')[0] || 'User'}!
            </Typography>
            <Grid container spacing={2} mt={0.1}>
              {DASHBOARD_MENUS
                // .filter(cc =>
                //   _.includes(Object.keys(modules), cc.menu_name)
                // )
                .map((item, cardIndex) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={3} key={cardIndex}>
                      <CustomCard
                        elevation={0}
                        sx={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between'
                          // justifyContent: 'flex-end'
                        }}
                      >
                        <Grid>
                          {item.menu_name === 'User Management' ? (
                            <UserAddIcon />
                          ) : item.menu_name === 'Location Management' ? (
                            <LocationIcon />
                          ) : item.menu_name === 'Analytics' ? (
                            <AnalyticsIcon />
                          ) : item.menu_name === 'Schedules' ? (
                            <SchedulesCalendarIcon />
                          ) : (
                            ''
                          )}
                          <Typography
                            color={colorTextBlack}
                            mt={0.5}
                            fontWeight={'700'}
                            fontSize={20}
                          >
                            {item.menu_name}
                          </Typography>
                          <Typography
                            color={colorLinkGrey}
                            fontWeight={'500'}
                            fontSize={14}
                            lineHeight={'18px'}
                            sx={{ marginBottom: 2 }}
                          >
                            {item.menu_content}
                          </Typography>
                        </Grid>

                        <Grid
                          sx={{
                            height: '100px',
                            display: 'flex',
                            flexDirection: 'column-reverse'
                          }}
                        >
                          {item.subMenu
                            // .filter(cc => {
                            //   return cc.menu_name !== 'Schedules'
                            //     ? modules[item.menu_name]
                            //       ? modules[item.menu_name].some(
                            //           xx => xx.pageName === cc.menu_name
                            //         )
                            //       : null
                            //     : cc;
                            // })
                            // .reverse()
                            .map((subMenu, subMenuIndex) => {
                              const isHovered =
                                hoveredItem &&
                                hoveredItem[0] === cardIndex &&
                                hoveredItem[1] === subMenuIndex;

                              return (
                                <Grid
                                  key={subMenuIndex}
                                  container
                                  display={'flex'}
                                  alignItems={'center'}
                                  mt={2}
                                  sx={{
                                    cursor: isHovered ? 'pointer' : 'default'
                                  }}
                                >
                                  <Box
                                    onMouseEnter={() =>
                                      handleMouseEnter(cardIndex, subMenuIndex)
                                    }
                                    onMouseLeave={handleMouseLeave}
                                    display={'flex'}
                                    sx={{
                                      ...(isHovered && {
                                        '&:hover': {
                                          borderBottom: `1px solid ${colorPrimary}`
                                        }
                                      }),
                                      borderBottom: `1px solid ${colorTextBlack}`,
                                      alignItems: 'center'
                                    }}
                                  >
                                    <SvgIcon
                                      sx={{
                                        marginRight: '5px'
                                      }}
                                      fontSize="16px"
                                    >
                                      {svgIcon(subMenu.menu_desc, isHovered)}
                                    </SvgIcon>
                                    <Link
                                      sx={{
                                        textDecoration: 'none',
                                        textDecorationColor: 'black',
                                        textUnderlineOffset: '3px',
                                        color: colorTextBlack
                                      }}
                                      onClick={() => {
                                        if (
                                          !(
                                            cardIndex === 3 &&
                                            subMenuIndex === 0
                                          )
                                        ) {
                                          handleNavigation(
                                            subMenu.menu_name,
                                            subMenu.path
                                          );
                                        }
                                      }}
                                    >
                                      <Typography
                                        color={
                                          isHovered ? colorPrimary : 'inherit'
                                        }
                                        fontWeight={700}
                                        fontSize={'14px'}
                                      >
                                        {subMenu?.menu_desc}
                                      </Typography>
                                    </Link>
                                  </Box>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </CustomCard>
                    </Grid>
                  );
                })}
            </Grid>
          </>
        )}
      </div>
    </>
  );
}

export default Dashboard;
