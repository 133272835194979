import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import React, { useState } from 'react';
import Flip from 'react-card-flip';
import { colorPrimary, colorTextBlack } from '../config/theme';

const FlashCard = () => {
  const [flipped, setFlipped] = useState(false);
  const flashCardJson = [
    {
      id: 0,
      question: {
        question: 'Question',
        option: [
          {
            id: 'A',
            value: 'Otion A'
          },
          {
            id: 'B',
            value: 'Option B'
          },
          {
            id: 'C',
            value: 'Option C'
          },
          {
            id: 'D',
            value: 'Option D'
          }
        ]
      },
      answer: {
        content: {
          id: 'A',
          value: 'Option A'
        }
      }
    }
  ];
  return (
    <>
      {flashCardJson.map((item, index) => {
        return (
          <Flip
            key={index}
            isFlipped={flipped}
            flipDirection="vertical"
            style={{ scrollbarColor: 'transparent transparent' }}
          >
            <Card
              style={{
                width: '50%',
                minHeight: '20rem'
              }}
              elevation={24} // MUI Box Shadow
              key="front"
              onClick={() => setFlipped(true)}
            >
              <CardHeader title={'Question 1'} />
              <hr />
              <CardContent>
                <Typography
                  color={colorTextBlack}
                  mt={0.5}
                  fontWeight={'700'}
                  fontSize={20}
                  marginBottom={'1rem'}
                >
                  {item.question?.question}
                </Typography>
                {item.question?.option?.map((option, i) => (
                  <div key={option.id} style={{ marginBottom: '10px' }}>
                    <Typography
                      color={colorPrimary}
                      fontWeight={700}
                      fontSize={'16px'}
                    >
                      {option.id}. {option.value}
                    </Typography>
                  </div>
                ))}
              </CardContent>
            </Card>

            <Card
              key="back"
              style={{ width: '50%', minHeight: '20rem' }}
              elevation={24} // MUI Box Shadow
              onClick={() => setFlipped(false)}
            >
              <CardHeader title={'Answer'} />
              <hr />
              <CardContent>
                <Typography
                  color={colorTextBlack}
                  fontWeight={'600'}
                  fontSize={20}
                  marginBottom={'1rem'}
                >
                  The Correct answer is {item?.answer?.content?.value}{' '}
                </Typography>
              </CardContent>
            </Card>
          </Flip>
        );
      })}
    </>
  );
};

export default FlashCard;
