import React, { useEffect, useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider
} from '@mui/material';
import { IMG_SPLASH_LOGO } from '../utils/imageUrls';
import {
  colorGrayish,
  colorLinkGrey,
  colorPrimary,
  colorPureWhite,
  colorSecondary,
  colorTextBlack
} from '../config/theme';
import { createTitle, handleError, mapArrayToTypography } from '../utils/utils';
import {
  getNoAuthGradeList,
  getNoAuthRoleList
} from '../redux/reducers/mastersSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  registerUser,
  revertValidateUser,
  updateUserWhileRegistration
} from '../redux/reducers/authSlice';
import SnackBarBox from '../components/SnackBarBox';

function Welcome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState({});

  const { noAuthGradeList, noAuthRoleList } = useSelector(
    state => state.masters
  );

  const {
    isUpadteUserLoading,
    isUpadteUserSuccess,
    registerUserData,
    isRegisterUserLoading,
    isRegisterUserSuccess,
    isRegisterUserFailed,
    registerUserErrorContainer
  } = useSelector(state => state.auth);

  const firstName = location?.state?.firstName;
  const username = location?.state?.username;
  const userPassword = location?.state?.userPassword;

  const signUpPayload = {
    name: location?.state?.name,
    contactNumber: location?.state?.contactNumber,
    email: location?.state?.email,
    dateOfBirth: location?.state?.dateOfBirth,
    userType: location?.state?.userType,
    citizenship: location?.state?.citizenship,
    status: location?.state?.status,
    loggedInUserName: location?.state?.loggedInUserName,
    password: location?.state?.password,
    stateId: location?.state?.stateId,
    districtId: location?.state?.districtId,
    area: location?.state?.area,
    schoolId: location?.state?.schoolId,
    isSignInGoogle: location?.state?.isSignInGoogle
  };

  const questions = [
    {
      question: 'Please select your identity and tell us about yourself',
      key: 'role',
      type: 'radio',
      // options: ['I am a student', 'I am a teacher']
      options: [...noAuthRoleList]
    },
    {
      question: 'Please select your identity and tell us about yourself',
      key: 'grade',
      type: 'dropdown',
      gradeOptions: [...noAuthGradeList]
    }
  ];

  useEffect(() => {
    document.title = createTitle('Welcome');
    dispatch(getNoAuthGradeList());
    dispatch(getNoAuthRoleList());
  }, []);

  // useEffect(() => {
  //   if (!isUpadteUserLoading && isUpadteUserSuccess) {
  //     setTimeout(() => {
  //       navigate('/login');
  //     }, 4000);
  //   }
  // }, [isUpadteUserSuccess]);

  useEffect(() => {
    if (!isRegisterUserLoading && isRegisterUserSuccess) {
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    }
  }, [isRegisterUserSuccess]);

  const handleNext = () => {
    if (activeStep === questions?.length - 1) {
      const payload = {
        ...signUpPayload,
        username: registerUserData?.payload?.id,
        grade: answers?.grade,
        roleId: answers?.role
      };
      // dispatch(updateUserWhileRegistration(payload));

      const formData = new FormData();
      formData.append('user', JSON.stringify(payload));
      dispatch(registerUser(formData));
    }
    activeStep < 1 && setActiveStep(prevStep => prevStep + 1);
  };

  const handleBack = () => {
    if (activeStep >= 1) {
      setActiveStep(prevStep => prevStep - 1);
    } else {
      dispatch(revertValidateUser());
      navigate('/sign-up', {
        state: {
          firstName: location?.state?.firstName,
          lastName: location?.state?.lastName,
          contactNumber: location?.state?.contactNumber,
          email: location?.state?.email,
          dateOfBirth: location?.state?.dateOfBirth,
          userType: location?.state?.userType,
          citizenship: location?.state?.citizenship,
          status: location?.state?.status,
          loggedInUserName: location?.state?.loggedInUserName,
          password: location?.state?.password,
          stateId: location?.state?.stateId,
          districtId: location?.state?.districtId,
          area: location?.state?.area,
          schoolId: location?.state?.schoolId,
          isSignInGoogle: location?.state?.isSignInGoogle,
          roleId: location?.state?.roleId,
          grade: location?.state?.grade,
          isGoogleSignIn: location?.state?.isSignInGoogle
        }
      });
    }
  };

  const handleInputChange = (key, value) => {
    setAnswers({
      ...answers,
      [key]: value
    });
  };

  // const isDropdownStepValid = () => {
  //   return answers.grade && answers.location;
  // };

  // const handleSubmit = () => {
  //   console.log("User's Answers:", answers);
  //   alert('Form Submitted!');
  // };

  return (
    <Grid
      sx={{
        height: '100vh',
        position: 'fixed',
        width: '100%',
        display: 'flex',
        // justifyContent: 'space-between',
        backgroundColor: colorPureWhite
      }}
    >
      <Grid
        style={{
          overflowY: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          WebkitOverflowScrolling: 'touch',
          scrollbarColor: 'transparent transparent',
          // backgroundColor: 'yellow',
          width: '80%'
        }}
      >
        <Grid sx={{ px: '24px', py: '20px' }}>
          <img
            width={'80px'}
            height={'75.88px'}
            src={IMG_SPLASH_LOGO}
            alt={IMG_SPLASH_LOGO}
          />
        </Grid>
        <Grid
          sx={{
            p: 3,
            py: 10,
            marginLeft: '65px'
          }}
        >
          <Box sx={{ width: '696px', margin: '0 auto', padding: '20px' }}>
            <Box>
              <Typography
                fontSize={'32px'}
                fontWeight={'700'}
                fontFamily={'Lato'}
                color={'#1F2933'}
              >
                {`Welcome, ${firstName}!`}
              </Typography>
            </Box>

            {/* {activeStep >= questions.length ? (
              <Box sx={{ textAlign: 'center', marginTop: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Your registration on the PAF platform is successful.
                </Typography>
                <Button variant="contained" onClick={handleSubmit}>
                  Login
                </Button>
              </Box>
            ) : ( */}
            <Box>
              <Typography
                sx={{
                  marginBottom: '24px',
                  fontSize: '16px',
                  fontWeight: 600,
                  fontFamily: 'Lato',
                  color: '#4E565F'
                }}
              >
                Almost there, please help us craft your experience better.
              </Typography>

              <Stepper
                activeStep={activeStep}
                sx={{
                  marginBottom: '24px',
                  textAlign: 'left', // Align stepper to the left
                  width: '264px'
                }}
              >
                {questions?.map((_, index) => (
                  <Step key={index}>
                    <StepLabel></StepLabel>
                  </Step>
                ))}
              </Stepper>

              <Box sx={{ marginTop: 3 }}>
                <Typography
                  variant="h6"
                  color={colorLinkGrey}
                  fontWeight={600}
                  fontSize={'16px'}
                  marginBottom={2}
                >
                  {questions[activeStep]?.question}
                </Typography>

                {questions[activeStep]?.type === 'radio' && (
                  <RadioGroup
                    value={answers[questions[activeStep]?.key] || ''}
                    onChange={e =>
                      handleInputChange(
                        questions[activeStep]?.key,
                        e.target?.value
                      )
                    }
                    sx={{
                      textAlign: 'left',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 2
                    }}
                  >
                    {questions[activeStep]?.options?.map(option => (
                      <FormControlLabel
                        key={option?.roleId}
                        value={option?.roleId}
                        control={<Radio />}
                        label={
                          <Typography
                            fontWeight="700"
                            fontSize={'20px'}
                            color="#1F2933"
                            marginRight="32px"
                          >
                            {option?.roleName === 'I am a teacher'
                              ? 'I am a teacher'
                              : option?.roleName === 'I am a student'
                                ? 'I am a student'
                                : ''}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                )}

                {questions[activeStep]?.type === 'dropdown' && (
                  <Box>
                    <Box sx={{ marginBottom: '16px' }}>
                      <InputLabel
                        sx={{
                          mb: 0.2,
                          fontWeight: '700',
                          color: colorTextBlack,
                          fontSize: '16px'
                        }}
                      >
                        Select grade
                      </InputLabel>
                      <FormControl size="small" sx={{ width: '320px' }}>
                        <Select
                          value={answers?.grade || ''}
                          onChange={e =>
                            handleInputChange(
                              questions[activeStep]?.key,
                              e.target?.value
                            )
                          }
                          displayEmpty
                          renderValue={selected => {
                            if (selected === '') {
                              return (
                                <span
                                  style={{
                                    color: '#ABB4BD',
                                    fontFamily: 'Lato',
                                    fontWeight: 500
                                  }}
                                >
                                  Select grade
                                </span>
                              );
                            }
                            return questions[activeStep]?.gradeOptions.find(
                              row => row.gradeId === selected
                            )?.gradeName;
                          }}
                        >
                          <MenuItem value="" disabled>
                            <div color="#ABB4BD">Select grade</div>
                          </MenuItem>
                          {questions[activeStep]?.gradeOptions?.map(grade => (
                            <MenuItem
                              key={grade?.gradeId}
                              value={grade?.gradeId}
                            >
                              {grade?.gradeName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                )}

                <Divider sx={{ marginTop: '16px' }} />

                <Box sx={{ marginTop: 4 }}>
                  <Button
                    // disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{
                      marginRight: 1,
                      width: '95px',
                      height: '42px',
                      border: '2px solid', // Add border to Back button
                      borderColor: colorPrimary,
                      color: colorTextBlack
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={
                      (questions[activeStep]?.type === 'radio' &&
                        !answers[questions[activeStep]?.key]) ||
                      (questions[activeStep]?.type === 'dropdown' &&
                        !answers.grade)
                    }
                    sx={{ width: '110px', height: '42px' }}
                  >
                    {activeStep < 1 ? 'Next' : 'Sign up'}
                  </Button>
                </Box>
              </Box>
            </Box>
            {/* )} */}
          </Box>
        </Grid>
      </Grid>
      <Grid
        sx={{
          backgroundColor: colorSecondary,
          width: '360px',
          // height: '928px',
          borderRadius: '5px',
          margin: '16px'
        }}
      ></Grid>
      {!isRegisterUserLoading && isRegisterUserSuccess && (
        <SnackBarBox
          type="success"
          message={
            registerUserData?.payload?.message ||
            'Your registration on the PAF platform is successful.'
          }
        />
      )}

      {!isRegisterUserLoading && isRegisterUserFailed && (
        <SnackBarBox
          type="error"
          // message={mapArrayToTypography(
          //   createUserErrorContainer?.errorMessage?.split(';')
          // )}
          message={
            registerUserErrorContainer?.errorMessage?.split(';').length > 1
              ? mapArrayToTypography(
                  registerUserErrorContainer?.errorMessage?.split(';')
                )
              : handleError(registerUserErrorContainer?.errorMessage)
          }
        />
      )}
      {/* {!isRegisterUserLoading && isRegisterUserSuccess && (
        <SnackBarBox
          type="error"
          // message={mapArrayToTypography(
          //   createUserErrorContainer?.errorMessage?.split(';')
          // )}
          message={
            registerUserErrorContainer?.errorMessage?.split(';').length > 1
              ? mapArrayToTypography(
                  registerUserErrorContainer?.errorMessage?.split(';')
                )
              : handleError(registerUserErrorContainer?.errorMessage)
          }
        />
      )} */}
    </Grid>
  );
}

export default Welcome;
