import { Box, Button, Grid, Typography, styled } from '@mui/material';
import { LoginForm } from '../containers/login/LoginForm';
import CenteredLogo from '../components/CenteredLogo';
import { colorGrayish, colorSecondary } from '../config/theme';
import CustomCarousel from '../components/CustomCarousel';
import { useEffect } from 'react';
import {
  clearLoginState,
  logoutAndClearToken,
  revertRegisterUser,
  revertValidateUser
} from '../redux/reducers/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle, handleError, mapArrayToTypography } from '../utils/utils';
import SnackBarBox from '../components/SnackBarBox';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { setActiveModule } from '../redux/reducers/layoutSlice';

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { padding: '0px 0px' },
  [theme.breakpoints.up('md')]: { padding: '0px 60px' }
}));

const CaurosalGrid = styled(Grid)(({ theme }) => ({
  // [theme.breakpoints.up('sm')]: { display: 'none' },
  [theme.breakpoints.down('md')]: { display: 'none' }
}));

const slides = [
  {
    title: '',
    image: './images/login-images/1.JPG',
    alt: 'login_img1',
    description:
      'English room- A collaborative space that provides functional English curriculum, digital aids, books, and skilled personnel to students and teachers in order to improve their English literacy and 21st century skills'
  },
  {
    title: '',
    image: './images/login-images/2.JPG',
    alt: 'login_img2',
    description:
      'English room- A collaborative space that provides functional English curriculum, digital aids, books, and skilled personnel to students and teachers in order to improve their English literacy and 21st century skills'
  },
  {
    title: '',
    image: './images/login-images/5.jpg',
    alt: 'login_img_3',
    description:
      'Teacher Training- Our Training Program helps Government School teachers build themselves, improves their teaching practices and strengthens their subject matter knowledge, which enables them to drive better learning outcomes in their classrooms.'
  },
  {
    title: '',
    image: './images/login-images/6.jpg',
    alt: 'login_img4',
    description:
      'Teacher Training- Our Training Program helps Government School teachers build themselves, improves their teaching practices and strengthens their subject matter knowledge, which enables them to drive better learning outcomes in their classrooms.'
  },
  {
    title: '',
    image: './images/login-images/3.jpeg',
    alt: 'login_img5',
    description:
      'English Room on Wheels- Through this program, we provide support and training to school teachers in diverse teaching techniques along with a customized portable box inclusive of digital teaching resources and books to use in their classroom.'
  },
  {
    title: '',
    image: './images/login-images/4.png',
    alt: 'login_img_6',
    description:
      'English Room on Wheels- Through this program, we provide support and training to school teachers in diverse teaching techniques along with a customized portable box inclusive of digital teaching resources and books to use in their classroom.'
  }
];

export default function Login() {
  // constants
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isLoading,
    isLoggedIn,
    isError,
    errorContainer,
    authData,
    isUpdatePasswordLoading,
    isUpdatePasswordSuccess
  } = useSelector(state => state.auth);

  useEffect(() => {
    document.title = createTitle('Login');
    // return () => dispatch(clearLoginState());
    dispatch(revertValidateUser());
    dispatch(revertRegisterUser());
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(setActiveModule('dashboard'));
      return navigate('/dashboard');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isUpdatePasswordLoading && isUpdatePasswordSuccess) {
      setTimeout(() => {
        dispatch(clearLoginState());
      }, 3000);
    }
  }, [isUpdatePasswordSuccess]);

  return (
    <Grid container sx={{ height: '100vh', bgcolor: 'white' }}>
      <CaurosalGrid
        item
        xs={false}
        sm={false}
        md={6}
        lg={6}
        p={2}
        elevation={6}
        square
      >
        <Box
          bgcolor={colorSecondary}
          display={'flex'}
          justifyContent={'center'}
          height={'100%'}
          textAlign={'center'}
          alignItems={'center'}
          borderRadius={'8px'}
        >
          <CustomCarousel slides={slides} />
        </Box>
      </CaurosalGrid>

      <CustomGrid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        elevation={6}
        square
        display={'flex'}
        alignItems="center"
        justifyContent={'center'}
      >
        <Box
          sx={{
            my: 4,
            display: 'flex',
            flexDirection: 'column',
            width: 340
          }}
        >
          <Grid container mb={2}>
            <Grid item mr={2} width={80} height={80}>
              <CenteredLogo />
            </Grid>
            <Grid
              item
              alignSelf={'end'}
              display={'flex'}
              flexDirection="column"
            >
              <Typography variant="h4" fontWeight={'700'} color={'#000'}>
                Welcome!
              </Typography>
              <Typography color={colorGrayish}>
                Please login into your account.
              </Typography>
            </Grid>
          </Grid>

          <LoginForm />
        </Box>
      </CustomGrid>

      {!isLoading &&
        isError &&
        errorContainer?.errorMessage !== 'Invalid password entered' && (
          <SnackBarBox
            type="error"
            message={mapArrayToTypography(
              errorContainer?.errorMessage?.split(',')
            )}
          />
        )}
      {isLoading && <Loader text="Validating username and password..." />}
      {!isUpdatePasswordLoading && isUpdatePasswordSuccess && (
        <SnackBarBox
          type="success"
          message={'New password reset successfully.'}
        />
      )}
    </Grid>
  );
}
